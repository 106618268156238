import * as React from 'react';
import moment from 'moment';
import { useHistory } from 'react-router';
import { debounce } from "lodash-es";
import { UseQueryState } from 'urql';

import { CheckedIn, EnrollmentStatusFilter, RosterGroupingKind, Site, userPreferences } from 'app2/api';
import { ActionButton,  Button, copyToClipboard, DatePicker, DataTable, DropdownZindex, iso8601Date, HBox, MenuItem, Phone, Point, TabletOrDesktop, Tabs, Text, useRef, useLifecycle, useSafeState, parseSingleDate, VBox } from 'app2/components';
import { byActivity, ContentType, createRosterView, downloadRoster, HrDataTable, HrDataTableProps, RestrictedStudentModal, SiteWithTimezone, StudentModal, TableViewsProps } from 'app2/views/shared'

import { CopyParentEmailsAction } from '../CopyParentEmailsAction';

import { useAttendanceDate } from './useAttendanceDate';
import { AttendanceSelections, AttendanceCourseSelections, AttendanceQuery, setAttendance, useAttendanceQuery } from './generated';

const TABLE_PREFS_VERSION = '10';

interface Props {
  url:string;

  // you must supply one of these
  token?:string;
  course?:AttendanceCourseSelections;
  site?:SiteWithTimezone & Pick<Site, 'name'>;

  prefsKey?:string;

  pageHeader?:React.ReactNode;
  header?:Partial<HrDataTableProps<AttendanceSelections>['header']>;
  table?:Partial<HrDataTableProps<AttendanceSelections>['table']>;
  queryOptions?:Partial<HrDataTableProps<AttendanceSelections>['queryOptions']>;

  // dictates if the check in/out tabs should be shown on desktop
  // by default they show on mobile automatically
  tabs?:boolean;
  
  // is this the teacher view
  teacher?:boolean;
  views?:TableViewsProps;

  dates?: {
    isSessionDay?:boolean;
    prev?:moment.Moment;
    next?:moment.Moment;
    legend?:React.ComponentProps<typeof DatePicker>['legend'];
  }
}

type ViewMode = 'all' | 'check-in' | 'check-out' | 'check-in-mobile' | 'check-out-mobile';

interface AttenanceCounts {
  total:number;
  present:number;
  checkedIn:number;
  checkedOut:number;
}

export function Attendance(props: Props) {
  const history = useHistory();
  const date = useAttendanceDate();

  const prev = props.dates ? props.dates.prev : moment(date).subtract(1, 'days');
  const next = props.dates ? props.dates.next : moment(date).add(1, 'days');

  const tableRef = props.table?.ref || useRef<DataTable<AttendanceSelections>>();

  const [attendances, setAttendances] = useSafeState<AttendanceQuery['rosterByGrouping']>();
  const [studentCounts, setStudentCounts] = useSafeState<AttenanceCounts>({total:0, present:0, checkedIn: 0, checkedOut: 0});

  const allCols = getCols();

  useLifecycle({onUpdate});

  function onUpdate() {
    if (date) {
      return;
    }

    setDate(moment(), true);
  }

  function render() {
    return <>
      {props.pageHeader}
      <Phone>
        {props.site
        ? renderTable('all')
        : <Tabs position='sticky' top='0px' background='white' zIndex={DropdownZindex} tabStrip={{mb:0}} tabs={[
          {label: 'Check in', name: 'check-in-mobile', content: renderTable('check-in-mobile')},
          {label: 'Check out', name: 'check-out-mobile', content: renderTable('check-out-mobile')}
        ]} />}
      </Phone>
      <TabletOrDesktop>
        {!props.tabs
          ? renderTable('all')
          : <Tabs position='sticky' top='0px' background='white' zIndex={DropdownZindex} tabStrip={{mb:0}} tabs={[
                {label: 'Check in', name: 'check-in', content: renderTable('check-in')},
                {label: 'Check out', name: 'check-out', content: renderTable('check-out')}
              ]} />}
      </TabletOrDesktop>
    </>
  }

  function renderTable(mode:ViewMode) {
    const cols = allCols[mode];
    const mobile = mode == 'check-in-mobile' || mode == 'check-out-mobile';

    return <HrDataTable<AttendanceSelections>
      header={{ icon: 'Users', title: 'Attendance', subtitle: renderSubtitle(), options: renderOptions(), secondaryActions:mobile ? 'hide' : renderSecondaryActions(mode), editing: true, header:renderDate(), ...props.header }}
      table={{ cols, ref: tableRef, none: renderNoneMsg(), cellStyle: 'read-only', rowHeaders: !mobile, fillWidth: true, editable: true, atLeastOneEditableRow: false, onDataUpdate: handleAttendanceChange, sortFilterWhenEditable: true, rowNumbers:false, appendable: false, stickyOffset: mode != 'all' ? new Point(0, 36) : undefined, mergeData: isPrevAttendanceMoreUpToDate, ...props.table }}
      views={props.views}
      prefs={userPreferences} prefsVersion={TABLE_PREFS_VERSION} prefsKey={prefsKey(mode)}
      queryHook={useAttendanceQuery} queryResponseHandler={onQueryResponse}
      queryOptions={getQueryOptions()}
    />
  }

  function getQueryOptions() {
    const removeFields = props.site ? undefined : ['items.course', 'items.season'];
    const options = {...props.queryOptions, variables:getRosterViewVariables(), removeFields};

    return options;
  }

  function renderSubtitle() {
    return <VBox gap='$8'>
      <Text text='body'>{studentCounts.total} enrolled / {studentCounts.present} present</Text>
      <Text text='body'>{studentCounts.checkedIn} checked-in / {studentCounts.checkedOut} checked-out</Text>
    </VBox>
  }

  function renderDate() {
    const isFuture = moment(date).isAfter(moment(), 'date');
    const warning = isFuture ? 'Attendance date is in the future' : undefined;

    return <HBox gap='$4' flex={1}>
      <Button kind='tertiary' icon='Left' iconPosition='left' whiteSpace='normal' disabled={!prev} onClick={onPrev} />
      <DatePicker legend={props.dates?.legend} minWidth={['95px', '150px']} maxWidth={['95px', '150px']} value={date} dateFormat={[DatePicker.SHORT_DATE_FORMAT, DatePicker.DEFAULT_DATE_FORMAT]} shadowFormat={['', 'MMM D, YYYY ddd']} onChange={onChange} warning={warning} />
      <Button kind='tertiary' icon='Right' whiteSpace='normal' disabled={!next}  onClick={onNext} />
    </HBox>
  }

  function renderOptions() {
    return [
      <CopyParentEmailsAction table={tableRef.current} />,
      <MenuItem label="Download roster PDF" onClick={() => handleDownloadRoster(ContentType.PDF)} />,
      <MenuItem label="Download roster CSV" onClick={() => handleDownloadRoster(ContentType.CSV)} />,
    ]
  }

  function renderSecondaryActions(mode:ViewMode) {
    const checkIn = mode == 'all' || mode == 'check-in' || mode == 'check-in-mobile';
    const checkOut = mode == 'all' || mode == 'check-out' || mode == 'check-out-mobile';

    return [
      checkIn && <ActionButton icon="UserCheck" onClick={handleCheckedIn}>Check in</ActionButton>,
      checkOut && <ActionButton icon="LogOut" onClick={handleCheckOut}>Check out</ActionButton>,
      <ActionButton icon="XCircle" onClick={handleAbsent}>Absent</ActionButton>,
      props.course?.behavior?.attendanceLink && <ActionButton icon="BookOpen" selection={false} onClick={() => copyToClipboard(`${location.origin}/activities/${props.token}/attendance`)}>Copy instructor link</ActionButton>
    ]
  }

  function renderNoneMsg() {
    if (props.dates?.isSessionDay === false) {
      return 'Not an activity day';
    }

    if ((props.course?.hasEnrolledStudents ?? true) && attendances?.total) {
      return `Showing 0 of ${attendances?.total} attendances.  Update the column filters to see more.`      
    }

    if (props.course?.hasEnrolledStudents) {
      return 'No enrolled students on this day';
    }

    return 'No enrolled students';
  }

  function onQueryResponse(response:UseQueryState<AttendanceQuery>) {
    if (attendances != response?.data?.rosterByGrouping) {
      setAttendances(response?.data?.rosterByGrouping);
    }

    const students = response?.data?.rosterByGrouping?.items;

    if (students) {
      const present = students.filter(s => s.checkedIn == CheckedIn.Present && !s.checkedOut).length;
      const checkedIn = students.filter(s => s.checkedIn == CheckedIn.Present).length;
      const checkedOut = students.filter(s => s.checkedOut).length;
      setStudentCounts({total: students.length, present, checkedIn, checkedOut});
    }

    return {items:students, total: response?.data?.rosterByGrouping?.total};
  }

  function prefsKey(mode:ViewMode) {
    return props.prefsKey ? `${props.prefsKey}/${mode}` : (props.token ? `/obfuscated/activities/${props.token}/attendance/${mode}` : undefined);
  }

  function getCols() {
    return React.useMemo(() => {
      return {
        'all':getColsForMode('all'),
        'check-in':getColsForMode('check-in'),
        'check-out':getColsForMode('check-out'),
        'check-in-mobile':getColsForMode('check-in-mobile'),
        'check-out-mobile':getColsForMode('check-out-mobile')
      };
    }, [props.course, props.site, date])
  }
  
  function getColsForMode(mode:ViewMode) {
    const colsToUse = props.site ? cols.allSite : cols[mode];
    const options = {...getRosterViewVariables(), course:props.course, site: props.site, status:EnrollmentStatusFilter.Attendance, cols:colsToUse, studentModal: getStudentModal};
    return createRosterView<AttendanceSelections>(options).cols
  }

  function getRosterViewVariables() {
    const groupingKind = props?.token || props.course ? RosterGroupingKind.Course : RosterGroupingKind.Site;
    const groupingId = props?.token || props.course?.id || props.site?.id;
    const variables = {groupingKind, groupingId, date:iso8601Date(date), ...props.queryOptions?.variables};

    return variables;
  }

  function onPrev() {
    setDate(prev);
  }

  function onNext() {
    setDate(next);
  }

  function getStudentModal(modalProps:{id:string}) {
    return props.teacher ? <RestrictedStudentModal token={props.token} id={modalProps.id} /> : <StudentModal id={modalProps.id} />;
  }

  function onChange(event:React.ChangeEvent<DatePicker>) {
    const date = event.target.value as string;
    setDate(date);
  }

  function setDate(date:moment.Moment | string, replace = false) {
    if (!date) {
      return;
    }

    const url = `${props.url}/${moment(date).format('YYYY-MM-DD')}`;

    if (replace) {
      history.replace(url)
    }
    else {
      history.push(url)
    }
  }

  function handleCheckedIn() {
    tableRef.current.selections.updateSelected({checkedIn:CheckedIn.Present});
  }

  function handleAbsent() {
    tableRef.current.selections.updateSelected({checkedIn:CheckedIn.Absent});
  }

  function handleCheckOut() {
    tableRef.current.selections.updateSelected({checkedOut:true});
  }

  const handleAttendanceChange = debounce(async () => {
    const changes = tableRef.current.getChanges();
    const attendances = Object.keys(changes).map(enrollmentId => {
      const attendance = changes[enrollmentId].item;

      return {
        course: attendance.course?.id || props.token || props.course?.id,
        student:attendance.student.id,
        date:iso8601Date(date),
        checkedIn: attendance.checkedIn,
        checkedInAt: attendance.checkedInAt,
        checkedOut: attendance.checkedOut,
        checkedOutAt: attendance.checkedOutAt,
        checkoutDest: attendance.checkoutDest
      }
    });

    if (!attendances.length) {
      return;
    }

    await setAttendance({variables:{attendances}});
  }, 1000);

  function isPrevAttendanceMoreUpToDate(prev:AttendanceSelections, next:AttendanceSelections, updatedAt:number) {
      const prevUpdatedAt = moment(updatedAt);
      const nextUpdatedAt = moment(next.updatedAt);
      return prevUpdatedAt.isAfter(nextUpdatedAt);
    }

  function handleDownloadRoster(contentType:ContentType) {
    const fileName = ['Homeroom roster', props.course?.name || props.site?.name, date];
    const variables = {...getRosterViewVariables(), enrollmentStatus: EnrollmentStatusFilter.Attendance}; 

    return downloadRoster(fileName, contentType, 'rosterByGrouping', variables, tableRef.current, byActivity, ['title', 'cols', 'groups']);
  }

  return render();
}

const cols = {
    all: [
    'student.firstName',  
    'student.lastName', 
    'student.grade', 
    'student.age', 
    'student.classroom.displayName',
    'otherEnrollments',
    'checkedIn',
    'checkedInAt',
    'checkedInBy',
    'checkedOut',
    'checkedOutAt',
    'checkedOutBy',
    'checkoutDest',
    'groups'
  ],
  allSite: [
    'season.name',
    'course.name',
  ],
  'check-in': [
    'student.firstName', 
    'student.lastName', 
    'checkedIn',
    'checkedInAt',
    'checkedInBy',
    'student.grade', 
    'student.age', 
    'student.classroom.displayName',
    'otherEnrollments',
    'groups'
  ],
  'check-out': [
    'student.firstName', 
    'student.lastName', 
    'checkedOut',
    'checkedOutAt',
    'checkedOutBy',
    'checkoutDest',
    'student.grade', 
    'student.age', 
    'student.classroom.displayName',
    'otherEnrollments',
    'checkedIn',
    'checkedInAt',
    'checkedInBy',
    'groups'
  ],
  'check-in-mobile': [
    {name:'student.firstName' as any, label: 'First', width: 100}, 
    {name:'student.lastName' as any, label: 'Last', width: 110}, 
    {name: 'checkedIn' as any, width: 105}, 
    {name: 'otherEnrollments' as any, width: 200}, 
    'groups'

  ],
  'check-out-mobile': [
    {name:'student.firstName' as any, label: 'First', width: 100}, 
    {name:'student.lastName' as any, label: 'Last', width: 110}, 
    {name: 'checkedOut' as any, width: 105}, 
    'checkoutDest',
    {name: 'otherEnrollments' as any, width: 200}, 
    'groups',
  ]
};

cols.allSite.push(...cols.all);