export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** An ISO 8601-encoded date */
  ISO8601Date: string;
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: string;
  /** Represents untyped JSON */
  JSON: { [key: string]: any };
  OptionValue: string | number;
};

export type AccessibleCoursesQueryResults = CollectionInfo & {
  count?: Maybe<Scalars['Int']>;
  filterOptions?: Maybe<Array<Maybe<Option>>>;
  /** The unique identifier of the resource. */
  id: Scalars['ID'];
  items?: Maybe<Array<Course>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  pages?: Maybe<Scalars['Int']>;
  totals?: Maybe<Array<Scalars['Int']>>;
};

export type ActiveRecordInterface = {
  /** The unique identifier of the resource. */
  id: Scalars['ID'];
};

export type AddBreakdown = {
  charges: Array<Maybe<ChargeBreakdown>>;
  refund: Scalars['Float'];
};

export type AddEnrollmentPriceBreakdown = {
  first?: Maybe<AddBreakdown>;
  future?: Maybe<AddBreakdown>;
  priceConfig: PriceConfig;
  standard?: Maybe<ChargeBreakdown>;
};

export type Adjustment = Receipt & {
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  total: Scalars['Float'];
};

export type AdminUsersQueryResults = CollectionInfo & {
  count?: Maybe<Scalars['Int']>;
  filterOptions?: Maybe<Array<Maybe<Option>>>;
  /** The unique identifier of the resource. */
  id: Scalars['ID'];
  items?: Maybe<Array<User>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  pages?: Maybe<Scalars['Int']>;
  totals?: Maybe<Array<Scalars['Int']>>;
};

export type Allergy = ActiveRecordInterface & {
  allergicTo: Scalars['String'];
  /** The unique identifier of the resource. */
  id: Scalars['ID'];
  notes?: Maybe<Scalars['String']>;
};

export type AllergyInput = {
  allergicTo: Scalars['String'];
  notes?: InputMaybe<Scalars['String']>;
};

export type AthleticsConfig = {
  students?: Maybe<Array<AthleticsConfigStudent>>;
  teamName?: Maybe<Scalars['String']>;
};

export type AthleticsConfigInput = {
  students?: InputMaybe<Array<AthleticsConfigStudentInput>>;
  teamName: Scalars['String'];
};

export type AthleticsConfigStudent = {
  email: Scalars['String'];
  name: Scalars['String'];
};

export type AthleticsConfigStudentInput = {
  email: Scalars['String'];
  name: Scalars['String'];
};

export type Attendance = {
  checkedInAt?: Maybe<Scalars['ISO8601DateTime']>;
  checkedOutAt?: Maybe<Scalars['ISO8601DateTime']>;
  date: Scalars['ISO8601Date'];
};

export enum AttendanceAction {
  CheckIn = 'check_in',
  CheckOut = 'check_out'
}

export type AttendanceInput = {
  checkedIn?: InputMaybe<CheckedIn>;
  checkedInAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  checkedOut?: InputMaybe<Scalars['Boolean']>;
  checkedOutAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  checkoutDest?: InputMaybe<Scalars['String']>;
  course: Scalars['String'];
  date: Scalars['ISO8601Date'];
  student: Scalars['ID'];
};

export type BetaFlags = {
  organizerRedesign?: Maybe<OrganizerRedesign>;
  parentRedesign?: Maybe<ParentRedesign>;
};

export type BetaFlagsInput = {
  organizerRedesign?: InputMaybe<OrganizerRedesignInput>;
  parentRedesign?: InputMaybe<ParentRedesignInput>;
};

export type BulkRefundBreakdown = {
  familiesReceive: Scalars['Float'];
  individualBreakdowns: Array<RefundBreakdown>;
  nonrefundableSiteFee?: Maybe<Scalars['Float']>;
  otherFeeReversals: Scalars['Float'];
  providerWithdrawAmount: Scalars['Float'];
  siteWithdrawAmount: Scalars['Float'];
};

export enum CardBrand {
  AmericanExpress = 'AMERICAN_EXPRESS',
  DinersClub = 'DINERS_CLUB',
  Discover = 'DISCOVER',
  Jcb = 'JCB',
  Mastercard = 'MASTERCARD',
  UnionPay = 'UNION_PAY',
  Unknown = 'UNKNOWN',
  Visa = 'VISA'
}

export type Cart = ActiveRecordInterface & {
  cartExpiration?: Maybe<Scalars['ISO8601DateTime']>;
  courseDiscountTotal: Scalars['Float'];
  discountTotal: Scalars['Float'];
  donation?: Maybe<Donation>;
  dueLater: Scalars['Float'];
  enrollments: Array<Enrollment>;
  family?: Maybe<Family>;
  /** The unique identifier of the resource. */
  id: Scalars['ID'];
  paymentMethodRequired: Scalars['Boolean'];
  paymentService?: Maybe<PaymentService>;
  priceTierDiscountTotal: Scalars['Float'];
  priceTierUse?: Maybe<PriceTierUse>;
  seasons: Array<Season>;
  sites: Array<Site>;
  stripeSetupIntentToken?: Maybe<Scalars['String']>;
  students: Array<Student>;
  subtotal: Scalars['Float'];
  total: Scalars['Float'];
};

/** Autogenerated return type of CartAddEnrollments */
export type CartAddEnrollmentsPayload = {
  enrollments?: Maybe<Array<Maybe<Enrollment>>>;
  expiryRefreshed?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of CartAddInvite */
export type CartAddInvitePayload = {
  success?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of CartApplyCourseDiscount */
export type CartApplyCourseDiscountPayload = {
  discount?: Maybe<Discount>;
  enrollment?: Maybe<Enrollment>;
};

/** Autogenerated return type of CartApplyDiscount */
export type CartApplyDiscountPayload = {
  success?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of CartCheckOut */
export type CartCheckOutPayload = {
  statementDescriptor?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of CartCreateAndAssignStudentToEnrollment */
export type CartCreateAndAssignStudentToEnrollmentPayload = {
  enrollment?: Maybe<Enrollment>;
  inGradeRange?: Maybe<Scalars['Boolean']>;
  student?: Maybe<Student>;
};

export type CartDonationInput = {
  coverTxnFees?: InputMaybe<Scalars['Boolean']>;
  initialAmount?: InputMaybe<Scalars['Float']>;
};

export type CartItem = {
  amount?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of CartRemoveDiscount */
export type CartRemoveDiscountPayload = {
  success?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of CartRemoveEnrollment */
export type CartRemoveEnrollmentPayload = {
  success?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of CartSetFamily */
export type CartSetFamilyPayload = {
  cart?: Maybe<Cart>;
};

/** Autogenerated return type of CartSetPriceTier */
export type CartSetPriceTierPayload = {
  success?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of CartSetTeamRegistration */
export type CartSetTeamRegistrationPayload = {
  enrollment?: Maybe<Enrollment>;
};

/** Autogenerated return type of CartUpdateEnrollment */
export type CartUpdateEnrollmentPayload = {
  enrollment?: Maybe<Enrollment>;
  inGradeRange?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of CartUpdatePaymentService */
export type CartUpdatePaymentServicePayload = {
  success?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of CartUpsertDonation */
export type CartUpsertDonationPayload = {
  donation?: Maybe<Donation>;
};

export type CcContactInput = {
  archived?: InputMaybe<Scalars['Boolean']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
};

export type CfAnswer = ActiveRecordInterface & {
  answers?: Maybe<Scalars['JSON']>;
  /** The unique identifier of the resource. */
  id: Scalars['ID'];
  ownerId?: Maybe<Scalars['ID']>;
  ownerType?: Maybe<CfOwnerKind>;
};

export type CfAnswerInput = {
  answers: Scalars['JSON'];
  ownerId: Scalars['ID'];
  ownerType: CfOwnerKind;
};

/** Autogenerated return type of CfAnswersUpsert */
export type CfAnswersUpsertPayload = {
  student?: Maybe<Student>;
};

export enum CfKind {
  Choice = 'CHOICE',
  Confirmation = 'CONFIRMATION',
  MultipleChoice = 'MULTIPLE_CHOICE',
  MultipleLines = 'MULTIPLE_LINES',
  Upload = 'UPLOAD'
}

export enum CfOwnerKind {
  Course = 'Course',
  Season = 'Season'
}

export type CfQuestion = ActiveRecordInterface & {
  choices?: Maybe<Array<Scalars['String']>>;
  description?: Maybe<Scalars['String']>;
  files?: Maybe<Array<Maybe<FileUpload>>>;
  filterCourseKinds?: Maybe<Array<Maybe<CourseKind>>>;
  filterCourses?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The unique identifier of the resource. */
  id: Scalars['ID'];
  kind?: Maybe<CfKind>;
  owner?: Maybe<WithName>;
  ownerId?: Maybe<Scalars['ID']>;
  ownerType?: Maybe<CfOwnerKind>;
  required?: Maybe<Scalars['Boolean']>;
  stage?: Maybe<CfStage>;
  title?: Maybe<Scalars['String']>;
};

export type CfQuestionInput = {
  archived?: InputMaybe<Scalars['Boolean']>;
  choices?: InputMaybe<Array<Scalars['String']>>;
  description?: InputMaybe<Scalars['String']>;
  files?: InputMaybe<Array<FileUploadAttributes>>;
  filterCourseKinds?: InputMaybe<Array<InputMaybe<CourseKind>>>;
  filterCourses?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  id?: InputMaybe<Scalars['ID']>;
  kind?: InputMaybe<CfKind>;
  required?: InputMaybe<Scalars['Boolean']>;
  stage?: InputMaybe<CfStage>;
  title?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of CfQuestionsUpsert */
export type CfQuestionsUpsertPayload = {
  questions?: Maybe<Array<CfQuestion>>;
};

export enum CfStage {
  Later = 'LATER',
  PreCheckout = 'PRE_CHECKOUT'
}

export enum ChangePricingPeriod {
  Current = 'CURRENT',
  Next = 'NEXT'
}

export type ChargeBreakdown = {
  amount?: Maybe<Scalars['Float']>;
  date?: Maybe<Scalars['ISO8601Date']>;
  discountAmount?: Maybe<Scalars['Float']>;
  homeroomCharges?: Maybe<Scalars['Float']>;
  listPrice?: Maybe<Scalars['Float']>;
  period?: Maybe<Scalars['String']>;
  prorated?: Maybe<Scalars['Boolean']>;
  siteCharges?: Maybe<Scalars['Float']>;
  siteCreditCardFee?: Maybe<Scalars['Float']>;
  siteReceives?: Maybe<Scalars['Float']>;
  vendorCharges?: Maybe<Scalars['Float']>;
  vendorCreditCardFee?: Maybe<Scalars['Float']>;
  vendorReceives?: Maybe<Scalars['Float']>;
};

export type CheckOutCartInput = {
  donation?: InputMaybe<CheckoutDonationInput>;
  enrollments: Array<CheckOutEnrollmentInput>;
  priceTierUse?: InputMaybe<CheckoutPriceTierInput>;
};

export type CheckOutEnrollmentInput = {
  discountId?: InputMaybe<Scalars['ID']>;
  discountIds?: InputMaybe<Array<Scalars['ID']>>;
  dismissal?: InputMaybe<Scalars['String']>;
  enrollmentPriceTierUseId?: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
  pickup?: InputMaybe<Scalars['String']>;
  studentId: Scalars['ID'];
};

export enum CheckedIn {
  Absent = 'absent',
  Present = 'present'
}

export type CheckoutDonationInput = {
  coverTxnFees: Scalars['Boolean'];
  id: Scalars['ID'];
  initialAmount: Scalars['Float'];
};

export type CheckoutPriceTierInput = {
  id: Scalars['ID'];
  priceTierId: Scalars['ID'];
};

export type Classroom = ActiveRecordInterface & {
  displayName: Scalars['String'];
  grade: Scalars['String'];
  /** The unique identifier of the resource. */
  id: Scalars['ID'];
  name: Scalars['String'];
  site?: Maybe<Site>;
  siteId: Scalars['String'];
};

export type ClassroomInput = {
  archived?: InputMaybe<Scalars['Boolean']>;
  grade: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
};

export type CollectionInfo = {
  count?: Maybe<Scalars['Int']>;
  /** The unique identifier of the resource. */
  id: Scalars['ID'];
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  pages?: Maybe<Scalars['Int']>;
  totals?: Maybe<Array<Scalars['Int']>>;
};

/** Autogenerated return type of CompaniesMerge */
export type CompaniesMergePayload = {
  company?: Maybe<Company>;
};

export type CompaniesQueryResults = CollectionInfo & {
  count?: Maybe<Scalars['Int']>;
  filterOptions?: Maybe<Array<Maybe<Option>>>;
  /** The unique identifier of the resource. */
  id: Scalars['ID'];
  items?: Maybe<Array<Company>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  pages?: Maybe<Scalars['Int']>;
  totals?: Maybe<Array<Scalars['Int']>>;
};

export type Company = ActiveRecordInterface &
  SiteOrCompany &
  WithNameAndId &
  WithTableViews &
  WithTeam & {
    about?: Maybe<Scalars['String']>;
    address?: Maybe<Scalars['String']>;
    cancellationPolicy?: Maybe<VendorGuideline>;
    city?: Maybe<Scalars['String']>;
    courseCancellationPolicy?: Maybe<Scalars['String']>;
    courseFrameworks?: Maybe<Array<CourseFramework>>;
    courseFrameworksQuery?: Maybe<CourseFrameworksQueryResults>;
    courseKindGroups: Array<CourseKindGroup>;
    courses: Array<Course>;
    email?: Maybe<Scalars['String']>;
    hasCourseFrameworks: Scalars['Boolean'];
    hasCourses: Scalars['Boolean'];
    hasMutations: Scalars['Boolean'];
    hasParticipants: Scalars['Boolean'];
    hasRequests: Scalars['Boolean'];
    hasTableViews: Scalars['Boolean'];
    hasTeachers: Scalars['Boolean'];
    hasTeamAdmins: Scalars['Boolean'];
    hasTeamMembers: Scalars['Boolean'];
    headline?: Maybe<Scalars['String']>;
    history?: Maybe<Array<HistoryVersion>>;
    /** The unique identifier of the resource. */
    id: Scalars['ID'];
    mutationChanges?: Maybe<Array<MutationChange>>;
    mutationChangesQuery?: Maybe<MutationChangesQueryResults>;
    mutations?: Maybe<Array<Mutation>>;
    mutationsQuery?: Maybe<MutationsQueryResults>;
    name?: Maybe<Scalars['String']>;
    nonPartnerApproved?: Maybe<Scalars['Boolean']>;
    participants?: Maybe<Array<Participant>>;
    participantsQuery?: Maybe<ParticipantsQueryResults>;
    payouts?: Maybe<Array<Payout>>;
    pendingPayoutTotal: Scalars['Float'];
    phone?: Maybe<Scalars['String']>;
    profileBackground?: Maybe<Scalars['String']>;
    profilePhoto?: Maybe<Scalars['String']>;
    quickStartGuide?: Maybe<CompanyQuickStartGuide>;
    sites: Array<Site>;
    sitesQuery: SitesQueryResults;
    size?: Maybe<Scalars['Int']>;
    slug: Scalars['String'];
    state?: Maybe<Scalars['String']>;
    stripeAccount?: Maybe<Scalars['String']>;
    stripeAccountType?: Maybe<Scalars['String']>;
    stripeAccountVerified?: Maybe<Scalars['Boolean']>;
    stripeBalance?: Maybe<Scalars['Float']>;
    stripeConnectUrl?: Maybe<Scalars['String']>;
    stripeDashboardUrl?: Maybe<Scalars['String']>;
    tableViews?: Maybe<Array<TableView>>;
    tableViewsQuery?: Maybe<TableViewsQueryResults>;
    tableViewsUpsert?: Maybe<Scalars['Boolean']>;
    tableViewsUpsertPersonal?: Maybe<Scalars['Boolean']>;
    teachers?: Maybe<Array<Teacher>>;
    teachersQuery?: Maybe<TeachersQueryResults>;
    teachingRadius?: Maybe<Scalars['Int']>;
    teamAdmins?: Maybe<Array<Member>>;
    teamAdminsQuery?: Maybe<TeamAdminsQueryResults>;
    teamChangeRole?: Maybe<Scalars['Boolean']>;
    teamInvites?: Maybe<Array<MemberInvitation>>;
    teamMembers?: Maybe<Array<Member>>;
    teamMembersQuery?: Maybe<TeamMembersQueryResults>;
    teamParticipantsUpsert?: Maybe<Scalars['Boolean']>;
    teamSetAsContact?: Maybe<Scalars['Boolean']>;
    timezone?: Maybe<Scalars['String']>;
    timezoneDisplay?: Maybe<Scalars['String']>;
    upcomingActiveOrRecentCourses?: Maybe<Array<Course>>;
    userCompanyRole?: Maybe<MemberRole>;
    userCourseRole?: Maybe<MemberRole>;
    userFamilyRole?: Maybe<MemberRole>;
    userMemberRole?: Maybe<MemberRole>;
    userSiteRole?: Maybe<MemberRole>;
    vendors?: Maybe<Array<Vendor>>;
    website?: Maybe<Scalars['String']>;
    zipcode?: Maybe<Scalars['String']>;
  };

export type CompanyCourseFrameworksArgs = {
  advancedFilter?: InputMaybe<Scalars['JSON']>;
  filters?: InputMaybe<Array<Filter>>;
  groups?: InputMaybe<Array<Scalars['String']>>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  queries?: InputMaybe<Array<Scalars['JSON']>>;
  sorts?: InputMaybe<Array<Sort>>;
};

export type CompanyCourseFrameworksQueryArgs = {
  advancedFilter?: InputMaybe<Scalars['JSON']>;
  filterOptions?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<Filter>>;
  groups?: InputMaybe<Array<Scalars['String']>>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  queries?: InputMaybe<Array<Scalars['JSON']>>;
  sorts?: InputMaybe<Array<Sort>>;
  totals?: InputMaybe<Array<Total>>;
};

export type CompanyMutationChangesArgs = {
  advancedFilter?: InputMaybe<Scalars['JSON']>;
  filters?: InputMaybe<Array<Filter>>;
  groups?: InputMaybe<Array<Scalars['String']>>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  queries?: InputMaybe<Array<Scalars['JSON']>>;
  sorts?: InputMaybe<Array<Sort>>;
};

export type CompanyMutationChangesQueryArgs = {
  advancedFilter?: InputMaybe<Scalars['JSON']>;
  filterOptions?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<Filter>>;
  groups?: InputMaybe<Array<Scalars['String']>>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  queries?: InputMaybe<Array<Scalars['JSON']>>;
  sorts?: InputMaybe<Array<Sort>>;
  totals?: InputMaybe<Array<Total>>;
};

export type CompanyMutationsArgs = {
  advancedFilter?: InputMaybe<Scalars['JSON']>;
  filters?: InputMaybe<Array<Filter>>;
  groups?: InputMaybe<Array<Scalars['String']>>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  queries?: InputMaybe<Array<Scalars['JSON']>>;
  sorts?: InputMaybe<Array<Sort>>;
};

export type CompanyMutationsQueryArgs = {
  advancedFilter?: InputMaybe<Scalars['JSON']>;
  filterOptions?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<Filter>>;
  groups?: InputMaybe<Array<Scalars['String']>>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  queries?: InputMaybe<Array<Scalars['JSON']>>;
  sorts?: InputMaybe<Array<Sort>>;
  totals?: InputMaybe<Array<Total>>;
};

export type CompanyParticipantsArgs = {
  advancedFilter?: InputMaybe<Scalars['JSON']>;
  filters?: InputMaybe<Array<Filter>>;
  groups?: InputMaybe<Array<Scalars['String']>>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  queries?: InputMaybe<Array<Scalars['JSON']>>;
  sorts?: InputMaybe<Array<Sort>>;
};

export type CompanyParticipantsQueryArgs = {
  advancedFilter?: InputMaybe<Scalars['JSON']>;
  filterOptions?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<Filter>>;
  groups?: InputMaybe<Array<Scalars['String']>>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  queries?: InputMaybe<Array<Scalars['JSON']>>;
  sorts?: InputMaybe<Array<Sort>>;
  totals?: InputMaybe<Array<Total>>;
};

export type CompanySitesArgs = {
  advancedFilter?: InputMaybe<Scalars['JSON']>;
  filters?: InputMaybe<Array<Filter>>;
  groups?: InputMaybe<Array<Scalars['String']>>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  queries?: InputMaybe<Array<Scalars['JSON']>>;
  sorts?: InputMaybe<Array<Sort>>;
};

export type CompanySitesQueryArgs = {
  advancedFilter?: InputMaybe<Scalars['JSON']>;
  filterOptions?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<Filter>>;
  groups?: InputMaybe<Array<Scalars['String']>>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  queries?: InputMaybe<Array<Scalars['JSON']>>;
  sorts?: InputMaybe<Array<Sort>>;
  totals?: InputMaybe<Array<Total>>;
};

export type CompanyTableViewsArgs = {
  advancedFilter?: InputMaybe<Scalars['JSON']>;
  filters?: InputMaybe<Array<Filter>>;
  groups?: InputMaybe<Array<Scalars['String']>>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  queries?: InputMaybe<Array<Scalars['JSON']>>;
  sorts?: InputMaybe<Array<Sort>>;
};

export type CompanyTableViewsQueryArgs = {
  advancedFilter?: InputMaybe<Scalars['JSON']>;
  filterOptions?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<Filter>>;
  groups?: InputMaybe<Array<Scalars['String']>>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  queries?: InputMaybe<Array<Scalars['JSON']>>;
  sorts?: InputMaybe<Array<Sort>>;
  totals?: InputMaybe<Array<Total>>;
};

export type CompanyTeachersArgs = {
  advancedFilter?: InputMaybe<Scalars['JSON']>;
  filters?: InputMaybe<Array<Filter>>;
  groups?: InputMaybe<Array<Scalars['String']>>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  queries?: InputMaybe<Array<Scalars['JSON']>>;
  sorts?: InputMaybe<Array<Sort>>;
};

export type CompanyTeachersQueryArgs = {
  advancedFilter?: InputMaybe<Scalars['JSON']>;
  filterOptions?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<Filter>>;
  groups?: InputMaybe<Array<Scalars['String']>>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  queries?: InputMaybe<Array<Scalars['JSON']>>;
  sorts?: InputMaybe<Array<Sort>>;
  totals?: InputMaybe<Array<Total>>;
};

export type CompanyTeamAdminsArgs = {
  advancedFilter?: InputMaybe<Scalars['JSON']>;
  filters?: InputMaybe<Array<Filter>>;
  groups?: InputMaybe<Array<Scalars['String']>>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  queries?: InputMaybe<Array<Scalars['JSON']>>;
  sorts?: InputMaybe<Array<Sort>>;
};

export type CompanyTeamAdminsQueryArgs = {
  advancedFilter?: InputMaybe<Scalars['JSON']>;
  filterOptions?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<Filter>>;
  groups?: InputMaybe<Array<Scalars['String']>>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  queries?: InputMaybe<Array<Scalars['JSON']>>;
  sorts?: InputMaybe<Array<Sort>>;
  totals?: InputMaybe<Array<Total>>;
};

export type CompanyTeamMembersArgs = {
  advancedFilter?: InputMaybe<Scalars['JSON']>;
  filters?: InputMaybe<Array<Filter>>;
  groups?: InputMaybe<Array<Scalars['String']>>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  queries?: InputMaybe<Array<Scalars['JSON']>>;
  sorts?: InputMaybe<Array<Sort>>;
};

export type CompanyTeamMembersQueryArgs = {
  advancedFilter?: InputMaybe<Scalars['JSON']>;
  filterOptions?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<Filter>>;
  groups?: InputMaybe<Array<Scalars['String']>>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  queries?: InputMaybe<Array<Scalars['JSON']>>;
  sorts?: InputMaybe<Array<Sort>>;
  totals?: InputMaybe<Array<Total>>;
};

export type CompanyDirectoryResult = {
  course: Course;
  distance?: Maybe<Scalars['Int']>;
  reviewAverage?: Maybe<Scalars['Float']>;
  reviewCount?: Maybe<Scalars['Int']>;
};

export type CompanyDirectoryResults = {
  courses: Array<CompanyDirectoryResult>;
  stems?: Maybe<Array<Scalars['String']>>;
  total: Scalars['Int'];
};

export type CompanyQuickStartGuide = {
  courseFrameworksComplete?: Maybe<Scalars['Boolean']>;
  coursesComplete?: Maybe<Scalars['Boolean']>;
  hidden?: Maybe<Scalars['Boolean']>;
  intructorsComplete?: Maybe<Scalars['Boolean']>;
  profileComplete?: Maybe<Scalars['Boolean']>;
  stripeComplete?: Maybe<Scalars['Boolean']>;
};

export enum CompanyRole {
  CompanyAdmin = 'COMPANY_ADMIN',
  CompanyMember = 'COMPANY_MEMBER'
}

/** Autogenerated return type of CompanyUpdate */
export type CompanyUpdatePayload = {
  company?: Maybe<Company>;
};

/** Autogenerated return type of CompanyUpsertTeacher */
export type CompanyUpsertTeacherPayload = {
  company?: Maybe<Company>;
  teacher?: Maybe<Teacher>;
};

/** Autogenerated return type of CompanyUpsertTeachers */
export type CompanyUpsertTeachersPayload = {
  company?: Maybe<Company>;
};

export type ComplianceDefinition = ActiveRecordInterface & {
  complianceType: Scalars['String'];
  downloads?: Maybe<Array<Maybe<FileUpload>>>;
  /** The unique identifier of the resource. */
  id: Scalars['ID'];
  instructions?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  site?: Maybe<Site>;
};

export type ComplianceDefinitionInput = {
  archived: Scalars['Boolean'];
  complianceType?: InputMaybe<Scalars['String']>;
  downloads?: InputMaybe<Array<FileUploadAttributes>>;
  id?: InputMaybe<Scalars['ID']>;
  instructions?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type ComplianceDefinitions = {
  teacher?: Maybe<Array<Maybe<ComplianceDefinition>>>;
  vendor?: Maybe<Array<Maybe<ComplianceDefinition>>>;
};

export type ComplianceDefinitionsInput = {
  teacher: Array<InputMaybe<ComplianceDefinitionInput>>;
  vendor: Array<InputMaybe<ComplianceDefinitionInput>>;
};

export type ComplianceReportRow = {
  files?: Maybe<Scalars['String']>;
  instructor?: Maybe<Scalars['String']>;
  provider: Scalars['String'];
  requirement: Scalars['String'];
  status?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

export type ComplianceRequirement = ActiveRecordInterface & {
  definition: ComplianceDefinition;
  expiresAt?: Maybe<Scalars['ISO8601Date']>;
  /** The unique identifier of the resource. */
  id: Scalars['ID'];
  status?: Maybe<Scalars['String']>;
  target: ComplianceTarget;
  uploads?: Maybe<Array<Maybe<FileUpload>>>;
};

export type ComplianceRequirementInput = {
  expiresAt?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  status?: InputMaybe<Scalars['String']>;
  uploads?: InputMaybe<Array<FileUploadAttributes>>;
};

export type ComplianceTarget = {
  id: Scalars['ID'];
};

export type ConfirmedEnrollmentInput = {
  dismissal?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  pickup?: InputMaybe<Scalars['String']>;
  studentId?: InputMaybe<Scalars['ID']>;
};

export type Contact = ActiveRecordInterface &
  Participant & {
    attendanceCodeCode?: Maybe<Scalars['String']>;
    email?: Maybe<Scalars['String']>;
    firstName?: Maybe<Scalars['String']>;
    /** The unique identifier of the resource. */
    id: Scalars['ID'];
    kind: ParticipantKind;
    lastName?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
    normalizedRole?: Maybe<MemberRole>;
    phone?: Maybe<Scalars['String']>;
    position?: Maybe<Scalars['String']>;
    relation?: Maybe<Scalars['String']>;
    role: Scalars['String'];
  };

export type ContactInput = {
  _destroy?: InputMaybe<Scalars['Boolean']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  position?: InputMaybe<Scalars['String']>;
  relation?: InputMaybe<Scalars['String']>;
};

export type Course = ActiveRecordInterface &
  WithName &
  WithRoles & {
    ageGroup?: Maybe<Scalars['String']>;
    ageMax?: Maybe<Scalars['Int']>;
    ageMin?: Maybe<Scalars['Int']>;
    awayScore?: Maybe<Scalars['Int']>;
    awayTeam?: Maybe<Course>;
    behavior?: Maybe<CourseBehavior>;
    cancelledAt?: Maybe<Scalars['ISO8601DateTime']>;
    cartedCount: Scalars['Int'];
    cfQuestions?: Maybe<Array<CfQuestion>>;
    childCourses: Array<Course>;
    childOptionCourses: Array<Course>;
    childVariantCourses: Array<Course>;
    children: Array<Course>;
    classesCount: Scalars['Int'];
    closed: Scalars['Boolean'];
    company?: Maybe<Company>;
    cost?: Maybe<Scalars['Float']>;
    courseCardImage?: Maybe<Scalars['String']>;
    courseDays: Array<Maybe<CourseDay>>;
    courseDaysSortKey: Scalars['String'];
    courseFramework?: Maybe<CourseFramework>;
    courseFrameworkId?: Maybe<Scalars['ID']>;
    courseImage?: Maybe<Scalars['String']>;
    courseTags: Array<Maybe<CourseTag>>;
    days: Array<Maybe<Scalars['String']>>;
    description?: Maybe<Scalars['String']>;
    disambiguatedName?: Maybe<Scalars['String']>;
    discardedAt?: Maybe<Scalars['ISO8601DateTime']>;
    effectiveSiteFeeRate: Scalars['Float'];
    effectiveSiteFeeType: SiteFeeTypeEnum;
    endDate?: Maybe<Scalars['ISO8601Date']>;
    enrolledCount: Scalars['Int'];
    enrollmentCloses?: Maybe<Scalars['ISO8601DateTime']>;
    enrollmentOpens?: Maybe<Scalars['ISO8601DateTime']>;
    enrollments?: Maybe<Array<Maybe<Enrollment>>>;
    enrollmentsQuery?: Maybe<EnrollmentsQueryResults>;
    events?: Maybe<Array<Event>>;
    eventsQuery?: Maybe<EventsQueryResults>;
    feeRate?: Maybe<Scalars['Float']>;
    feeType?: Maybe<SiteFeeTypeEnum>;
    forRosterWithInvitesCount: Scalars['Int'];
    formattedCourseDays: Scalars['String'];
    formattedDaysAndTimes: Scalars['String'];
    gradeGroup?: Maybe<Scalars['String']>;
    grades?: Maybe<Array<Maybe<Scalars['String']>>>;
    hasCheckoutOptions: Scalars['Boolean'];
    hasEnrolledStudents: Scalars['Boolean'];
    hasOptionParents: Scalars['Boolean'];
    hasOptions: Scalars['Boolean'];
    hasParentCourses: Scalars['Boolean'];
    hasParentEnrollments: Scalars['Boolean'];
    hasParentOptionCourses: Scalars['Boolean'];
    homeScore?: Maybe<Scalars['Int']>;
    homeTeam?: Maybe<Course>;
    id: Scalars['ID'];
    kind?: Maybe<CourseKind>;
    lastWithdrawDate?: Maybe<Scalars['ISO8601DateTime']>;
    listPrice: Scalars['Float'];
    materialsFee?: Maybe<Scalars['Float']>;
    maxCapacity?: Maybe<Scalars['Int']>;
    messages: Array<Message>;
    minCapacity?: Maybe<Scalars['Int']>;
    name?: Maybe<Scalars['String']>;
    nameForRoster?: Maybe<Scalars['String']>;
    noEnrichmentDays: Array<Maybe<Scalars['ISO8601Date']>>;
    notes?: Maybe<Scalars['String']>;
    optionParents?: Maybe<Array<Maybe<Enrollment>>>;
    optionParentsQuery?: Maybe<OptionParentsQueryResults>;
    options: Array<Maybe<CourseOption>>;
    parent?: Maybe<Course>;
    parentCourses: Array<Course>;
    parentEnrollments?: Maybe<Array<Maybe<Enrollment>>>;
    parentEnrollmentsQuery?: Maybe<ParentEnrollmentsQueryResults>;
    parentOptionCourses: Array<Course>;
    parentVariant?: Maybe<Course>;
    parentVariantCourse?: Maybe<Course>;
    parents?: Maybe<Array<MessageEntity>>;
    priceBreakdowns: PriceBreakdowns;
    priceConfigs?: Maybe<Array<PriceConfig>>;
    prices: Prices;
    rates: Rates;
    removedCount: Scalars['Int'];
    reviewAverage?: Maybe<Scalars['Float']>;
    reviewCount?: Maybe<Scalars['Int']>;
    reviews: Array<Review>;
    room?: Maybe<Scalars['String']>;
    scholarshipCode?: Maybe<Scalars['String']>;
    score?: Maybe<Scalars['String']>;
    searchable: Scalars['Boolean'];
    season: Season;
    seasonId: Scalars['ID'];
    site: Site;
    siteGradeLabels?: Maybe<Array<Maybe<Scalars['String']>>>;
    siteId: Scalars['ID'];
    startDate?: Maybe<Scalars['ISO8601Date']>;
    status: CourseStatus;
    supplies?: Maybe<Array<Maybe<Scalars['String']>>>;
    teacher?: Maybe<Teacher>;
    teacherId?: Maybe<Scalars['ID']>;
    teamMaxCapacity?: Maybe<Scalars['Int']>;
    token: Scalars['String'];
    usageBillingDate?: Maybe<Scalars['ISO8601Date']>;
    userCompanyRole?: Maybe<MemberRole>;
    userCourseRole?: Maybe<MemberRole>;
    userFamilyRole?: Maybe<MemberRole>;
    userSiteRole?: Maybe<MemberRole>;
    vendor?: Maybe<Vendor>;
    waitlistOpen: Scalars['Boolean'];
    waitlistedCount: Scalars['Int'];
  };

export type CourseEnrollmentsArgs = {
  advancedFilter?: InputMaybe<Scalars['JSON']>;
  filters?: InputMaybe<Array<Filter>>;
  groups?: InputMaybe<Array<Scalars['String']>>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  queries?: InputMaybe<Array<Scalars['JSON']>>;
  sorts?: InputMaybe<Array<Sort>>;
};

export type CourseEnrollmentsQueryArgs = {
  advancedFilter?: InputMaybe<Scalars['JSON']>;
  filterOptions?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<Filter>>;
  groups?: InputMaybe<Array<Scalars['String']>>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  queries?: InputMaybe<Array<Scalars['JSON']>>;
  sorts?: InputMaybe<Array<Sort>>;
  totals?: InputMaybe<Array<Total>>;
};

export type CourseEventsArgs = {
  advancedFilter?: InputMaybe<Scalars['JSON']>;
  filters?: InputMaybe<Array<Filter>>;
  groups?: InputMaybe<Array<Scalars['String']>>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  queries?: InputMaybe<Array<Scalars['JSON']>>;
  sorts?: InputMaybe<Array<Sort>>;
};

export type CourseEventsQueryArgs = {
  advancedFilter?: InputMaybe<Scalars['JSON']>;
  filterOptions?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<Filter>>;
  groups?: InputMaybe<Array<Scalars['String']>>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  queries?: InputMaybe<Array<Scalars['JSON']>>;
  sorts?: InputMaybe<Array<Sort>>;
  totals?: InputMaybe<Array<Total>>;
};

export type CourseOptionParentsArgs = {
  advancedFilter?: InputMaybe<Scalars['JSON']>;
  filters?: InputMaybe<Array<Filter>>;
  groups?: InputMaybe<Array<Scalars['String']>>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  queries?: InputMaybe<Array<Scalars['JSON']>>;
  sorts?: InputMaybe<Array<Sort>>;
};

export type CourseOptionParentsQueryArgs = {
  advancedFilter?: InputMaybe<Scalars['JSON']>;
  filterOptions?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<Filter>>;
  groups?: InputMaybe<Array<Scalars['String']>>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  queries?: InputMaybe<Array<Scalars['JSON']>>;
  sorts?: InputMaybe<Array<Sort>>;
  totals?: InputMaybe<Array<Total>>;
};

export type CourseParentEnrollmentsArgs = {
  advancedFilter?: InputMaybe<Scalars['JSON']>;
  filters?: InputMaybe<Array<Filter>>;
  groups?: InputMaybe<Array<Scalars['String']>>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  queries?: InputMaybe<Array<Scalars['JSON']>>;
  sorts?: InputMaybe<Array<Sort>>;
};

export type CourseParentEnrollmentsQueryArgs = {
  advancedFilter?: InputMaybe<Scalars['JSON']>;
  filterOptions?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<Filter>>;
  groups?: InputMaybe<Array<Scalars['String']>>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  queries?: InputMaybe<Array<Scalars['JSON']>>;
  sorts?: InputMaybe<Array<Sort>>;
  totals?: InputMaybe<Array<Total>>;
};

export type CourseBehavior = ActiveRecordInterface & {
  attendance?: Maybe<Scalars['Boolean']>;
  attendanceLink?: Maybe<Scalars['Boolean']>;
  billing?: Maybe<Scalars['Boolean']>;
  enrollmentAdd?: Maybe<Scalars['Boolean']>;
  enrollmentAddExistingStudent?: Maybe<Scalars['Boolean']>;
  enrollmentAddWithDiscount?: Maybe<Scalars['Boolean']>;
  enrollments?: Maybe<Scalars['Boolean']>;
  enrollmentsChangeSeasonPlan?: Maybe<Scalars['Boolean']>;
  enrollmentsChangeSubscription?: Maybe<Scalars['Boolean']>;
  enrollmentsCustomCharge?: Maybe<Scalars['Boolean']>;
  enrollmentsMove?: Maybe<Scalars['Boolean']>;
  enrollmentsPromote?: Maybe<Scalars['Boolean']>;
  enrollmentsReenroll?: Maybe<Scalars['Boolean']>;
  enrollmentsRefund?: Maybe<Scalars['Boolean']>;
  enrollmentsRemove?: Maybe<Scalars['Boolean']>;
  enrollmentsSetGroups?: Maybe<Scalars['Boolean']>;
  /** The unique identifier of the resource. */
  id: Scalars['ID'];
  info?: Maybe<Scalars['Boolean']>;
  messages?: Maybe<Scalars['Boolean']>;
  options?: Maybe<Scalars['Boolean']>;
  reviews?: Maybe<Scalars['Boolean']>;
  studentUpdate?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of CourseBillNow */
export type CourseBillNowPayload = {
  success?: Maybe<Scalars['Boolean']>;
};

export type CourseDay = {
  days?: Maybe<Array<Scalars['String']>>;
  finish?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['String']>;
};

export type CourseDaysInput = {
  days?: InputMaybe<Array<Scalars['String']>>;
  finish?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['String']>;
};

export enum CourseDraftStatus {
  Draft = 'DRAFT',
  Requested = 'REQUESTED'
}

export type CourseFeeBreakdown = {
  discountAmount: Scalars['Float'];
  homeroomCharges: Scalars['Float'];
  listPrice: Scalars['Float'];
  listPriceBeforeDiscount: Scalars['Float'];
  siteCharges: Scalars['Float'];
  siteCreditCardFee: Scalars['Float'];
  siteReceives: Scalars['Float'];
  vendorCharges: Scalars['Float'];
  vendorCourseCost: Scalars['Float'];
  vendorCreditCardFee: Scalars['Float'];
  vendorMaterialsFee: Scalars['Float'];
  vendorReceives: Scalars['Float'];
};

export enum CourseFilterOptionKind {
  Classes = 'CLASSES',
  Cost = 'COST',
  CourseFramework = 'COURSE_FRAMEWORK',
  CourseTags = 'COURSE_TAGS',
  DropInRate = 'DROP_IN_RATE',
  EndDate = 'END_DATE',
  Enrolled = 'ENROLLED',
  EnrollmentCloses = 'ENROLLMENT_CLOSES',
  EnrollmentOpens = 'ENROLLMENT_OPENS',
  Grades = 'GRADES',
  HasParentCourses = 'HAS_PARENT_COURSES',
  HasParentOptionCourses = 'HAS_PARENT_OPTION_COURSES',
  MaterialsFee = 'MATERIALS_FEE',
  MaterialsRate = 'MATERIALS_RATE',
  MaxCapacity = 'MAX_CAPACITY',
  MinCapacity = 'MIN_CAPACITY',
  Name = 'NAME',
  NoEnrichmentDays = 'NO_ENRICHMENT_DAYS',
  Recurring = 'RECURRING',
  Removed = 'REMOVED',
  Room = 'ROOM',
  SeasonRate = 'SEASON_RATE',
  StartDate = 'START_DATE',
  Status = 'STATUS',
  Teacher = 'TEACHER',
  TeamMaxCapacity = 'TEAM_MAX_CAPACITY',
  UsageGracePeriods = 'USAGE_GRACE_PERIODS',
  UsageRates = 'USAGE_RATES',
  UsageRoundingIncrements = 'USAGE_ROUNDING_INCREMENTS',
  UsageUnits = 'USAGE_UNITS',
  Vendor = 'VENDOR',
  Waitlisted = 'WAITLISTED'
}

export type CourseFilters = {
  byClassesCount?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  byCost?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  byCourseFramework?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  byCourseTag?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  byDay?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  byDropInRates?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  byEndDate?: InputMaybe<Array<InputMaybe<Scalars['ISO8601Date']>>>;
  byEnrolledCount?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  byEnrollmentCloses?: InputMaybe<Array<InputMaybe<Scalars['ISO8601DateTime']>>>;
  byEnrollmentOpens?: InputMaybe<Array<InputMaybe<Scalars['ISO8601DateTime']>>>;
  byGradeLabel?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  byHasParentCourses?: InputMaybe<Array<InputMaybe<Scalars['Boolean']>>>;
  byHasParentOptionCourses?: InputMaybe<Array<InputMaybe<Scalars['Boolean']>>>;
  byMaterialsFee?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  byMaterialsRates?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  byMaxCapacity?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  byMinCapacity?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  byName?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  byNoEnrichmentDay?: InputMaybe<Array<InputMaybe<Scalars['ISO8601Date']>>>;
  byRecurringLabels?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  byRemovedCount?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  byRoom?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  bySeasonRates?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  bySiteFeeRate?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  bySiteFeeType?: InputMaybe<Array<InputMaybe<SiteFeeTypeEnum>>>;
  byStartDate?: InputMaybe<Array<InputMaybe<Scalars['ISO8601Date']>>>;
  byStatus?: InputMaybe<Array<InputMaybe<CourseStatus>>>;
  bySupplies?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  byTeacher?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  byTeamMaxCapacity?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  byUsageGracePeriods?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  byUsageRates?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  byUsageRoundingIncrements?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  byUsageUnits?: InputMaybe<Array<InputMaybe<UsageUnit>>>;
  byVendor?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  byWaitlistedCount?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type CourseFramework = ActiveRecordInterface & {
  courseCardImage?: Maybe<Scalars['String']>;
  courseImage?: Maybe<Scalars['String']>;
  courseTags?: Maybe<Array<Maybe<CourseTag>>>;
  description: Scalars['String'];
  /** The unique identifier of the resource. */
  id: Scalars['ID'];
  lastUsedCourse?: Maybe<Course>;
  name: Scalars['String'];
  public?: Maybe<Scalars['Boolean']>;
  reviewAverage?: Maybe<Scalars['Float']>;
  reviewCount?: Maybe<Scalars['Int']>;
  reviews: Array<Review>;
  supplies?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** Autogenerated return type of CourseFrameworkArchive */
export type CourseFrameworkArchivePayload = {
  success?: Maybe<Scalars['Boolean']>;
};

export type CourseFrameworkInput = {
  companyId: Scalars['ID'];
  courseCardImage?: InputMaybe<Scalars['String']>;
  courseImage?: InputMaybe<Scalars['String']>;
  courseTagIds?: InputMaybe<Array<Scalars['ID']>>;
  description: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  public?: InputMaybe<Scalars['Boolean']>;
  supplies?: InputMaybe<Array<Scalars['String']>>;
};

export type CourseFrameworksQueryResults = CollectionInfo & {
  count?: Maybe<Scalars['Int']>;
  filterOptions?: Maybe<Array<Maybe<Option>>>;
  /** The unique identifier of the resource. */
  id: Scalars['ID'];
  items?: Maybe<Array<CourseFramework>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  pages?: Maybe<Scalars['Int']>;
  totals?: Maybe<Array<Scalars['Int']>>;
};

/** Autogenerated return type of CourseFrameworksUpsert */
export type CourseFrameworksUpsertPayload = {
  items?: Maybe<Array<CourseFramework>>;
};

export type CourseInput = {
  ageMax?: InputMaybe<Scalars['Int']>;
  ageMin?: InputMaybe<Scalars['Int']>;
  awayScore?: InputMaybe<Scalars['Int']>;
  awayTeamId?: InputMaybe<Scalars['ID']>;
  clientGeneratedId?: InputMaybe<Scalars['String']>;
  cost?: InputMaybe<Scalars['Float']>;
  courseCardImage?: InputMaybe<Scalars['String']>;
  courseDays?: InputMaybe<Array<CourseDaysInput>>;
  courseFrameworkAttributes?: InputMaybe<CourseFrameworkInput>;
  courseFrameworkId?: InputMaybe<Scalars['ID']>;
  courseImage?: InputMaybe<Scalars['String']>;
  courseTagIds?: InputMaybe<Array<Scalars['ID']>>;
  description?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['ISO8601Date']>;
  enrollmentCloses?: InputMaybe<Scalars['ISO8601DateTime']>;
  enrollmentOpens?: InputMaybe<Scalars['ISO8601DateTime']>;
  feeRate?: InputMaybe<Scalars['Float']>;
  feeType?: InputMaybe<SiteFeeTypeEnum>;
  grades?: InputMaybe<Array<Scalars['String']>>;
  homeScore?: InputMaybe<Scalars['Int']>;
  homeTeamId?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  kind?: InputMaybe<CourseKind>;
  materialsFee?: InputMaybe<Scalars['Float']>;
  maxCapacity?: InputMaybe<Scalars['Int']>;
  minCapacity?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  noEnrichmentDays?: InputMaybe<Array<InputMaybe<Scalars['ISO8601Date']>>>;
  options?: InputMaybe<Array<CourseOptionInput>>;
  rates?: InputMaybe<RatesInput>;
  room?: InputMaybe<Scalars['String']>;
  searchable?: InputMaybe<Scalars['Boolean']>;
  siteId?: InputMaybe<Scalars['ID']>;
  startDate?: InputMaybe<Scalars['ISO8601Date']>;
  supplies?: InputMaybe<Array<Scalars['String']>>;
  teacherAttributes?: InputMaybe<TeacherInput>;
  teacherId?: InputMaybe<Scalars['ID']>;
  teamMaxCapacity?: InputMaybe<Scalars['Int']>;
  vendorId?: InputMaybe<Scalars['ID']>;
};

/** Autogenerated return type of CourseInviteAccept */
export type CourseInviteAcceptPayload = {
  companies?: Maybe<Array<Company>>;
  site?: Maybe<Site>;
  success?: Maybe<Scalars['Boolean']>;
  user?: Maybe<User>;
};

export enum CourseKind {
  AthleticIndividualReg = 'athletic_individual_reg',
  AthleticReg = 'athletic_reg',
  Camp = 'camp',
  Course = 'course',
  Enrichment = 'enrichment',
  ExtendedCare = 'extended_care',
  Game = 'game',
  LessonSet = 'lesson_set',
  Team = 'team',
  TimeSlot = 'time_slot',
  Variant = 'variant'
}

export enum CourseKindGroup {
  Athletics = 'athletics',
  Camp = 'camp',
  Enrichment = 'enrichment',
  ExtendedCare = 'extended_care',
  LessonSet = 'lesson_set'
}

export type CourseOption = ActiveRecordInterface & {
  checkout: Scalars['Boolean'];
  choices: Array<Course>;
  description?: Maybe<Scalars['String']>;
  /** The unique identifier of the resource. */
  id: Scalars['ID'];
  kind: CourseOptionKind;
  required: Scalars['Boolean'];
  title: Scalars['String'];
};

export type CourseOptionInput = {
  archive?: InputMaybe<Scalars['Boolean']>;
  checkout?: InputMaybe<Scalars['Boolean']>;
  choices?: InputMaybe<Array<Scalars['String']>>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  kind?: InputMaybe<CourseOptionKind>;
  required?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
};

export enum CourseOptionKind {
  MultipleChoice = 'MULTIPLE_CHOICE',
  SingleChoice = 'SINGLE_CHOICE'
}

export enum CourseRole {
  Admin = 'ADMIN',
  Member = 'MEMBER'
}

export type CourseSort = {
  by: CourseSortBy;
  direction?: InputMaybe<SortDirectionEnum>;
};

export enum CourseSortBy {
  Classes = 'CLASSES',
  Cost = 'COST',
  CourseDay = 'COURSE_DAY',
  CourseFramework = 'COURSE_FRAMEWORK',
  CourseTag = 'COURSE_TAG',
  DropInRate = 'DROP_IN_RATE',
  EndDate = 'END_DATE',
  Enrolled = 'ENROLLED',
  EnrollmentCloses = 'ENROLLMENT_CLOSES',
  EnrollmentOpens = 'ENROLLMENT_OPENS',
  HasParentCourses = 'HAS_PARENT_COURSES',
  HasParentOptionCourses = 'HAS_PARENT_OPTION_COURSES',
  MaterialsFee = 'MATERIALS_FEE',
  MaterialsRate = 'MATERIALS_RATE',
  MaxCapacity = 'MAX_CAPACITY',
  MinCapacity = 'MIN_CAPACITY',
  Name = 'NAME',
  Removed = 'REMOVED',
  Room = 'ROOM',
  SeasonRate = 'SEASON_RATE',
  SiteFeeRate = 'SITE_FEE_RATE',
  SiteFeeType = 'SITE_FEE_TYPE',
  StartDate = 'START_DATE',
  Status = 'STATUS',
  Teacher = 'TEACHER',
  TeamMaxCapacity = 'TEAM_MAX_CAPACITY',
  UsageGracePeriod = 'USAGE_GRACE_PERIOD',
  UsageRate = 'USAGE_RATE',
  UsageRoundingIncrement = 'USAGE_ROUNDING_INCREMENT',
  UsageUnit = 'USAGE_UNIT',
  Vendor = 'VENDOR',
  Waitlisted = 'WAITLISTED'
}

export enum CourseStatus {
  Active = 'ACTIVE',
  AwaitingApproval = 'AWAITING_APPROVAL',
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Deleted = 'DELETED',
  Draft = 'DRAFT',
  Enrolling = 'ENROLLING',
  Request = 'REQUEST',
  Upcoming = 'UPCOMING'
}

export type CourseSummaryReport = {
  data: Array<Maybe<CourseSummaryReportRow>>;
  totalEnrollments?: Maybe<Scalars['Int']>;
  totalParentSpend: Scalars['Float'];
  totalProviderEarnings?: Maybe<Scalars['Float']>;
  totalSchoolEarnings: Scalars['Float'];
};

export type CourseSummaryReportRow = {
  course: Scalars['String'];
  endDate: Scalars['ISO8601Date'];
  enrollments: Scalars['Int'];
  grades: Scalars['String'];
  id: Scalars['String'];
  listPrice: Scalars['Float'];
  period: Scalars['String'];
  provider: Scalars['String'];
  providerEarnings?: Maybe<Scalars['Float']>;
  schoolEarnings: Scalars['Float'];
  site: Scalars['String'];
  startDate: Scalars['ISO8601Date'];
};

export type CourseTag = ActiveRecordInterface & {
  cardImgUrl?: Maybe<Scalars['String']>;
  heading2?: Maybe<Scalars['String']>;
  heroImgUrl?: Maybe<Scalars['String']>;
  /** The unique identifier of the resource. */
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

export type CourseTagsQueryResults = CollectionInfo & {
  count?: Maybe<Scalars['Int']>;
  filterOptions?: Maybe<Array<Maybe<Option>>>;
  /** The unique identifier of the resource. */
  id: Scalars['ID'];
  items?: Maybe<Array<CourseTag>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  pages?: Maybe<Scalars['Int']>;
  totals?: Maybe<Array<Scalars['Int']>>;
};

/** Autogenerated return type of CoursesApproveMutation */
export type CoursesApproveMutationPayload = {
  courses?: Maybe<Array<Maybe<Course>>>;
};

/** Autogenerated return type of CoursesCancelOrDeleteMutation */
export type CoursesCancelOrDeleteMutationPayload = {
  courses?: Maybe<Array<Maybe<Course>>>;
};

/** Autogenerated return type of CoursesCloseEnrollmentMutation */
export type CoursesCloseEnrollmentMutationPayload = {
  courses?: Maybe<Array<Maybe<Course>>>;
};

/** Autogenerated return type of CoursesFinalizeMutation */
export type CoursesFinalizeMutationPayload = {
  courses?: Maybe<Array<Maybe<Course>>>;
};

/** Autogenerated return type of CoursesOpenEnrollmentMutation */
export type CoursesOpenEnrollmentMutationPayload = {
  courses?: Maybe<Array<Maybe<Course>>>;
};

export type CoursesQueryResults = CollectionInfo & {
  count?: Maybe<Scalars['Int']>;
  filterOptions?: Maybe<Array<Maybe<Option>>>;
  /** The unique identifier of the resource. */
  id: Scalars['ID'];
  items?: Maybe<Array<Course>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  pages?: Maybe<Scalars['Int']>;
  totals?: Maybe<Array<Scalars['Int']>>;
};

export type CoursesResult = {
  draftTotal: Scalars['Int'];
  items: Array<Maybe<Course>>;
  requestedTotal: Scalars['Int'];
  total: Scalars['Int'];
  totals: Array<Scalars['Int']>;
};

/** Autogenerated return type of CoursesSendCourseRequestsMutation */
export type CoursesSendCourseRequestsMutationPayload = {
  courses?: Maybe<Array<Maybe<Course>>>;
};

/** Autogenerated return type of CoursesUpsertMutation */
export type CoursesUpsertMutationPayload = {
  courses?: Maybe<Array<Maybe<Course>>>;
};

export type CreditCard = {
  brand: CardBrand;
  expiration: Scalars['String'];
  id: Scalars['ID'];
  last4: Scalars['String'];
  name?: Maybe<Scalars['String']>;
};

export type CustomPriceConfig = PriceConfig & {
  amount: Scalars['Float'];
  billingDate?: Maybe<Scalars['ISO8601Date']>;
  billingDescription: Scalars['String'];
  description: Scalars['String'];
  kind: PriceConfigKind;
  purchaseDescription?: Maybe<Scalars['String']>;
  template: Scalars['Boolean'];
  updateDescription?: Maybe<Scalars['String']>;
};

export type CustomPriceConfigInput = {
  amount: Scalars['Float'];
  description: Scalars['String'];
};

/** Autogenerated return type of DirectoryStartConversation */
export type DirectoryStartConversationPayload = {
  success: Scalars['Boolean'];
};

export type Discount = {
  amountOff: Scalars['Float'];
  code?: Maybe<Scalars['ID']>;
  format?: Maybe<DiscountFormat>;
  id?: Maybe<Scalars['ID']>;
  rate?: Maybe<Scalars['Float']>;
};

export type DiscountDefinition = ActiveRecordInterface & {
  active: Scalars['Boolean'];
  amount: Scalars['Float'];
  code: Scalars['String'];
  format: DiscountDefinitionFormat;
  /** The unique identifier of the resource. */
  id: Scalars['ID'];
  kind: DiscountKind;
  usesCount: Scalars['Int'];
};

export enum DiscountDefinitionFormat {
  Fixed = 'FIXED',
  Percentage = 'PERCENTAGE'
}

export type DiscountDefinitionInput = {
  active: Scalars['Boolean'];
  amount: Scalars['Float'];
  archive?: InputMaybe<Scalars['Boolean']>;
  code: Scalars['String'];
  format: DiscountDefinitionFormat;
  id: Scalars['ID'];
  kind: DiscountKind;
};

/** Autogenerated return type of DiscountDefinitionsUpsert */
export type DiscountDefinitionsUpsertPayload = {
  discounts?: Maybe<Array<DiscountDefinition>>;
};

export enum DiscountFormat {
  Number = 'NUMBER',
  Percentage = 'PERCENTAGE'
}

export type DiscountInput = {
  format?: InputMaybe<DiscountFormat>;
  rate?: InputMaybe<Scalars['Float']>;
};

export enum DiscountKind {
  Activity = 'ACTIVITY',
  Sibling = 'SIBLING'
}

export type DiscountUse = ActiveRecordInterface & {
  amount: Scalars['Float'];
  amountOff: Scalars['Float'];
  code: Scalars['String'];
  discountDefinitionId: Scalars['ID'];
  format?: Maybe<DiscountFormat>;
  /** The unique identifier of the resource. */
  id: Scalars['ID'];
  kind: DiscountKind;
  rate?: Maybe<Scalars['Float']>;
};

export type Donation = ActiveRecordInterface & {
  coverTxnFees: Scalars['Boolean'];
  finalAmount: Scalars['Float'];
  /** The unique identifier of the resource. */
  id: Scalars['ID'];
  initialAmount: Scalars['Float'];
  seasonId: Scalars['ID'];
  site: Site;
  txnFee: Scalars['Float'];
};

export type DonationConfig = {
  charityName?: Maybe<Scalars['String']>;
  ein?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  nonprofit: Scalars['Boolean'];
  siteEin?: Maybe<Scalars['String']>;
};

export type DonationConfigInput = {
  charityName?: InputMaybe<Scalars['String']>;
  ein?: InputMaybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  nonprofit: Scalars['Boolean'];
  siteEin?: InputMaybe<Scalars['String']>;
};

export type DropInPrice = {
  price?: Maybe<Scalars['Float']>;
};

export type DropInPriceConfig = PriceConfig & {
  billingDate?: Maybe<Scalars['ISO8601Date']>;
  billingDescription: Scalars['String'];
  dates: Array<Scalars['ISO8601Date']>;
  kind: PriceConfigKind;
  purchaseDescription?: Maybe<Scalars['String']>;
  template: Scalars['Boolean'];
  updateDescription?: Maybe<Scalars['String']>;
};

export type DropInPriceConfigInput = {
  dates: Array<InputMaybe<Scalars['ISO8601Date']>>;
};

export type DropInRate = {
  homeroomFeeRoundingIncrementCents?: Maybe<Scalars['Int']>;
  rate?: Maybe<Scalars['Float']>;
};

export type DropInRateInput = {
  homeroomFeeRoundingIncrementCents?: InputMaybe<Scalars['Int']>;
  rate?: InputMaybe<Scalars['Float']>;
};

export type EnrollingSoonSeason = ActiveRecordInterface &
  WithName & {
    approvedCourseKindGroups: Array<CourseKindGroup>;
    cfQuestions?: Maybe<Array<CfQuestion>>;
    completed: Scalars['Boolean'];
    courseKindGroups: Array<CourseKindGroup>;
    courses: Array<Course>;
    coursesBegin: Scalars['ISO8601DateTime'];
    coursesFinish: Scalars['ISO8601DateTime'];
    enrollmentCloses: Scalars['ISO8601DateTime'];
    enrollmentOpens: Scalars['ISO8601DateTime'];
    enrollmentPeriod?: Maybe<Scalars['String']>;
    formattedKind?: Maybe<Scalars['String']>;
    games: Array<Course>;
    hasConfigurablePricing: Scalars['Boolean'];
    hasCourses: Scalars['Boolean'];
    hasFutureCourses: Scalars['Boolean'];
    hasNonSeasonPricing: Scalars['Boolean'];
    holidays: Array<Maybe<Scalars['ISO8601Date']>>;
    homeroomRate?: Maybe<Scalars['Float']>;
    /** The unique identifier of the resource. */
    id: Scalars['ID'];
    kind?: Maybe<SeasonKind>;
    loadMetrics?: Maybe<SeasonLoadInfo>;
    name: Scalars['String'];
    note?: Maybe<Scalars['String']>;
    open: Scalars['Boolean'];
    payoutConfig: SitePayoutConfig;
    private?: Maybe<Scalars['Boolean']>;
    registrationGroup?: Maybe<Scalars['String']>;
    seasonPeriod?: Maybe<Scalars['String']>;
    site: Site;
    siteId: Scalars['ID'];
    status: SeasonStatus;
    timezone?: Maybe<Scalars['String']>;
    totalCourses?: Maybe<Scalars['Int']>;
    totalSpots?: Maybe<Scalars['Int']>;
    totalSpotsLeft?: Maybe<Scalars['Int']>;
    txnFeeRate?: Maybe<Scalars['Float']>;
    upcoming: Scalars['Boolean'];
  };

export type Enrollment = ActiveRecordInterface &
  CartItem & {
    added?: Maybe<Scalars['ISO8601Date']>;
    additionalDiscount?: Maybe<Discount>;
    affirmEnabled: Scalars['Boolean'];
    ageAtStart?: Maybe<Scalars['Int']>;
    amount?: Maybe<Scalars['Float']>;
    athleticsConfig?: Maybe<AthleticsConfig>;
    balance: Scalars['Float'];
    billPeriod?: Maybe<Scalars['String']>;
    billedAt?: Maybe<Scalars['ISO8601DateTime']>;
    billedDate?: Maybe<Scalars['ISO8601Date']>;
    billingDescription?: Maybe<Scalars['String']>;
    billingStartDate?: Maybe<Scalars['ISO8601Date']>;
    cfAnswers?: Maybe<Scalars['JSON']>;
    cfFormattedAnswers?: Maybe<Scalars['JSON']>;
    cfFormattedSeasonAnswers?: Maybe<Scalars['JSON']>;
    cfQuestions?: Maybe<Array<CfQuestion>>;
    cfSeasonAnswers?: Maybe<Scalars['JSON']>;
    childEnrollments: Array<Enrollment>;
    childOptions: Array<Enrollment>;
    company: Company;
    contacts?: Maybe<Array<Maybe<Contact>>>;
    course: Course;
    courseOption?: Maybe<CourseOption>;
    createdAt: Scalars['ISO8601DateTime'];
    discount?: Maybe<Discount>;
    discountAmount: Scalars['Float'];
    discountCodes: Array<Scalars['String']>;
    discountId?: Maybe<Scalars['ID']>;
    discountUses: Array<DiscountUse>;
    discountedPrice: Scalars['Float'];
    dismissal?: Maybe<Scalars['String']>;
    endDate?: Maybe<Scalars['ISO8601Date']>;
    enrollmentOptions: Array<EnrollmentOption>;
    enrollmentPriceTierUse?: Maybe<EnrollmentPriceTierUse>;
    family?: Maybe<Family>;
    firstPayment?: Maybe<FirstPayment>;
    formattedDays: Scalars['String'];
    formattedDaysAndTimes: Scalars['String'];
    formattedOtherEnrollments: Scalars['String'];
    formattedTimes: Scalars['String'];
    gradeAtStart?: Maybe<Scalars['String']>;
    groups?: Maybe<Array<Scalars['String']>>;
    id: Scalars['ID'];
    inAgeRange?: Maybe<Scalars['Boolean']>;
    inGradeRange?: Maybe<Scalars['Boolean']>;
    invitationExpired?: Maybe<Scalars['Boolean']>;
    isChildEnrollment: Scalars['Boolean'];
    isInvitation: Scalars['Boolean'];
    isOption: Scalars['Boolean'];
    isOptionParent: Scalars['Boolean'];
    isParentEnrollment: Scalars['Boolean'];
    isScholarshipHold: Scalars['Boolean'];
    lateStart: Scalars['Boolean'];
    listPrice: Scalars['Float'];
    optionParent?: Maybe<Enrollment>;
    otherEnrollments: Array<Enrollment>;
    overlaps?: Maybe<Scalars['Boolean']>;
    parent?: Maybe<GuardedUser>;
    parentEnrollment?: Maybe<Enrollment>;
    parents?: Maybe<Array<GuardedUser>>;
    paymentFailureReason?: Maybe<Scalars['String']>;
    paymentService?: Maybe<PaymentService>;
    paymentStatus: PaymentStatus;
    pendingChange?: Maybe<EnrollmentChange>;
    pendingInstances: Array<Enrollment>;
    perSessionRefunds: Scalars['Boolean'];
    pickup?: Maybe<Scalars['String']>;
    pickupDropoffRequired: Scalars['Boolean'];
    priceConfig?: Maybe<PriceConfig>;
    priceConfigs?: Maybe<Array<PriceConfig>>;
    priceTierUse?: Maybe<PriceTierUse>;
    purchaseDescription?: Maybe<Scalars['String']>;
    purchased: Scalars['Boolean'];
    refunds: Array<Maybe<Refund>>;
    refundsTotal: Scalars['Float'];
    rosterAmount?: Maybe<Scalars['String']>;
    rosterPeriod?: Maybe<Scalars['String']>;
    rosterPeriods?: Maybe<Scalars['String']>;
    season?: Maybe<Season>;
    startDate?: Maybe<Scalars['ISO8601Date']>;
    status: EnrollmentStatusEnum;
    student?: Maybe<Student>;
    studentSelectionRequired: Scalars['Boolean'];
    templateId?: Maybe<Scalars['String']>;
    trackingParameters?: Maybe<Scalars['String']>;
    waitlistKind: WaitlistKind;
    weekday: Scalars['String'];
    withdrawable?: Maybe<Scalars['Boolean']>;
  };

/** Autogenerated return type of EnrollmentAdd */
export type EnrollmentAddPayload = {
  enrollments?: Maybe<Array<Maybe<Enrollment>>>;
};

export type EnrollmentChange = ActiveRecordInterface & {
  discountedPrice?: Maybe<Scalars['Float']>;
  /** The unique identifier of the resource. */
  id: Scalars['ID'];
  listPrice: Scalars['Float'];
  priceConfig?: Maybe<PriceConfig>;
  startDate?: Maybe<Scalars['ISO8601Date']>;
};

export type EnrollmentInput = {
  afterClassDismissal?: InputMaybe<Scalars['String']>;
  beforeClassPickup?: InputMaybe<Scalars['String']>;
  configurableSeason?: InputMaybe<SeasonPriceConfigInput>;
  custom?: InputMaybe<CustomPriceConfigInput>;
  discount?: InputMaybe<DiscountInput>;
  dismissal?: InputMaybe<Scalars['String']>;
  dropIn?: InputMaybe<DropInPriceConfigInput>;
  dropInPriceConfig?: InputMaybe<DropInPriceConfigInput>;
  id: Scalars['ID'];
  kind?: InputMaybe<PriceConfigKind>;
  options?: InputMaybe<Array<EnrollmentOptionInput>>;
  pickup?: InputMaybe<Scalars['String']>;
  priceKind?: InputMaybe<PriceConfigKind>;
  recurring?: InputMaybe<RecurringPriceConfigInput>;
  recurringPriceConfig?: InputMaybe<RecurringPriceConfigInput>;
  startDate?: InputMaybe<Scalars['ISO8601Date']>;
  studentId?: InputMaybe<Scalars['ID']>;
};

export type EnrollmentOption = {
  choices: Array<Enrollment>;
  option: CourseOption;
};

export type EnrollmentOptionInput = {
  choices: Array<EnrollmentInput>;
  option: Scalars['ID'];
};

export type EnrollmentPriceTierUse = ActiveRecordInterface & {
  cartPriceTierUse: PriceTierUse;
  /** The unique identifier of the resource. */
  id: Scalars['ID'];
};

export enum EnrollmentStatusEnum {
  ExpiredFromCart = 'EXPIRED_FROM_CART',
  Paid = 'PAID',
  Pending = 'PENDING',
  PromotionCancelled = 'PROMOTION_CANCELLED',
  PromotionExpired = 'PROMOTION_EXPIRED',
  ProvisionallyWaitlisted = 'PROVISIONALLY_WAITLISTED',
  RemovedFromCart = 'REMOVED_FROM_CART',
  RemovedFromCourse = 'REMOVED_FROM_COURSE',
  RemovedFromWaitlist = 'REMOVED_FROM_WAITLIST',
  Unpaid = 'UNPAID',
  Waitlisted = 'WAITLISTED'
}

export enum EnrollmentStatusFilter {
  Attendance = 'ATTENDANCE',
  Billed = 'BILLED',
  BilledLaterAdvanced = 'BILLED_LATER_ADVANCED',
  BilledLaterRecurring = 'BILLED_LATER_RECURRING',
  BilledLaterSeason = 'BILLED_LATER_SEASON',
  BilledLaterUsage = 'BILLED_LATER_USAGE',
  Paid = 'PAID',
  Removed = 'REMOVED',
  Rosterable = 'ROSTERABLE',
  Rostered = 'ROSTERED',
  RosteredAndInvited = 'ROSTERED_AND_INVITED',
  Session = 'SESSION',
  Waitlisted = 'WAITLISTED'
}

/** Autogenerated return type of EnrollmentsChangeSeasonPlan */
export type EnrollmentsChangeSeasonPlanPayload = {
  enrollments?: Maybe<Array<Maybe<Enrollment>>>;
};

/** Autogenerated return type of EnrollmentsChangeSubscription */
export type EnrollmentsChangeSubscriptionPayload = {
  enrollments?: Maybe<Array<Maybe<Enrollment>>>;
};

/** Autogenerated return type of EnrollmentsCustomCharge */
export type EnrollmentsCustomChargePayload = {
  enrollments?: Maybe<Array<Maybe<Enrollment>>>;
};

/** Autogenerated return type of EnrollmentsMove */
export type EnrollmentsMovePayload = {
  enrollments?: Maybe<Array<Maybe<Enrollment>>>;
};

/** Autogenerated return type of EnrollmentsPromote */
export type EnrollmentsPromotePayload = {
  enrollments?: Maybe<Array<Maybe<Enrollment>>>;
};

export type EnrollmentsQueryResults = CollectionInfo & {
  count?: Maybe<Scalars['Int']>;
  filterOptions?: Maybe<Array<Maybe<Option>>>;
  /** The unique identifier of the resource. */
  id: Scalars['ID'];
  items?: Maybe<Array<Enrollment>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  pages?: Maybe<Scalars['Int']>;
  totals?: Maybe<Array<Scalars['Int']>>;
};

/** Autogenerated return type of EnrollmentsReenroll */
export type EnrollmentsReenrollPayload = {
  enrollment?: Maybe<Enrollment>;
  enrollments?: Maybe<Array<Maybe<Enrollment>>>;
};

/** Autogenerated return type of EnrollmentsRefund */
export type EnrollmentsRefundPayload = {
  enrollments?: Maybe<Array<Maybe<Enrollment>>>;
};

/** Autogenerated return type of EnrollmentsRemove */
export type EnrollmentsRemovePayload = {
  enrollments?: Maybe<Array<Maybe<Enrollment>>>;
};

/** Autogenerated return type of EnrollmentsResendInvite */
export type EnrollmentsResendInvitePayload = {
  enrollments?: Maybe<Array<Maybe<Enrollment>>>;
};

export type EnrollmentsSetAdditionalDiscountInput = {
  discount?: InputMaybe<DiscountInput>;
  enrollmentId: Scalars['ID'];
};

/** Autogenerated return type of EnrollmentsSetAdditionalDiscount */
export type EnrollmentsSetAdditionalDiscountPayload = {
  enrollments?: Maybe<Array<Enrollment>>;
};

/** Autogenerated return type of EnrollmentsSetAttendance */
export type EnrollmentsSetAttendancePayload = {
  attendances?: Maybe<Array<RosterItem>>;
};

/** Autogenerated return type of EnrollmentsSetGroups */
export type EnrollmentsSetGroupsPayload = {
  enrollments?: Maybe<Array<Maybe<Enrollment>>>;
};

/** Autogenerated return type of EnrollmentsUpdate */
export type EnrollmentsUpdatePayload = {
  success?: Maybe<Scalars['Boolean']>;
};

export enum EntityKind {
  Company = 'COMPANY',
  Family = 'FAMILY',
  Site = 'SITE'
}

export type Event = ActiveRecordInterface & {
  contact?: Maybe<GuardedUser>;
  createdAt: Scalars['ISO8601DateTime'];
  eventAttributes?: Maybe<Scalars['JSON']>;
  eventName?: Maybe<Scalars['String']>;
  /** The unique identifier of the resource. */
  id: Scalars['ID'];
  recipient?: Maybe<Scalars['String']>;
  serviceType?: Maybe<EventService>;
};

export enum EventService {
  Email = 'EMAIL',
  Sms = 'SMS'
}

export type EventsQueryResults = CollectionInfo & {
  count?: Maybe<Scalars['Int']>;
  filterOptions?: Maybe<Array<Maybe<Option>>>;
  /** The unique identifier of the resource. */
  id: Scalars['ID'];
  items?: Maybe<Array<Event>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  pages?: Maybe<Scalars['Int']>;
  totals?: Maybe<Array<Scalars['Int']>>;
};

/** Autogenerated return type of FamiliesMerge */
export type FamiliesMergePayload = {
  family?: Maybe<Family>;
};

export type FamiliesQueryResults = CollectionInfo & {
  count?: Maybe<Scalars['Int']>;
  filterOptions?: Maybe<Array<Maybe<Option>>>;
  /** The unique identifier of the resource. */
  id: Scalars['ID'];
  items?: Maybe<Array<Family>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  pages?: Maybe<Scalars['Int']>;
  totals?: Maybe<Array<Scalars['Int']>>;
};

export type Family = ActiveRecordInterface &
  WithNameAndId &
  WithTeam & {
    ccContacts?: Maybe<Array<Contact>>;
    contacts?: Maybe<Array<Contact>>;
    creditCard?: Maybe<CreditCard>;
    enrollments?: Maybe<Array<Maybe<Enrollment>>>;
    enrollmentsQuery?: Maybe<EnrollmentsQueryResults>;
    id: Scalars['ID'];
    name?: Maybe<Scalars['String']>;
    parents?: Maybe<Array<GuardedUser>>;
    participants?: Maybe<Array<Participant>>;
    participantsQuery?: Maybe<ParticipantsQueryResults>;
    receipts?: Maybe<Array<Receipt>>;
    students?: Maybe<Array<Student>>;
    teamAdmins?: Maybe<Array<Member>>;
    teamAdminsQuery?: Maybe<TeamAdminsQueryResults>;
    teamChangeRole?: Maybe<Scalars['Boolean']>;
    teamInvites?: Maybe<Array<MemberInvitation>>;
    teamMembers?: Maybe<Array<Member>>;
    teamMembersQuery?: Maybe<TeamMembersQueryResults>;
    teamParticipantsUpsert?: Maybe<Scalars['Boolean']>;
    teamSetAsContact?: Maybe<Scalars['Boolean']>;
    usBankAccount?: Maybe<UsBankAccount>;
    user?: Maybe<User>;
    userCompanyRole?: Maybe<MemberRole>;
    userCourseRole?: Maybe<MemberRole>;
    userFamilyRole?: Maybe<MemberRole>;
    userMemberRole?: Maybe<MemberRole>;
    userSiteRole?: Maybe<MemberRole>;
  };

export type FamilyEnrollmentsArgs = {
  advancedFilter?: InputMaybe<Scalars['JSON']>;
  filters?: InputMaybe<Array<Filter>>;
  groups?: InputMaybe<Array<Scalars['String']>>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  queries?: InputMaybe<Array<Scalars['JSON']>>;
  sorts?: InputMaybe<Array<Sort>>;
};

export type FamilyEnrollmentsQueryArgs = {
  advancedFilter?: InputMaybe<Scalars['JSON']>;
  filterOptions?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<Filter>>;
  groups?: InputMaybe<Array<Scalars['String']>>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  queries?: InputMaybe<Array<Scalars['JSON']>>;
  sorts?: InputMaybe<Array<Sort>>;
  totals?: InputMaybe<Array<Total>>;
};

export type FamilyParticipantsArgs = {
  advancedFilter?: InputMaybe<Scalars['JSON']>;
  filters?: InputMaybe<Array<Filter>>;
  groups?: InputMaybe<Array<Scalars['String']>>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  queries?: InputMaybe<Array<Scalars['JSON']>>;
  sorts?: InputMaybe<Array<Sort>>;
};

export type FamilyParticipantsQueryArgs = {
  advancedFilter?: InputMaybe<Scalars['JSON']>;
  filterOptions?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<Filter>>;
  groups?: InputMaybe<Array<Scalars['String']>>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  queries?: InputMaybe<Array<Scalars['JSON']>>;
  sorts?: InputMaybe<Array<Sort>>;
  totals?: InputMaybe<Array<Total>>;
};

export type FamilyTeamAdminsArgs = {
  advancedFilter?: InputMaybe<Scalars['JSON']>;
  filters?: InputMaybe<Array<Filter>>;
  groups?: InputMaybe<Array<Scalars['String']>>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  queries?: InputMaybe<Array<Scalars['JSON']>>;
  sorts?: InputMaybe<Array<Sort>>;
};

export type FamilyTeamAdminsQueryArgs = {
  advancedFilter?: InputMaybe<Scalars['JSON']>;
  filterOptions?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<Filter>>;
  groups?: InputMaybe<Array<Scalars['String']>>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  queries?: InputMaybe<Array<Scalars['JSON']>>;
  sorts?: InputMaybe<Array<Sort>>;
  totals?: InputMaybe<Array<Total>>;
};

export type FamilyTeamMembersArgs = {
  advancedFilter?: InputMaybe<Scalars['JSON']>;
  filters?: InputMaybe<Array<Filter>>;
  groups?: InputMaybe<Array<Scalars['String']>>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  queries?: InputMaybe<Array<Scalars['JSON']>>;
  sorts?: InputMaybe<Array<Sort>>;
};

export type FamilyTeamMembersQueryArgs = {
  advancedFilter?: InputMaybe<Scalars['JSON']>;
  filterOptions?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<Filter>>;
  groups?: InputMaybe<Array<Scalars['String']>>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  queries?: InputMaybe<Array<Scalars['JSON']>>;
  sorts?: InputMaybe<Array<Sort>>;
  totals?: InputMaybe<Array<Total>>;
};

export enum FamilyRole {
  AuthorizedPickup = 'authorized_pickup',
  EmergencyContact = 'emergency_contact',
  Parent = 'parent'
}

export type FileUpload = ActiveRecordInterface & {
  fileType?: Maybe<Scalars['String']>;
  /** The unique identifier of the resource. */
  id: Scalars['ID'];
  name: Scalars['String'];
  uri?: Maybe<Scalars['String']>;
};

export type FileUploadAttributes = {
  archived?: InputMaybe<Scalars['Boolean']>;
  fileData?: InputMaybe<Scalars['String']>;
  fileType?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
};

export type Filter = {
  name: Scalars['String'];
  op?: InputMaybe<FilterOperator>;
  value?: InputMaybe<Scalars['OptionValue']>;
};

export enum FilterOperator {
  Between = 'BETWEEN',
  Contains = 'CONTAINS',
  Eq = 'EQ',
  Gt = 'GT',
  GtEq = 'GT_EQ',
  IsEmpty = 'IS_EMPTY',
  IsNotEmpty = 'IS_NOT_EMPTY',
  Lt = 'LT',
  LtEq = 'LT_EQ',
  NotEq = 'NOT_EQ'
}

export type FirstPayment = {
  amount: Scalars['Float'];
  dueOn: Scalars['ISO8601Date'];
  nextPaymentOn?: Maybe<Scalars['ISO8601Date']>;
  prorated: Scalars['Boolean'];
  recurringAmount: Scalars['Float'];
  recurringListPrice: Scalars['Float'];
  refund?: Maybe<Scalars['Float']>;
};

export type Grade = {
  hidden?: Maybe<Scalars['Boolean']>;
  label: Scalars['String'];
  value: Scalars['String'];
};

export type GradeInput = {
  hidden?: InputMaybe<Scalars['Boolean']>;
  label: Scalars['String'];
  value: Scalars['String'];
};

export type GuardedUser = ActiveRecordInterface & {
  attendanceCodeCode?: Maybe<Scalars['String']>;
  ccContacts?: Maybe<Array<Contact>>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  formattedCcContacts?: Maybe<Scalars['String']>;
  /** The unique identifier of the resource. */
  id: Scalars['ID'];
  lastName: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type HistoryVersion = ActiveRecordInterface & {
  attributes?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  type?: Maybe<Scalars['String']>;
  user?: Maybe<SharedUser>;
};

export type Homeroom = ActiveRecordInterface & {
  hrStripeBalance?: Maybe<Scalars['Float']>;
  /** The unique identifier of the resource. */
  id: Scalars['ID'];
  pendingPayoutBalance?: Maybe<Scalars['Float']>;
  stripeBalance?: Maybe<Scalars['Float']>;
  transactionTotals?: Maybe<HomeroomTransactionTotals>;
};

export type HomeroomTransactionTotalsArgs = {
  endDate: Scalars['ISO8601DateTime'];
  startDate: Scalars['ISO8601DateTime'];
};

export type HomeroomTransactionTotals = {
  costOfRevenue?: Maybe<Scalars['Float']>;
  grossProfit?: Maybe<Scalars['Float']>;
  netRevenue?: Maybe<Scalars['Float']>;
};

export type Image = {
  aspectRatio?: Maybe<Scalars['Float']>;
  assetId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  etag?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  folder?: Maybe<Scalars['String']>;
  format?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  pixels?: Maybe<Scalars['Int']>;
  publicId?: Maybe<Scalars['String']>;
  resourceType?: Maybe<Scalars['String']>;
  secureUrl?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']>>;
  type?: Maybe<Scalars['String']>;
  uploadedAt?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
};

export type Invite = {
  invitedTo?: Maybe<Scalars['String']>;
  kind?: Maybe<InviteKind>;
  token?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of InviteAccept */
export type InviteAcceptPayload = {
  success?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of InviteCreate */
export type InviteCreatePayload = {
  entity?: Maybe<SiteOrCompany>;
};

export type InviteInput = {
  description?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  role: MemberRole;
};

export enum InviteKind {
  Company = 'company',
  Course = 'course',
  Enrollment = 'enrollment',
  Family = 'family',
  Site = 'site'
}

/** Autogenerated return type of InviteResend */
export type InviteResendPayload = {
  entity?: Maybe<SiteOrCompany>;
};

/** Autogenerated return type of InviteRevoke */
export type InviteRevokePayload = {
  entity?: Maybe<SiteOrCompany>;
};

export type Location = {
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
};

export type Medication = ActiveRecordInterface & {
  dose: Scalars['String'];
  /** The unique identifier of the resource. */
  id: Scalars['ID'];
  medication: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
};

export type MedicationInput = {
  dose: Scalars['String'];
  medication: Scalars['String'];
  notes?: InputMaybe<Scalars['String']>;
};

export type Member = ActiveRecordInterface &
  Participant & {
    attendanceCodeCode?: Maybe<Scalars['String']>;
    email?: Maybe<Scalars['String']>;
    firstName?: Maybe<Scalars['String']>;
    /** The unique identifier of the resource. */
    id: Scalars['ID'];
    invitedAt?: Maybe<Scalars['ISO8601Date']>;
    isContact?: Maybe<Scalars['Boolean']>;
    isCurrentUser?: Maybe<Scalars['Boolean']>;
    kind: ParticipantKind;
    lastName?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
    normalizedRole?: Maybe<MemberRole>;
    phone?: Maybe<Scalars['String']>;
    relation?: Maybe<Scalars['String']>;
    role: Scalars['String'];
    user?: Maybe<GuardedUser>;
  };

export type MemberInvitation = ActiveRecordInterface &
  Participant & {
    attendanceCodeCode?: Maybe<Scalars['String']>;
    email?: Maybe<Scalars['String']>;
    firstName?: Maybe<Scalars['String']>;
    /** The unique identifier of the resource. */
    id: Scalars['ID'];
    kind: ParticipantKind;
    lastName?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
    normalizedRole?: Maybe<MemberRole>;
    phone?: Maybe<Scalars['String']>;
    relation?: Maybe<Scalars['String']>;
    role: Scalars['String'];
    sentAt: Scalars['ISO8601Date'];
    token: Scalars['String'];
  };

/** Autogenerated return type of MemberRemove */
export type MemberRemovePayload = {
  entity?: Maybe<SiteOrCompany>;
};

export enum MemberRole {
  Admin = 'ADMIN',
  Member = 'MEMBER'
}

export type Message = MessageCommon & {
  bcc?: Maybe<Array<MessageRecipient>>;
  body?: Maybe<MessageContent>;
  broadcast?: Maybe<Scalars['Boolean']>;
  cc?: Maybe<Array<MessageRecipient>>;
  createdAt: Scalars['ISO8601DateTime'];
  entityMember?: Maybe<Scalars['Boolean']>;
  from?: Maybe<MessageSender>;
  id: Scalars['ID'];
  inReplyTo?: Maybe<Message>;
  labels?: Maybe<Array<MessageEntity>>;
  participants?: Maybe<Array<MessageEntity>>;
  preferredService?: Maybe<MessageServiceKind>;
  read?: Maybe<Scalars['Boolean']>;
  subject?: Maybe<Scalars['String']>;
  systemMessage?: Maybe<Scalars['Boolean']>;
  threadId?: Maybe<Scalars['String']>;
  threadScope?: Maybe<Scalars['ID']>;
  to?: Maybe<Array<MessageRecipient>>;
  type?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type MessageCommon = {
  bcc?: Maybe<Array<MessageRecipient>>;
  broadcast?: Maybe<Scalars['Boolean']>;
  cc?: Maybe<Array<MessageRecipient>>;
  createdAt: Scalars['ISO8601DateTime'];
  entityMember?: Maybe<Scalars['Boolean']>;
  from?: Maybe<MessageSender>;
  id: Scalars['ID'];
  labels?: Maybe<Array<MessageEntity>>;
  participants?: Maybe<Array<MessageEntity>>;
  preferredService?: Maybe<MessageServiceKind>;
  read?: Maybe<Scalars['Boolean']>;
  subject?: Maybe<Scalars['String']>;
  systemMessage?: Maybe<Scalars['Boolean']>;
  to?: Maybe<Array<MessageRecipient>>;
  type?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type MessageContent = {
  html?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type MessageEntity = ActiveRecordInterface & {
  email?: Maybe<Scalars['String']>;
  entity?: Maybe<MessageEntity>;
  firstName?: Maybe<Scalars['String']>;
  firstNameWithNickname?: Maybe<Scalars['String']>;
  /** The unique identifier of the resource. */
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type MessageEntityInput = {
  entity?: InputMaybe<Array<MessageEntityInput>>;
  id?: InputMaybe<Scalars['ID']>;
  type?: InputMaybe<Scalars['String']>;
};

export type MessageInput = {
  bcc?: InputMaybe<Array<MessageEntityInput>>;
  body: Scalars['String'];
  cc?: InputMaybe<Array<MessageEntityInput>>;
  fromEntity?: InputMaybe<MessageEntityInput>;
  labels?: InputMaybe<Array<MessageEntityInput>>;
  preferredService: MessageServiceKind;
  subject?: InputMaybe<Scalars['String']>;
  to?: InputMaybe<Array<MessageEntityInput>>;
};

export type MessageRecipient = {
  emailStatus?: Maybe<MessageServiceDeliveryStatus>;
  role?: Maybe<Scalars['String']>;
  smsStatus?: Maybe<MessageServiceDeliveryStatus>;
  students?: Maybe<Array<MessageEntity>>;
  to?: Maybe<SharedUser>;
  toEntity?: Maybe<MessageEntity>;
  toKind?: Maybe<MessageEntity>;
};

export type MessageSender = {
  from: SharedUser;
  fromEntity?: Maybe<MessageEntity>;
  role?: Maybe<Scalars['String']>;
  students?: Maybe<Array<MessageEntity>>;
};

export enum MessageServiceDeliveryStatus {
  Failed = 'FAILED',
  Success = 'SUCCESS'
}

export enum MessageServiceKind {
  Email = 'EMAIL',
  Sms = 'SMS'
}

export type MessageThread = MessageCommon & {
  bcc?: Maybe<Array<MessageRecipient>>;
  broadcast?: Maybe<Scalars['Boolean']>;
  cc?: Maybe<Array<MessageRecipient>>;
  createdAt: Scalars['ISO8601DateTime'];
  entityMember?: Maybe<Scalars['Boolean']>;
  from?: Maybe<MessageSender>;
  id: Scalars['ID'];
  labels?: Maybe<Array<MessageEntity>>;
  messageCount?: Maybe<Scalars['Int']>;
  messages?: Maybe<Array<Message>>;
  participants?: Maybe<Array<MessageEntity>>;
  preferredService?: Maybe<MessageServiceKind>;
  preview?: Maybe<Scalars['String']>;
  read?: Maybe<Scalars['Boolean']>;
  subject?: Maybe<Scalars['String']>;
  systemMessage?: Maybe<Scalars['Boolean']>;
  to?: Maybe<Array<MessageRecipient>>;
  type?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

/** Autogenerated return type of MessageThreadArchive */
export type MessageThreadArchivePayload = {
  thread?: Maybe<MessageThread>;
};

/** Autogenerated return type of MessageThreadCreate */
export type MessageThreadCreatePayload = {
  message?: Maybe<Message>;
};

/** Autogenerated return type of MessageThreadMarkThread */
export type MessageThreadMarkThreadPayload = {
  success?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of MessageThreadReply */
export type MessageThreadReplyPayload = {
  message?: Maybe<Message>;
};

export type Mutation = ActiveRecordInterface & {
  displayName?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  ip?: Maybe<Scalars['String']>;
  lr?: Maybe<Scalars['String']>;
  mutationChanges?: Maybe<Array<MutationChange>>;
  mutationId: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['ISO8601DateTime']>;
  user?: Maybe<SharedUser>;
  variables?: Maybe<Scalars['JSON']>;
};

export type MutationChange = ActiveRecordInterface & {
  displayName?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  ip?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  lr?: Maybe<Scalars['String']>;
  mutationId: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  related?: Maybe<Array<MutationChange>>;
  start?: Maybe<Scalars['ISO8601DateTime']>;
  targetChanges?: Maybe<Scalars['JSON']>;
  targetId?: Maybe<Scalars['ID']>;
  targetType?: Maybe<Scalars['String']>;
  user?: Maybe<SharedUser>;
  variables?: Maybe<Scalars['JSON']>;
};

export type MutationChangesQueryResults = CollectionInfo & {
  count?: Maybe<Scalars['Int']>;
  filterOptions?: Maybe<Array<Maybe<Option>>>;
  /** The unique identifier of the resource. */
  id: Scalars['ID'];
  items?: Maybe<Array<MutationChange>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  pages?: Maybe<Scalars['Int']>;
  totals?: Maybe<Array<Scalars['Int']>>;
};

export type MutationObject = {
  cartAddEnrollments?: Maybe<CartAddEnrollmentsPayload>;
  cartAddInvite?: Maybe<CartAddInvitePayload>;
  cartApplyCourseDiscount?: Maybe<CartApplyCourseDiscountPayload>;
  cartApplyDiscount?: Maybe<CartApplyDiscountPayload>;
  cartCheckout?: Maybe<CartCheckOutPayload>;
  cartCreateAndAssignStudentToEnrollment?: Maybe<CartCreateAndAssignStudentToEnrollmentPayload>;
  cartRemoveDiscount?: Maybe<CartRemoveDiscountPayload>;
  cartRemoveEnrollment?: Maybe<CartRemoveEnrollmentPayload>;
  cartSetFamily?: Maybe<CartSetFamilyPayload>;
  cartSetPriceTier?: Maybe<CartSetPriceTierPayload>;
  cartSetTeamRegistration?: Maybe<CartSetTeamRegistrationPayload>;
  cartUpdateEnrollment?: Maybe<CartUpdateEnrollmentPayload>;
  cartUpdatePaymentService?: Maybe<CartUpdatePaymentServicePayload>;
  cartUpsertDonation?: Maybe<CartUpsertDonationPayload>;
  cfAnswersUpsert?: Maybe<CfAnswersUpsertPayload>;
  cfQuestionsUpsert?: Maybe<CfQuestionsUpsertPayload>;
  companiesMerge?: Maybe<CompaniesMergePayload>;
  companyAdminMemberAdd?: Maybe<SiteOrCompany>;
  companyAdminMemberRemove?: Maybe<SiteOrCompany>;
  companyClearStripeToken?: Maybe<SiteOrCompany>;
  companyCopyStripeToken?: Maybe<SiteOrCompany>;
  companyCreateAdjustment?: Maybe<SiteOrCompany>;
  companyInviteAccept?: Maybe<InviteAcceptPayload>;
  companyInviteCreate?: Maybe<InviteCreatePayload>;
  companyInviteResend?: Maybe<InviteResendPayload>;
  companyInviteRevoke?: Maybe<InviteRevokePayload>;
  companyMemberRemove?: Maybe<MemberRemovePayload>;
  companySetContact?: Maybe<SetContactPayload>;
  companyUpdate?: Maybe<CompanyUpdatePayload>;
  companyUpsertTeacher?: Maybe<CompanyUpsertTeacherPayload>;
  companyUpsertTeachers?: Maybe<CompanyUpsertTeachersPayload>;
  courseBillNow?: Maybe<CourseBillNowPayload>;
  courseFrameworkArchive?: Maybe<CourseFrameworkArchivePayload>;
  courseFrameworksUpsert?: Maybe<CourseFrameworksUpsertPayload>;
  courseInviteAccept?: Maybe<CourseInviteAcceptPayload>;
  coursesApprove?: Maybe<CoursesApproveMutationPayload>;
  coursesCancelOrDelete?: Maybe<CoursesCancelOrDeleteMutationPayload>;
  coursesCloseEnrollment?: Maybe<CoursesCloseEnrollmentMutationPayload>;
  coursesFinalize?: Maybe<CoursesFinalizeMutationPayload>;
  coursesOpenEnrollment?: Maybe<CoursesOpenEnrollmentMutationPayload>;
  coursesSendRequest?: Maybe<CoursesSendCourseRequestsMutationPayload>;
  coursesUpsert?: Maybe<CoursesUpsertMutationPayload>;
  directoryContact?: Maybe<DirectoryStartConversationPayload>;
  discountDefinitionsUpsert?: Maybe<DiscountDefinitionsUpsertPayload>;
  enrollmentAdd?: Maybe<EnrollmentAddPayload>;
  enrollmentsChangeSeasonPlan?: Maybe<EnrollmentsChangeSeasonPlanPayload>;
  enrollmentsChangeSubscription?: Maybe<EnrollmentsChangeSubscriptionPayload>;
  enrollmentsCustomCharge?: Maybe<EnrollmentsCustomChargePayload>;
  enrollmentsMove?: Maybe<EnrollmentsMovePayload>;
  enrollmentsPromote?: Maybe<EnrollmentsPromotePayload>;
  enrollmentsReenroll?: Maybe<EnrollmentsReenrollPayload>;
  enrollmentsRefund?: Maybe<EnrollmentsRefundPayload>;
  enrollmentsRemove?: Maybe<EnrollmentsRemovePayload>;
  enrollmentsResendInvites?: Maybe<EnrollmentsResendInvitePayload>;
  enrollmentsSetAdditionalDiscount?: Maybe<EnrollmentsSetAdditionalDiscountPayload>;
  enrollmentsSetAttendance?: Maybe<EnrollmentsSetAttendancePayload>;
  enrollmentsSetGroups?: Maybe<EnrollmentsSetGroupsPayload>;
  enrollmentsUpdate?: Maybe<EnrollmentsUpdatePayload>;
  familiesMerge?: Maybe<FamiliesMergePayload>;
  familyAdminMemberAdd?: Maybe<SiteOrCompany>;
  familyAdminMemberRemove?: Maybe<SiteOrCompany>;
  familyInviteAccept?: Maybe<InviteAcceptPayload>;
  familyInviteCreate?: Maybe<InviteCreatePayload>;
  familyInviteResend?: Maybe<InviteResendPayload>;
  familyInviteRevoke?: Maybe<InviteRevokePayload>;
  familyMemberRemove?: Maybe<MemberRemovePayload>;
  familySetContact?: Maybe<SetContactPayload>;
  homeroomProcessPendingRefund?: Maybe<Scalars['Boolean']>;
  homeroomWithdrawFundsFromStripe?: Maybe<Scalars['Boolean']>;
  messageThreadArchive?: Maybe<MessageThreadArchivePayload>;
  messageThreadCreate?: Maybe<MessageThreadCreatePayload>;
  messageThreadMarkRead?: Maybe<MessageThreadMarkThreadPayload>;
  messageThreadReply?: Maybe<MessageThreadReplyPayload>;
  parentAddCreditCard?: Maybe<ParentAddCreditCardPayload>;
  parentRemoveCreditCard?: Maybe<ParentRemoveCreditCardPayload>;
  parentRemoveEnrollmentsFromWaitlist?: Maybe<ParentRemoveEnrollmentsFromWaitlistPayload>;
  parentRemoveUsBankAccount?: Maybe<ParentRemoveUsBankAccountPayload>;
  parentSetAttendance?: Maybe<ParentSetAttendancePayload>;
  parentUpsertCcContacts?: Maybe<ParentUpsertCcContactsPayload>;
  parentWithdrawEnrollment?: Maybe<ParentWithdrawEnrollmentPayload>;
  parentsMerge?: Maybe<ParentsMergePayload>;
  payoutProcess?: Maybe<Payout>;
  providerArchiveCourseFramework?: Maybe<CourseFrameworkArchivePayload>;
  reviewUpsert?: Maybe<ReviewUpsertPayload>;
  rosterItemsSetAdditionalDiscount?: Maybe<RosterItemsSetAdditionalDiscountPayload>;
  seasonImport?: Maybe<SeasonImportPayload>;
  seasonUpsert?: Maybe<SeasonUpsertPayload>;
  seasonsDelete?: Maybe<SeasonsDeletePayload>;
  siteActivate?: Maybe<Site>;
  siteAdminMemberAdd?: Maybe<SiteOrCompany>;
  siteAdminMemberRemove?: Maybe<SiteOrCompany>;
  siteClaim?: Maybe<User>;
  siteClearStripeToken?: Maybe<SiteOrCompany>;
  siteCopyStripeToken?: Maybe<SiteOrCompany>;
  siteCreate?: Maybe<SiteCreatePayload>;
  siteCreateAdjustment?: Maybe<SiteOrCompany>;
  siteCreateDemo?: Maybe<SiteCreateDemoPayload>;
  siteDeactivate?: Maybe<Site>;
  siteDelete?: Maybe<SiteDeletePayload>;
  siteInviteAccept?: Maybe<InviteAcceptPayload>;
  siteInviteCreate?: Maybe<InviteCreatePayload>;
  siteInviteResend?: Maybe<InviteResendPayload>;
  siteInviteRevoke?: Maybe<InviteRevokePayload>;
  siteMemberRemove?: Maybe<MemberRemovePayload>;
  siteSetContact?: Maybe<SetContactPayload>;
  siteUpdate?: Maybe<SiteUpdatePayload>;
  siteUpdateAdminFee?: Maybe<SiteUpdateAdminFeePayload>;
  siteUpdateDismissals?: Maybe<SiteUpdateDismissalsPayload>;
  siteUpdateDonationConfig?: Maybe<SiteUpdateDonationConfigPayload>;
  siteUpdateGeneralInfo?: Maybe<SiteUpdateGeneralInfoPayload>;
  siteUpdateGrades?: Maybe<SiteUpdateGradesPayload>;
  siteUpdateParentAttendance?: Maybe<SiteUpdateParentAttendancePayload>;
  siteUpdatePickups?: Maybe<SiteUpdatePickupsPayload>;
  siteUpdatePolicies?: Maybe<SiteUpdatePoliciesPayload>;
  siteUpdateWaivers?: Maybe<SiteUpdateWaiversPayload>;
  siteUpsertClassrooms?: Maybe<SiteUpsertClassroomsPayload>;
  siteUpsertComplianceDefinitions?: Maybe<SiteUpsertComplianceDefinitionsPayload>;
  siteUpsertContacts?: Maybe<SiteUpsertContactsPayload>;
  siteUpsertPriceTiers?: Maybe<SiteUpsertPriceTiersPayload>;
  studentConfirmYearlyUpdate?: Maybe<StudentConfirmYearlyUpdatePayload>;
  studentCopyContacts?: Maybe<StudentCopyContactsPayload>;
  studentRemoveLogEntry?: Maybe<StudentRemoveLogEntryPayload>;
  studentUpdate?: Maybe<StudentUpdatePayload>;
  studentUpsert?: Maybe<StudentUpsertPayload>;
  studentUpsertLogEntry?: Maybe<StudentUpsertLogEntryPayload>;
  studentsMerge?: Maybe<StudentsMergePayload>;
  tableViewRemove?: Maybe<TableViewRemovePayload>;
  tableViewUpsert?: Maybe<TableViewUpsertPayload>;
  teamInviteResend?: Maybe<TeamInviteResendPayload>;
  teamParticipantsUpsert?: Maybe<ParticipantsUpsertPayload>;
  teamSetAsContact?: Maybe<TeamSetAsContactPayload>;
  timeEntriesUpdate?: Maybe<TimeEntriesUpdatePayload>;
  timeEntryCheckIn?: Maybe<TimeEntryCheckInPayload>;
  timeEntryCheckOut?: Maybe<TimeEntryCheckOutPayload>;
  userAcceptTos?: Maybe<UserAcceptTermsOfServicePayload>;
  userActivate?: Maybe<User>;
  userAddRole?: Maybe<UserAddRolePayload>;
  userDeactivate?: Maybe<User>;
  userDelete?: Maybe<User>;
  userUpdate?: Maybe<UserUpdatePayload>;
  vendorCreate?: Maybe<VendorCreatePayload>;
  vendorMakeSiteCompany?: Maybe<VendorMakeSiteCompanyPayload>;
  vendorUpdate?: Maybe<VendorUpdatePayload>;
  vendorUpdateCompliance?: Maybe<VendorUpdateCompliancePayload>;
  vendorsDelete?: Maybe<VendorsDeletePayload>;
};

export type MutationObjectCartAddEnrollmentsArgs = {
  afterClassDismissal?: InputMaybe<Scalars['String']>;
  beforeClassPickup?: InputMaybe<Scalars['String']>;
  configurableSeason?: InputMaybe<SeasonPriceConfigInput>;
  custom?: InputMaybe<CustomPriceConfigInput>;
  discount?: InputMaybe<DiscountInput>;
  dismissal?: InputMaybe<Scalars['String']>;
  dropIn?: InputMaybe<DropInPriceConfigInput>;
  dropInPriceConfig?: InputMaybe<DropInPriceConfigInput>;
  enrollments?: InputMaybe<Array<EnrollmentInput>>;
  kind?: InputMaybe<PriceConfigKind>;
  options?: InputMaybe<Array<EnrollmentOptionInput>>;
  pickup?: InputMaybe<Scalars['String']>;
  priceKind?: InputMaybe<PriceConfigKind>;
  recurring?: InputMaybe<RecurringPriceConfigInput>;
  recurringPriceConfig?: InputMaybe<RecurringPriceConfigInput>;
  startDate?: InputMaybe<Scalars['ISO8601Date']>;
  studentId?: InputMaybe<Scalars['ID']>;
  trackingParameters?: InputMaybe<Scalars['String']>;
};

export type MutationObjectCartAddInviteArgs = {
  token: Scalars['String'];
};

export type MutationObjectCartApplyCourseDiscountArgs = {
  code: Scalars['ID'];
};

export type MutationObjectCartApplyDiscountArgs = {
  code: Scalars['ID'];
};

export type MutationObjectCartCheckoutArgs = {
  cart: CheckOutCartInput;
  paymentMethod?: InputMaybe<Scalars['String']>;
  paymentService?: InputMaybe<PaymentService>;
};

export type MutationObjectCartCreateAndAssignStudentToEnrollmentArgs = {
  attributes: StudentInput;
  enrollmentId: Scalars['ID'];
};

export type MutationObjectCartRemoveDiscountArgs = {
  id: Scalars['ID'];
};

export type MutationObjectCartRemoveEnrollmentArgs = {
  id: Scalars['ID'];
};

export type MutationObjectCartSetFamilyArgs = {
  family: Scalars['ID'];
};

export type MutationObjectCartSetPriceTierArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type MutationObjectCartSetTeamRegistrationArgs = {
  afterClassDismissal?: InputMaybe<Scalars['String']>;
  athleticsConfig: AthleticsConfigInput;
  beforeClassPickup?: InputMaybe<Scalars['String']>;
  dismissal?: InputMaybe<Scalars['String']>;
  enrollmentId: Scalars['ID'];
  pickup?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['ISO8601Date']>;
  studentId?: InputMaybe<Scalars['ID']>;
};

export type MutationObjectCartUpdateEnrollmentArgs = {
  afterClassDismissal?: InputMaybe<Scalars['String']>;
  beforeClassPickup?: InputMaybe<Scalars['String']>;
  dismissal?: InputMaybe<Scalars['String']>;
  enrollmentId: Scalars['ID'];
  pickup?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['ISO8601Date']>;
  studentId?: InputMaybe<Scalars['ID']>;
};

export type MutationObjectCartUpdatePaymentServiceArgs = {
  paymentService?: InputMaybe<PaymentService>;
};

export type MutationObjectCartUpsertDonationArgs = {
  donation: CartDonationInput;
};

export type MutationObjectCfAnswersUpsertArgs = {
  answers: Array<CfAnswerInput>;
  studentId: Scalars['ID'];
};

export type MutationObjectCfQuestionsUpsertArgs = {
  ownerId: Scalars['ID'];
  ownerType: CfOwnerKind;
  questions: Array<CfQuestionInput>;
};

export type MutationObjectCompaniesMergeArgs = {
  destination: Scalars['ID'];
  source: Scalars['ID'];
};

export type MutationObjectCompanyAdminMemberAddArgs = {
  company: Scalars['ID'];
  role?: InputMaybe<MemberRole>;
  user: Scalars['ID'];
};

export type MutationObjectCompanyAdminMemberRemoveArgs = {
  company: Scalars['ID'];
  user: Scalars['ID'];
};

export type MutationObjectCompanyClearStripeTokenArgs = {
  company: Scalars['ID'];
};

export type MutationObjectCompanyCopyStripeTokenArgs = {
  company: Scalars['ID'];
  destination: Scalars['ID'];
  destinationType: Scalars['String'];
};

export type MutationObjectCompanyCreateAdjustmentArgs = {
  amount: Scalars['Float'];
  company: Scalars['ID'];
  description: Scalars['String'];
};

export type MutationObjectCompanyInviteAcceptArgs = {
  token: Scalars['String'];
};

export type MutationObjectCompanyInviteCreateArgs = {
  attributes: InviteInput;
  company?: InputMaybe<Scalars['ID']>;
  site?: InputMaybe<Scalars['ID']>;
};

export type MutationObjectCompanyInviteResendArgs = {
  inviteToken: Scalars['String'];
};

export type MutationObjectCompanyInviteRevokeArgs = {
  inviteToken: Scalars['String'];
};

export type MutationObjectCompanyMemberRemoveArgs = {
  company?: InputMaybe<Scalars['ID']>;
  member: Scalars['ID'];
  site?: InputMaybe<Scalars['ID']>;
};

export type MutationObjectCompanySetContactArgs = {
  company?: InputMaybe<Scalars['ID']>;
  member: Scalars['ID'];
  site?: InputMaybe<Scalars['ID']>;
};

export type MutationObjectCompanyUpdateArgs = {
  about?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<Scalars['String']>;
  company: Scalars['ID'];
  courseCancellationPolicy?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  headline?: InputMaybe<Scalars['String']>;
  hideQuickStartGuide?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  nonPartnerApproved?: InputMaybe<Scalars['Boolean']>;
  phone?: InputMaybe<Scalars['String']>;
  profileBackground?: InputMaybe<Scalars['String']>;
  profilePhoto?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<Scalars['Int']>;
  teachingRadius?: InputMaybe<Scalars['Int']>;
  website?: InputMaybe<Scalars['String']>;
  zipcode?: InputMaybe<Scalars['String']>;
};

export type MutationObjectCompanyUpsertTeacherArgs = {
  company?: InputMaybe<Scalars['ID']>;
  teacher: SingleTeacherInput;
  vendor?: InputMaybe<Scalars['ID']>;
};

export type MutationObjectCompanyUpsertTeachersArgs = {
  company: Scalars['ID'];
  teachers: Array<TeacherInput>;
};

export type MutationObjectCourseBillNowArgs = {
  course: Scalars['ID'];
};

export type MutationObjectCourseFrameworkArchiveArgs = {
  items: Array<Scalars['String']>;
};

export type MutationObjectCourseFrameworksUpsertArgs = {
  items: Array<CourseFrameworkInput>;
};

export type MutationObjectCourseInviteAcceptArgs = {
  company?: InputMaybe<Scalars['ID']>;
  token: Scalars['String'];
};

export type MutationObjectCoursesApproveArgs = {
  companyId?: InputMaybe<Scalars['ID']>;
  courseId?: InputMaybe<Scalars['ID']>;
  ids: Array<Scalars['ID']>;
  seasonId?: InputMaybe<Scalars['ID']>;
};

export type MutationObjectCoursesCancelOrDeleteArgs = {
  childCourses?: InputMaybe<Scalars['Boolean']>;
  companyId?: InputMaybe<Scalars['ID']>;
  courseId?: InputMaybe<Scalars['ID']>;
  ids: Array<Scalars['ID']>;
  includeOptions?: InputMaybe<Scalars['Boolean']>;
  seasonId?: InputMaybe<Scalars['ID']>;
};

export type MutationObjectCoursesCloseEnrollmentArgs = {
  companyId?: InputMaybe<Scalars['ID']>;
  courseId?: InputMaybe<Scalars['ID']>;
  ids: Array<Scalars['ID']>;
  seasonId?: InputMaybe<Scalars['ID']>;
};

export type MutationObjectCoursesFinalizeArgs = {
  companyId?: InputMaybe<Scalars['ID']>;
  courseId?: InputMaybe<Scalars['ID']>;
  ids: Array<Scalars['ID']>;
  seasonId?: InputMaybe<Scalars['ID']>;
};

export type MutationObjectCoursesOpenEnrollmentArgs = {
  companyId?: InputMaybe<Scalars['ID']>;
  courseId?: InputMaybe<Scalars['ID']>;
  ids: Array<Scalars['ID']>;
  seasonId?: InputMaybe<Scalars['ID']>;
};

export type MutationObjectCoursesSendRequestArgs = {
  companyId?: InputMaybe<Scalars['ID']>;
  courseId?: InputMaybe<Scalars['ID']>;
  ids: Array<Scalars['ID']>;
  seasonId?: InputMaybe<Scalars['ID']>;
};

export type MutationObjectCoursesUpsertArgs = {
  attributes: Array<CourseInput>;
  companyId?: InputMaybe<Scalars['ID']>;
  courseId?: InputMaybe<Scalars['ID']>;
  seasonId?: InputMaybe<Scalars['ID']>;
  transition?: InputMaybe<Scalars['Boolean']>;
};

export type MutationObjectDirectoryContactArgs = {
  content?: InputMaybe<Scalars['String']>;
  from?: InputMaybe<Scalars['String']>;
  toId: Scalars['ID'];
  toKind: EntityKind;
};

export type MutationObjectDiscountDefinitionsUpsertArgs = {
  discounts: Array<DiscountDefinitionInput>;
  ownerId: Scalars['ID'];
  ownerType?: InputMaybe<Scalars['String']>;
};

export type MutationObjectEnrollmentAddArgs = {
  afterClassDismissal?: InputMaybe<Scalars['String']>;
  beforeClassPickup?: InputMaybe<Scalars['String']>;
  configurableSeason?: InputMaybe<SeasonPriceConfigInput>;
  custom?: InputMaybe<CustomPriceConfigInput>;
  discount?: InputMaybe<DiscountInput>;
  dismissal?: InputMaybe<Scalars['String']>;
  dropIn?: InputMaybe<DropInPriceConfigInput>;
  dropInPriceConfig?: InputMaybe<DropInPriceConfigInput>;
  id: Scalars['ID'];
  kind?: InputMaybe<PriceConfigKind>;
  options?: InputMaybe<Array<EnrollmentOptionInput>>;
  parentEmail?: InputMaybe<Scalars['String']>;
  pickup?: InputMaybe<Scalars['String']>;
  priceKind?: InputMaybe<PriceConfigKind>;
  recurring?: InputMaybe<RecurringPriceConfigInput>;
  recurringPriceConfig?: InputMaybe<RecurringPriceConfigInput>;
  startDate?: InputMaybe<Scalars['ISO8601Date']>;
  studentId?: InputMaybe<Scalars['ID']>;
  studentName?: InputMaybe<Scalars['String']>;
};

export type MutationObjectEnrollmentsChangeSeasonPlanArgs = {
  afterClassDismissal?: InputMaybe<Scalars['String']>;
  beforeClassPickup?: InputMaybe<Scalars['String']>;
  configurableSeason?: InputMaybe<SeasonPriceConfigInput>;
  courseId: Scalars['ID'];
  custom?: InputMaybe<CustomPriceConfigInput>;
  description?: InputMaybe<Scalars['String']>;
  discount?: InputMaybe<DiscountInput>;
  dismissal?: InputMaybe<Scalars['String']>;
  dropIn?: InputMaybe<DropInPriceConfigInput>;
  dropInPriceConfig?: InputMaybe<DropInPriceConfigInput>;
  ids: Array<Scalars['ID']>;
  kind?: InputMaybe<PriceConfigKind>;
  options?: InputMaybe<Array<EnrollmentOptionInput>>;
  pickup?: InputMaybe<Scalars['String']>;
  priceKind?: InputMaybe<PriceConfigKind>;
  recurring?: InputMaybe<RecurringPriceConfigInput>;
  recurringPriceConfig?: InputMaybe<RecurringPriceConfigInput>;
  startDate?: InputMaybe<Scalars['ISO8601Date']>;
  studentId?: InputMaybe<Scalars['ID']>;
};

export type MutationObjectEnrollmentsChangeSubscriptionArgs = {
  afterClassDismissal?: InputMaybe<Scalars['String']>;
  beforeClassPickup?: InputMaybe<Scalars['String']>;
  configurableSeason?: InputMaybe<SeasonPriceConfigInput>;
  courseId: Scalars['ID'];
  custom?: InputMaybe<CustomPriceConfigInput>;
  description?: InputMaybe<Scalars['String']>;
  discount?: InputMaybe<DiscountInput>;
  dismissal?: InputMaybe<Scalars['String']>;
  dropIn?: InputMaybe<DropInPriceConfigInput>;
  dropInPriceConfig?: InputMaybe<DropInPriceConfigInput>;
  effective?: InputMaybe<ChangePricingPeriod>;
  ids: Array<Scalars['ID']>;
  kind?: InputMaybe<PriceConfigKind>;
  options?: InputMaybe<Array<EnrollmentOptionInput>>;
  pickup?: InputMaybe<Scalars['String']>;
  priceKind?: InputMaybe<PriceConfigKind>;
  recurring?: InputMaybe<RecurringPriceConfigInput>;
  recurringPriceConfig?: InputMaybe<RecurringPriceConfigInput>;
  startDate?: InputMaybe<Scalars['ISO8601Date']>;
  studentId?: InputMaybe<Scalars['ID']>;
};

export type MutationObjectEnrollmentsCustomChargeArgs = {
  afterClassDismissal?: InputMaybe<Scalars['String']>;
  beforeClassPickup?: InputMaybe<Scalars['String']>;
  configurableSeason?: InputMaybe<SeasonPriceConfigInput>;
  custom?: InputMaybe<CustomPriceConfigInput>;
  discount?: InputMaybe<DiscountInput>;
  dismissal?: InputMaybe<Scalars['String']>;
  dropIn?: InputMaybe<DropInPriceConfigInput>;
  dropInPriceConfig?: InputMaybe<DropInPriceConfigInput>;
  enrollments?: InputMaybe<Array<EnrollmentInput>>;
  kind?: InputMaybe<PriceConfigKind>;
  options?: InputMaybe<Array<EnrollmentOptionInput>>;
  pickup?: InputMaybe<Scalars['String']>;
  priceKind?: InputMaybe<PriceConfigKind>;
  recurring?: InputMaybe<RecurringPriceConfigInput>;
  recurringPriceConfig?: InputMaybe<RecurringPriceConfigInput>;
  startDate?: InputMaybe<Scalars['ISO8601Date']>;
  studentId?: InputMaybe<Scalars['ID']>;
};

export type MutationObjectEnrollmentsMoveArgs = {
  destinationCourseId: Scalars['ID'];
  ids: Array<Scalars['ID']>;
  sourceCourseId?: InputMaybe<Scalars['ID']>;
};

export type MutationObjectEnrollmentsPromoteArgs = {
  courseId: Scalars['ID'];
  discount?: InputMaybe<DiscountInput>;
  ids: Array<Scalars['ID']>;
  startDate?: InputMaybe<Scalars['ISO8601Date']>;
};

export type MutationObjectEnrollmentsReenrollArgs = {
  courseId: Scalars['ID'];
  ids: Array<Scalars['ID']>;
};

export type MutationObjectEnrollmentsRefundArgs = {
  amount?: InputMaybe<Scalars['Float']>;
  childEnrollments?: InputMaybe<Scalars['Boolean']>;
  courseId: Scalars['ID'];
  description: Scalars['String'];
  ids: Array<Scalars['ID']>;
  includeOptions?: InputMaybe<Scalars['Boolean']>;
  kind: RefundKind;
  remove: Scalars['Boolean'];
};

export type MutationObjectEnrollmentsRemoveArgs = {
  childEnrollments?: InputMaybe<Scalars['Boolean']>;
  courseId: Scalars['ID'];
  ids: Array<Scalars['ID']>;
  includeOptions?: InputMaybe<Scalars['Boolean']>;
};

export type MutationObjectEnrollmentsResendInvitesArgs = {
  courseId: Scalars['ID'];
  ids: Array<Scalars['ID']>;
};

export type MutationObjectEnrollmentsSetAdditionalDiscountArgs = {
  discounts: Array<EnrollmentsSetAdditionalDiscountInput>;
};

export type MutationObjectEnrollmentsSetAttendanceArgs = {
  attendances: Array<AttendanceInput>;
};

export type MutationObjectEnrollmentsSetGroupsArgs = {
  groups: Array<Scalars['String']>;
  ids: Array<Scalars['ID']>;
};

export type MutationObjectEnrollmentsUpdateArgs = {
  enrollments: Array<ConfirmedEnrollmentInput>;
};

export type MutationObjectFamiliesMergeArgs = {
  destination: Scalars['ID'];
  source: Scalars['ID'];
};

export type MutationObjectFamilyAdminMemberAddArgs = {
  family: Scalars['ID'];
  role?: InputMaybe<MemberRole>;
  user: Scalars['ID'];
};

export type MutationObjectFamilyAdminMemberRemoveArgs = {
  family: Scalars['ID'];
  user: Scalars['ID'];
};

export type MutationObjectFamilyInviteAcceptArgs = {
  token: Scalars['String'];
};

export type MutationObjectFamilyInviteCreateArgs = {
  attributes: InviteInput;
  company?: InputMaybe<Scalars['ID']>;
  site?: InputMaybe<Scalars['ID']>;
};

export type MutationObjectFamilyInviteResendArgs = {
  inviteToken: Scalars['String'];
};

export type MutationObjectFamilyInviteRevokeArgs = {
  inviteToken: Scalars['String'];
};

export type MutationObjectFamilyMemberRemoveArgs = {
  company?: InputMaybe<Scalars['ID']>;
  member: Scalars['ID'];
  site?: InputMaybe<Scalars['ID']>;
};

export type MutationObjectFamilySetContactArgs = {
  company?: InputMaybe<Scalars['ID']>;
  member: Scalars['ID'];
  site?: InputMaybe<Scalars['ID']>;
};

export type MutationObjectHomeroomProcessPendingRefundArgs = {
  refund: Scalars['ID'];
};

export type MutationObjectHomeroomWithdrawFundsFromStripeArgs = {
  amount: Scalars['Float'];
};

export type MutationObjectMessageThreadArchiveArgs = {
  thread: Scalars['ID'];
};

export type MutationObjectMessageThreadCreateArgs = {
  attributes: MessageInput;
};

export type MutationObjectMessageThreadMarkReadArgs = {
  lastMessage: Scalars['ID'];
  threadScope?: InputMaybe<Scalars['String']>;
};

export type MutationObjectMessageThreadReplyArgs = {
  body: Scalars['String'];
  fromEntity?: InputMaybe<MessageEntityInput>;
  replyTo: Scalars['ID'];
  subject?: InputMaybe<Scalars['String']>;
};

export type MutationObjectParentAddCreditCardArgs = {
  family?: InputMaybe<Scalars['ID']>;
  token: Scalars['ID'];
  updateCartPaymentService?: InputMaybe<Scalars['Boolean']>;
};

export type MutationObjectParentRemoveCreditCardArgs = {
  token: Scalars['ID'];
};

export type MutationObjectParentRemoveEnrollmentsFromWaitlistArgs = {
  ids: Array<Scalars['ID']>;
};

export type MutationObjectParentRemoveUsBankAccountArgs = {
  fingerprint: Scalars['ID'];
};

export type MutationObjectParentSetAttendanceArgs = {
  attendances: Array<ParentAttendanceInput>;
  code: Scalars['String'];
};

export type MutationObjectParentUpsertCcContactsArgs = {
  contacts: Array<CcContactInput>;
};

export type MutationObjectParentWithdrawEnrollmentArgs = {
  enrollmentId: Scalars['ID'];
};

export type MutationObjectParentsMergeArgs = {
  destination: Scalars['ID'];
  source: Scalars['ID'];
};

export type MutationObjectPayoutProcessArgs = {
  payout: Scalars['ID'];
};

export type MutationObjectProviderArchiveCourseFrameworkArgs = {
  items: Array<Scalars['String']>;
};

export type MutationObjectReviewUpsertArgs = {
  attributes: ReviewInput;
  review: Scalars['String'];
};

export type MutationObjectRosterItemsSetAdditionalDiscountArgs = {
  discounts: Array<SetAdditionalDiscountInput>;
};

export type MutationObjectSeasonImportArgs = {
  importFrom: Scalars['ID'];
  season: Scalars['ID'];
};

export type MutationObjectSeasonUpsertArgs = {
  attributes: SeasonInput;
};

export type MutationObjectSeasonsDeleteArgs = {
  ids: Array<Scalars['ID']>;
  siteId: Scalars['ID'];
};

export type MutationObjectSiteActivateArgs = {
  site: Scalars['ID'];
};

export type MutationObjectSiteAdminMemberAddArgs = {
  role?: InputMaybe<MemberRole>;
  site: Scalars['ID'];
  user: Scalars['ID'];
};

export type MutationObjectSiteAdminMemberRemoveArgs = {
  site: Scalars['ID'];
  user: Scalars['ID'];
};

export type MutationObjectSiteClaimArgs = {
  site: Scalars['ID'];
};

export type MutationObjectSiteClearStripeTokenArgs = {
  site: Scalars['ID'];
};

export type MutationObjectSiteCopyStripeTokenArgs = {
  destination: Scalars['ID'];
  destinationType: Scalars['String'];
  site: Scalars['ID'];
};

export type MutationObjectSiteCreateArgs = {
  attributes?: InputMaybe<SiteInput>;
};

export type MutationObjectSiteCreateAdjustmentArgs = {
  amount: Scalars['Float'];
  description: Scalars['String'];
  site: Scalars['ID'];
};

export type MutationObjectSiteCreateDemoArgs = {
  name: Scalars['String'];
  zipcode: Scalars['String'];
};

export type MutationObjectSiteDeactivateArgs = {
  site: Scalars['ID'];
};

export type MutationObjectSiteDeleteArgs = {
  site?: InputMaybe<Scalars['ID']>;
};

export type MutationObjectSiteInviteAcceptArgs = {
  token: Scalars['String'];
};

export type MutationObjectSiteInviteCreateArgs = {
  attributes: InviteInput;
  company?: InputMaybe<Scalars['ID']>;
  site?: InputMaybe<Scalars['ID']>;
};

export type MutationObjectSiteInviteResendArgs = {
  inviteToken: Scalars['String'];
};

export type MutationObjectSiteInviteRevokeArgs = {
  inviteToken: Scalars['String'];
};

export type MutationObjectSiteMemberRemoveArgs = {
  company?: InputMaybe<Scalars['ID']>;
  member: Scalars['ID'];
  site?: InputMaybe<Scalars['ID']>;
};

export type MutationObjectSiteSetContactArgs = {
  company?: InputMaybe<Scalars['ID']>;
  member: Scalars['ID'];
  site?: InputMaybe<Scalars['ID']>;
};

export type MutationObjectSiteUpdateArgs = {
  attributes: SiteInput;
  id: Scalars['ID'];
};

export type MutationObjectSiteUpdateAdminFeeArgs = {
  feeConfig: SiteFeeConfigInput;
  siteId: Scalars['ID'];
};

export type MutationObjectSiteUpdateDismissalsArgs = {
  checkoutDestinations?: InputMaybe<Array<Scalars['String']>>;
  dismissals?: InputMaybe<Array<Scalars['String']>>;
  site?: InputMaybe<Scalars['ID']>;
  siteId?: InputMaybe<Scalars['ID']>;
};

export type MutationObjectSiteUpdateDonationConfigArgs = {
  donationConfig: DonationConfigInput;
  siteId: Scalars['ID'];
};

export type MutationObjectSiteUpdateGeneralInfoArgs = {
  address?: InputMaybe<SiteAddressInput>;
  branding?: InputMaybe<SiteBrandingInput>;
  contactInfo?: InputMaybe<SiteAddressInput>;
  payoutConfig?: InputMaybe<SitePayoutConfigInput>;
  proration?: InputMaybe<ProrationKind>;
  searchable?: InputMaybe<Scalars['Boolean']>;
  site?: InputMaybe<Scalars['ID']>;
  siteId?: InputMaybe<Scalars['ID']>;
  usingAch?: InputMaybe<Scalars['Boolean']>;
  usingTrackingParameters?: InputMaybe<Scalars['Boolean']>;
};

export type MutationObjectSiteUpdateGradesArgs = {
  grades: Array<InputMaybe<GradeInput>>;
  site: Scalars['ID'];
};

export type MutationObjectSiteUpdateParentAttendanceArgs = {
  attendanceConfig: SiteParentAttendanceConfigInput;
  site?: InputMaybe<Scalars['ID']>;
};

export type MutationObjectSiteUpdatePickupsArgs = {
  pickupDestinations?: InputMaybe<Array<Scalars['String']>>;
  pickups?: InputMaybe<Array<Scalars['String']>>;
  site?: InputMaybe<Scalars['ID']>;
  siteId?: InputMaybe<Scalars['ID']>;
};

export type MutationObjectSiteUpdatePoliciesArgs = {
  attributes?: InputMaybe<PolicyInput>;
  policies?: InputMaybe<PolicyInput>;
};

export type MutationObjectSiteUpdateWaiversArgs = {
  attributes: Array<InputMaybe<FileUploadAttributes>>;
  siteId: Scalars['ID'];
};

export type MutationObjectSiteUpsertClassroomsArgs = {
  classrooms: Array<InputMaybe<ClassroomInput>>;
  site?: InputMaybe<Scalars['ID']>;
  siteId?: InputMaybe<Scalars['ID']>;
};

export type MutationObjectSiteUpsertComplianceDefinitionsArgs = {
  complianceDefinitions: ComplianceDefinitionsInput;
  site?: InputMaybe<Scalars['ID']>;
  siteId?: InputMaybe<Scalars['ID']>;
};

export type MutationObjectSiteUpsertContactsArgs = {
  contacts: Array<ContactInput>;
  site?: InputMaybe<Scalars['ID']>;
  siteId?: InputMaybe<Scalars['ID']>;
};

export type MutationObjectSiteUpsertPriceTiersArgs = {
  priceTiers: Array<PriceTierInput>;
  site: Scalars['ID'];
};

export type MutationObjectStudentConfirmYearlyUpdateArgs = {
  students: Array<Scalars['ID']>;
};

export type MutationObjectStudentCopyContactsArgs = {
  id: Scalars['ID'];
};

export type MutationObjectStudentRemoveLogEntryArgs = {
  logEntry: Scalars['ID'];
};

export type MutationObjectStudentUpdateArgs = {
  attributes?: InputMaybe<StudentInput>;
  classroom?: InputMaybe<Scalars['ID']>;
  dob?: InputMaybe<Scalars['ISO8601Date']>;
  grade?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  nickname?: InputMaybe<Scalars['String']>;
};

export type MutationObjectStudentUpsertArgs = {
  attributes: StudentInput;
};

export type MutationObjectStudentUpsertLogEntryArgs = {
  attributes: StudentLogEntryInput;
  student: Scalars['ID'];
};

export type MutationObjectStudentsMergeArgs = {
  destination: Scalars['ID'];
  source: Scalars['ID'];
};

export type MutationObjectTableViewRemoveArgs = {
  entityId: Scalars['ID'];
  entityKind: EntityKind;
  view: Scalars['ID'];
};

export type MutationObjectTableViewUpsertArgs = {
  entityId: Scalars['ID'];
  entityKind: EntityKind;
  view: TableViewInput;
};

export type MutationObjectTeamInviteResendArgs = {
  entityId?: InputMaybe<Scalars['ID']>;
  entityKind?: InputMaybe<EntityKind>;
  member: Scalars['ID'];
};

export type MutationObjectTeamParticipantsUpsertArgs = {
  entityId: Scalars['ID'];
  entityKind: EntityKind;
  participants: Array<ParticipantInput>;
};

export type MutationObjectTeamSetAsContactArgs = {
  company?: InputMaybe<Scalars['ID']>;
  entityId?: InputMaybe<Scalars['ID']>;
  entityKind?: InputMaybe<EntityKind>;
  member: Scalars['ID'];
  site?: InputMaybe<Scalars['ID']>;
};

export type MutationObjectTimeEntriesUpdateArgs = {
  entries: Array<TimeEntryInput>;
  ownerId: Scalars['ID'];
  ownerType: EntityKind;
};

export type MutationObjectTimeEntryCheckInArgs = {
  ownerId: Scalars['ID'];
  ownerType: EntityKind;
};

export type MutationObjectTimeEntryCheckOutArgs = {
  ownerId: Scalars['ID'];
  ownerType: EntityKind;
};

export type MutationObjectUserAcceptTosArgs = {
  accepted?: InputMaybe<Scalars['Boolean']>;
};

export type MutationObjectUserActivateArgs = {
  user: Scalars['ID'];
};

export type MutationObjectUserAddRoleArgs = {
  role: UserRole;
};

export type MutationObjectUserDeactivateArgs = {
  user: Scalars['ID'];
};

export type MutationObjectUserDeleteArgs = {
  user: Scalars['ID'];
};

export type MutationObjectUserUpdateArgs = {
  userInfo: UserInput;
};

export type MutationObjectVendorCreateArgs = {
  attributes: VendorInput;
  siteId: Scalars['ID'];
};

export type MutationObjectVendorMakeSiteCompanyArgs = {
  vendor: Scalars['ID'];
};

export type MutationObjectVendorUpdateArgs = {
  companyName: Scalars['String'];
  contacts?: InputMaybe<Array<ContactInput>>;
  vendor: Scalars['ID'];
};

export type MutationObjectVendorUpdateComplianceArgs = {
  attributes: ComplianceRequirementInput;
};

export type MutationObjectVendorsDeleteArgs = {
  ids: Array<Scalars['ID']>;
};

export type MutationsQueryResults = CollectionInfo & {
  count?: Maybe<Scalars['Int']>;
  filterOptions?: Maybe<Array<Maybe<Option>>>;
  /** The unique identifier of the resource. */
  id: Scalars['ID'];
  items?: Maybe<Array<Mutation>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  pages?: Maybe<Scalars['Int']>;
  totals?: Maybe<Array<Scalars['Int']>>;
};

export type OpeningSeatsWithWaitlistInput = {
  id: Scalars['ID'];
  newMaxCapacity: Scalars['Int'];
};

export type OpeningSeatsWithWaitlistOutput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  result: Scalars['Boolean'];
};

export type Option = {
  label: Scalars['String'];
  value?: Maybe<Scalars['OptionValue']>;
};

export type OptionParentsQueryResults = CollectionInfo & {
  count?: Maybe<Scalars['Int']>;
  filterOptions?: Maybe<Array<Maybe<Option>>>;
  /** The unique identifier of the resource. */
  id: Scalars['ID'];
  items?: Maybe<Array<Maybe<Enrollment>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  pages?: Maybe<Scalars['Int']>;
  totals?: Maybe<Array<Scalars['Int']>>;
};

export type Order = ActiveRecordInterface &
  Receipt & {
    createdAt: Scalars['ISO8601DateTime'];
    id: Scalars['ID'];
    items: Array<OrderItem>;
    paymentFailureReason?: Maybe<Scalars['String']>;
    status: PaymentStatus;
    total: Scalars['Float'];
  };

export type OrderItem = Receipt & {
  course?: Maybe<Course>;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  product: OrderItemProduct;
  total: Scalars['Float'];
};

export type OrderItemProduct = Donation | Enrollment;

export enum Organization {
  Private = 'private',
  Public = 'public'
}

export type OrganizerRedesign = {
  bannerSeenNewUi?: Maybe<Scalars['ISO8601DateTime']>;
  bannerSeenOldUi?: Maybe<Scalars['ISO8601DateTime']>;
  introSeenNewUi?: Maybe<Scalars['ISO8601DateTime']>;
  on?: Maybe<Scalars['Boolean']>;
  switchable?: Maybe<Scalars['Boolean']>;
};

export type OrganizerRedesignInput = {
  bannerSeenNewUi?: InputMaybe<Scalars['ISO8601DateTime']>;
  bannerSeenOldUi?: InputMaybe<Scalars['ISO8601DateTime']>;
  introSeenNewUi?: InputMaybe<Scalars['ISO8601DateTime']>;
  on?: InputMaybe<Scalars['Boolean']>;
  switchable?: InputMaybe<Scalars['Boolean']>;
};

export enum OrganizerRole {
  Coordinator = 'COORDINATOR',
  SiteMember = 'SITE_MEMBER'
}

/** Autogenerated return type of ParentAddCreditCard */
export type ParentAddCreditCardPayload = {
  success?: Maybe<Scalars['Boolean']>;
};

export type ParentAttendanceConfig = ActiveRecordInterface & {
  enabled: Scalars['Boolean'];
  /** The unique identifier of the resource. */
  id: Scalars['ID'];
  notify: Scalars['Boolean'];
};

export type ParentAttendanceInput = {
  action: AttendanceAction;
  course: Scalars['ID'];
  student: Scalars['ID'];
};

export type ParentAttendanceStudentsResult = {
  items: Array<RosterItem>;
  owner: Contact;
};

export type ParentEnrollmentsQueryResults = CollectionInfo & {
  count?: Maybe<Scalars['Int']>;
  filterOptions?: Maybe<Array<Maybe<Option>>>;
  /** The unique identifier of the resource. */
  id: Scalars['ID'];
  items?: Maybe<Array<Maybe<Enrollment>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  pages?: Maybe<Scalars['Int']>;
  totals?: Maybe<Array<Scalars['Int']>>;
};

export type ParentRedesign = {
  introSeenNewUi?: Maybe<Scalars['ISO8601DateTime']>;
  on?: Maybe<Scalars['Boolean']>;
};

export type ParentRedesignInput = {
  introSeenNewUi?: InputMaybe<Scalars['ISO8601DateTime']>;
  on?: InputMaybe<Scalars['Boolean']>;
};

/** Autogenerated return type of ParentRemoveCreditCard */
export type ParentRemoveCreditCardPayload = {
  success?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of ParentRemoveEnrollmentsFromWaitlist */
export type ParentRemoveEnrollmentsFromWaitlistPayload = {
  enrollments?: Maybe<Array<Maybe<Enrollment>>>;
};

/** Autogenerated return type of ParentRemoveUsBankAccount */
export type ParentRemoveUsBankAccountPayload = {
  success?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of ParentSetAttendance */
export type ParentSetAttendancePayload = {
  attendances?: Maybe<Array<RosterItem>>;
  owner?: Maybe<Contact>;
  results?: Maybe<Array<ParentSetAttendanceResult>>;
};

export type ParentSetAttendanceResult = {
  action: AttendanceAction;
  id: Scalars['ID'];
};

/** Autogenerated return type of ParentUpsertCcContacts */
export type ParentUpsertCcContactsPayload = {
  user?: Maybe<User>;
};

/** Autogenerated return type of ParentWithdrawEnrollment */
export type ParentWithdrawEnrollmentPayload = {
  enrollment?: Maybe<Enrollment>;
};

/** Autogenerated return type of ParentsMerge */
export type ParentsMergePayload = {
  user?: Maybe<User>;
};

export type Participant = {
  attendanceCodeCode?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  /** The unique identifier of the resource. */
  id: Scalars['ID'];
  kind: ParticipantKind;
  lastName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  normalizedRole?: Maybe<MemberRole>;
  phone?: Maybe<Scalars['String']>;
  relation?: Maybe<Scalars['String']>;
  role: Scalars['String'];
};

export type ParticipantInput = {
  _destroy?: InputMaybe<Scalars['Boolean']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  kind: ParticipantKind;
  lastName?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  relation?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
};

export enum ParticipantKind {
  Contact = 'contact',
  Invite = 'invite',
  Member = 'member'
}

export type ParticipantsQueryResults = CollectionInfo & {
  count?: Maybe<Scalars['Int']>;
  filterOptions?: Maybe<Array<Maybe<Option>>>;
  /** The unique identifier of the resource. */
  id: Scalars['ID'];
  items?: Maybe<Array<Participant>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  pages?: Maybe<Scalars['Int']>;
  totals?: Maybe<Array<Scalars['Int']>>;
};

/** Autogenerated return type of ParticipantsUpsert */
export type ParticipantsUpsertPayload = {
  entity?: Maybe<WithTeam>;
};

export enum PaymentService {
  Ach = 'ACH',
  Affirm = 'AFFIRM',
  Card = 'CARD',
  Free = 'FREE'
}

export enum PaymentStatus {
  Canceled = 'CANCELED',
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  Paid = 'PAID',
  Pending = 'PENDING',
  Processing = 'PROCESSING',
  Ready = 'READY',
  RetriableError = 'RETRIABLE_ERROR'
}

export type Payout = ActiveRecordInterface & {
  amount?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['ISO8601Date']>;
  /** The unique identifier of the resource. */
  id: Scalars['ID'];
  item?: Maybe<Payout>;
  items?: Maybe<Array<Receipt>>;
  lastError?: Maybe<Scalars['String']>;
  paymentServiceId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['ISO8601Date']>;
  sites?: Maybe<Array<Site>>;
  status?: Maybe<Scalars['String']>;
  target?: Maybe<SiteOrCompany>;
};

export type PayoutItemArgs = {
  payoutId: Scalars['ID'];
};

export type PayoutsQueryResults = CollectionInfo & {
  count?: Maybe<Scalars['Int']>;
  filterOptions?: Maybe<Array<Maybe<Option>>>;
  /** The unique identifier of the resource. */
  id: Scalars['ID'];
  items?: Maybe<Array<Payout>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  pages?: Maybe<Scalars['Int']>;
  totals?: Maybe<Array<Scalars['Int']>>;
};

export type Policy = ActiveRecordInterface & {
  bathroom?: Maybe<Scalars['String']>;
  classroom?: Maybe<Scalars['String']>;
  /** The unique identifier of the resource. */
  id: Scalars['ID'];
  lateParent?: Maybe<Scalars['String']>;
  missingStudent?: Maybe<Scalars['String']>;
  parentWithdrawalsEnabled: Scalars['Boolean'];
  parking?: Maybe<Scalars['String']>;
  schoolRules?: Maybe<Scalars['String']>;
  site?: Maybe<Site>;
  siteId: Scalars['Int'];
  snacks?: Maybe<Scalars['String']>;
  studentDismissal?: Maybe<Scalars['String']>;
  studentPickup?: Maybe<Scalars['String']>;
  teacherCheckin?: Maybe<Scalars['String']>;
  token: Scalars['String'];
};

export type PolicyInput = {
  bathroom?: InputMaybe<Scalars['String']>;
  classroom?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  lateParent?: InputMaybe<Scalars['String']>;
  missingStudent?: InputMaybe<Scalars['String']>;
  parentWithdrawalsEnabled?: InputMaybe<Scalars['Boolean']>;
  parking?: InputMaybe<Scalars['String']>;
  schoolRules?: InputMaybe<Scalars['String']>;
  snacks?: InputMaybe<Scalars['String']>;
  studentDismissal?: InputMaybe<Scalars['String']>;
  studentPickup?: InputMaybe<Scalars['String']>;
  teacherCheckin?: InputMaybe<Scalars['String']>;
};

export type PriceBreakdown = {
  discountAmount?: Maybe<Scalars['Float']>;
  homeroomCharges?: Maybe<Scalars['Float']>;
  listPrice?: Maybe<Scalars['Float']>;
  listPriceBeforeDiscount?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  priceBeforeDiscount?: Maybe<Scalars['Float']>;
  siteCharges?: Maybe<Scalars['Float']>;
  siteCreditCardFee?: Maybe<Scalars['Float']>;
  siteReceives?: Maybe<Scalars['Float']>;
  vendorCharges?: Maybe<Scalars['Float']>;
  vendorCreditCardFee?: Maybe<Scalars['Float']>;
  vendorReceives?: Maybe<Scalars['Float']>;
};

export type PriceBreakdowns = {
  dropIn?: Maybe<PriceBreakdown>;
  recurring?: Maybe<Array<RecurringPriceBreakdown>>;
  season?: Maybe<PriceBreakdown>;
  seasons?: Maybe<Array<SeasonPriceBreakdown>>;
  usage?: Maybe<Array<UsagePriceBreakdown>>;
};

export type PriceConfig = {
  billingDate?: Maybe<Scalars['ISO8601Date']>;
  billingDescription: Scalars['String'];
  kind: PriceConfigKind;
  purchaseDescription?: Maybe<Scalars['String']>;
  template: Scalars['Boolean'];
  updateDescription?: Maybe<Scalars['String']>;
};

export enum PriceConfigKind {
  ConfigurableSeason = 'CONFIGURABLE_SEASON',
  Custom = 'CUSTOM',
  DropIn = 'DROP_IN',
  Recurring = 'RECURRING',
  Season = 'SEASON',
  Usage = 'USAGE'
}

export type PriceTier = ActiveRecordInterface & {
  displayOrder: Scalars['Int'];
  /** The unique identifier of the resource. */
  id: Scalars['ID'];
  name: Scalars['String'];
  rate: Scalars['Float'];
};

export type PriceTierInput = {
  archive?: InputMaybe<Scalars['Boolean']>;
  displayOrder?: InputMaybe<Scalars['Int']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  rate?: InputMaybe<Scalars['Float']>;
};

export type PriceTierUse = ActiveRecordInterface & {
  amountOff: Scalars['Float'];
  displayOrder: Scalars['Int'];
  /** The unique identifier of the resource. */
  id: Scalars['ID'];
  name: Scalars['String'];
  priceTierId: Scalars['ID'];
  rate: Scalars['Float'];
};

export type Prices = {
  dropIn: DropInPrice;
  recurring: Array<RecurringPrice>;
  season: SeasonPrice;
  seasons: Array<SeasonPrice>;
  usage: Array<UsagePrice>;
};

export enum ProrationKind {
  All = 'all',
  CourseDates = 'course_dates',
  None = 'none'
}

export type QueryObject = {
  adminUser?: Maybe<User>;
  adminUsers?: Maybe<Array<User>>;
  adminUsersQuery?: Maybe<AdminUsersQueryResults>;
  cartExpiration?: Maybe<Scalars['ISO8601DateTime']>;
  cfQuestions?: Maybe<Array<CfQuestion>>;
  companies?: Maybe<Array<Company>>;
  companiesQuery?: Maybe<CompaniesQueryResults>;
  company?: Maybe<Company>;
  companyCourseSummaryReport?: Maybe<CourseSummaryReport>;
  course?: Maybe<Course>;
  courseFramework?: Maybe<CourseFramework>;
  coursePriceBreakdowns?: Maybe<PriceBreakdowns>;
  courseTags?: Maybe<Array<CourseTag>>;
  courseTagsQuery?: Maybe<CourseTagsQueryResults>;
  courses?: Maybe<Array<Course>>;
  coursesQuery?: Maybe<CoursesQueryResults>;
  currentUser?: Maybe<User>;
  directoryCompanySearch?: Maybe<CompanyDirectoryResults>;
  directorySiteSearch?: Maybe<SiteDirectoryResults>;
  discountDefinitions: Array<Maybe<DiscountDefinition>>;
  enrollingSoonSeasons?: Maybe<Array<Maybe<EnrollingSoonSeason>>>;
  enrollment?: Maybe<Enrollment>;
  enrollmentDestinations?: Maybe<Array<Maybe<Course>>>;
  enrollmentPriceBreakdown?: Maybe<AddEnrollmentPriceBreakdown>;
  enrollments?: Maybe<Array<Enrollment>>;
  enrollmentsQuery?: Maybe<EnrollmentsQueryResults>;
  enrollmentsRefundBreakdowns?: Maybe<BulkRefundBreakdown>;
  entity?: Maybe<ActiveRecordInterface>;
  event?: Maybe<Event>;
  events?: Maybe<Array<Event>>;
  eventsQuery?: Maybe<EventsQueryResults>;
  families?: Maybe<Array<Family>>;
  familiesQuery?: Maybe<FamiliesQueryResults>;
  family?: Maybe<Family>;
  history?: Maybe<Array<HistoryVersion>>;
  homeroom?: Maybe<Homeroom>;
  imageSearch?: Maybe<Array<Image>>;
  msg?: Maybe<Message>;
  msgThread?: Maybe<MessageThread>;
  msgThreads?: Maybe<Array<MessageThread>>;
  mutation?: Maybe<Mutation>;
  mutationChanges?: Maybe<Array<MutationChange>>;
  mutationChangesQuery?: Maybe<MutationChangesQueryResults>;
  openingSeatsWithWaitlist?: Maybe<Array<OpeningSeatsWithWaitlistOutput>>;
  parentAttendanceStudents?: Maybe<ParentAttendanceStudentsResult>;
  payout?: Maybe<Payout>;
  payouts?: Maybe<Array<Payout>>;
  payoutsQuery?: Maybe<PayoutsQueryResults>;
  policy?: Maybe<Policy>;
  reportTransactions?: Maybe<ReportResult>;
  reportTransactionsFilterOptions?: Maybe<Array<Maybe<Option>>>;
  review?: Maybe<Review>;
  reviews?: Maybe<Array<Review>>;
  reviewsQuery?: Maybe<ReviewsQueryResults>;
  rosterByGrouping?: Maybe<RosterResult>;
  rosterFilterOptions?: Maybe<Array<Maybe<Option>>>;
  season?: Maybe<Season>;
  seasonCourseFilterOptions?: Maybe<Array<Maybe<Option>>>;
  seasonCourses?: Maybe<CoursesResult>;
  site?: Maybe<Site>;
  siteCourseSummaryReport?: Maybe<CourseSummaryReport>;
  siteFees?: Maybe<CourseFeeBreakdown>;
  sites?: Maybe<Array<Site>>;
  sitesQuery?: Maybe<SitesQueryResults>;
  sitesSearch?: Maybe<Array<Site>>;
  student?: Maybe<Student>;
  studentByToken?: Maybe<Student>;
  students?: Maybe<Array<Student>>;
  studentsQuery?: Maybe<StudentsQueryResults>;
  studentsSearch?: Maybe<Array<Student>>;
  timeEntries?: Maybe<Array<TimeEntry>>;
  timeEntriesQuery?: Maybe<TimeEntriesQueryResults>;
  user?: Maybe<User>;
  userCurrentTimeEntries?: Maybe<Array<TimeEntry>>;
  userCurrentTimeEntriesQuery?: Maybe<UserCurrentTimeEntriesQueryResults>;
  vendor?: Maybe<Vendor>;
  vendorCompliance?: Maybe<Array<Maybe<ComplianceRequirement>>>;
  vendorTeacherCompliance?: Maybe<Array<Maybe<ComplianceRequirement>>>;
  zipCodeSearch?: Maybe<Location>;
};

export type QueryObjectAdminUserArgs = {
  user: Scalars['ID'];
};

export type QueryObjectAdminUsersArgs = {
  advancedFilter?: InputMaybe<Scalars['JSON']>;
  filters?: InputMaybe<Array<Filter>>;
  groups?: InputMaybe<Array<Scalars['String']>>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  queries?: InputMaybe<Array<Scalars['JSON']>>;
  sorts?: InputMaybe<Array<Sort>>;
};

export type QueryObjectAdminUsersQueryArgs = {
  advancedFilter?: InputMaybe<Scalars['JSON']>;
  filterOptions?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<Filter>>;
  groups?: InputMaybe<Array<Scalars['String']>>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  queries?: InputMaybe<Array<Scalars['JSON']>>;
  sorts?: InputMaybe<Array<Sort>>;
  totals?: InputMaybe<Array<Total>>;
};

export type QueryObjectCfQuestionsArgs = {
  ownerId: Scalars['ID'];
  ownerType: CfOwnerKind;
};

export type QueryObjectCompaniesArgs = {
  advancedFilter?: InputMaybe<Scalars['JSON']>;
  filters?: InputMaybe<Array<Filter>>;
  groups?: InputMaybe<Array<Scalars['String']>>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  queries?: InputMaybe<Array<Scalars['JSON']>>;
  sorts?: InputMaybe<Array<Sort>>;
};

export type QueryObjectCompaniesQueryArgs = {
  advancedFilter?: InputMaybe<Scalars['JSON']>;
  filterOptions?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<Filter>>;
  groups?: InputMaybe<Array<Scalars['String']>>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  queries?: InputMaybe<Array<Scalars['JSON']>>;
  sorts?: InputMaybe<Array<Sort>>;
  totals?: InputMaybe<Array<Total>>;
};

export type QueryObjectCompanyArgs = {
  company: Scalars['ID'];
};

export type QueryObjectCompanyCourseSummaryReportArgs = {
  company: Scalars['ID'];
};

export type QueryObjectCourseArgs = {
  course: Scalars['ID'];
};

export type QueryObjectCourseFrameworkArgs = {
  courseFramework: Scalars['ID'];
};

export type QueryObjectCoursePriceBreakdownsArgs = {
  discountFormat?: InputMaybe<DiscountFormat>;
  discountRate?: InputMaybe<Scalars['Float']>;
  feeRate?: InputMaybe<Scalars['Float']>;
  feeType?: InputMaybe<SiteFeeTypeEnum>;
  id: Scalars['ID'];
  rates?: InputMaybe<RatesInput>;
};

export type QueryObjectCourseTagsArgs = {
  advancedFilter?: InputMaybe<Scalars['JSON']>;
  filters?: InputMaybe<Array<Filter>>;
  groups?: InputMaybe<Array<Scalars['String']>>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  queries?: InputMaybe<Array<Scalars['JSON']>>;
  sorts?: InputMaybe<Array<Sort>>;
};

export type QueryObjectCourseTagsQueryArgs = {
  advancedFilter?: InputMaybe<Scalars['JSON']>;
  filterOptions?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<Filter>>;
  groups?: InputMaybe<Array<Scalars['String']>>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  queries?: InputMaybe<Array<Scalars['JSON']>>;
  sorts?: InputMaybe<Array<Sort>>;
  totals?: InputMaybe<Array<Total>>;
};

export type QueryObjectCoursesArgs = {
  advancedFilter?: InputMaybe<Scalars['JSON']>;
  filters?: InputMaybe<Array<Filter>>;
  groups?: InputMaybe<Array<Scalars['String']>>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  queries?: InputMaybe<Array<Scalars['JSON']>>;
  sorts?: InputMaybe<Array<Sort>>;
};

export type QueryObjectCoursesQueryArgs = {
  advancedFilter?: InputMaybe<Scalars['JSON']>;
  filterOptions?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<Filter>>;
  groups?: InputMaybe<Array<Scalars['String']>>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  queries?: InputMaybe<Array<Scalars['JSON']>>;
  sorts?: InputMaybe<Array<Sort>>;
  totals?: InputMaybe<Array<Total>>;
};

export type QueryObjectDirectoryCompanySearchArgs = {
  grades?: InputMaybe<Array<Scalars['String']>>;
  keywords?: InputMaybe<Scalars['String']>;
  site?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Array<Scalars['String']>>;
  zipCode?: InputMaybe<Scalars['String']>;
};

export type QueryObjectDirectorySiteSearchArgs = {
  company?: InputMaybe<Scalars['String']>;
  zipCode?: InputMaybe<Scalars['String']>;
};

export type QueryObjectDiscountDefinitionsArgs = {
  ownerId: Scalars['ID'];
  ownerType: Scalars['String'];
};

export type QueryObjectEnrollmentArgs = {
  enrollment: Scalars['ID'];
};

export type QueryObjectEnrollmentDestinationsArgs = {
  sourceId: Scalars['ID'];
};

export type QueryObjectEnrollmentPriceBreakdownArgs = {
  afterClassDismissal?: InputMaybe<Scalars['String']>;
  beforeClassPickup?: InputMaybe<Scalars['String']>;
  changingEnrollment?: InputMaybe<Scalars['ID']>;
  configurableSeason?: InputMaybe<SeasonPriceConfigInput>;
  custom?: InputMaybe<CustomPriceConfigInput>;
  discount?: InputMaybe<DiscountInput>;
  dismissal?: InputMaybe<Scalars['String']>;
  dropIn?: InputMaybe<DropInPriceConfigInput>;
  dropInPriceConfig?: InputMaybe<DropInPriceConfigInput>;
  effective?: InputMaybe<ChangePricingPeriod>;
  id: Scalars['ID'];
  kind?: InputMaybe<PriceConfigKind>;
  options?: InputMaybe<Array<EnrollmentOptionInput>>;
  pickup?: InputMaybe<Scalars['String']>;
  priceKind?: InputMaybe<PriceConfigKind>;
  recurring?: InputMaybe<RecurringPriceConfigInput>;
  recurringPriceConfig?: InputMaybe<RecurringPriceConfigInput>;
  startDate?: InputMaybe<Scalars['ISO8601Date']>;
  studentId?: InputMaybe<Scalars['ID']>;
};

export type QueryObjectEnrollmentsArgs = {
  advancedFilter?: InputMaybe<Scalars['JSON']>;
  filters?: InputMaybe<Array<Filter>>;
  groups?: InputMaybe<Array<Scalars['String']>>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  queries?: InputMaybe<Array<Scalars['JSON']>>;
  sorts?: InputMaybe<Array<Sort>>;
};

export type QueryObjectEnrollmentsQueryArgs = {
  advancedFilter?: InputMaybe<Scalars['JSON']>;
  filterOptions?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<Filter>>;
  groups?: InputMaybe<Array<Scalars['String']>>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  queries?: InputMaybe<Array<Scalars['JSON']>>;
  sorts?: InputMaybe<Array<Sort>>;
  totals?: InputMaybe<Array<Total>>;
};

export type QueryObjectEnrollmentsRefundBreakdownsArgs = {
  amount?: InputMaybe<Scalars['Float']>;
  ids: Array<Scalars['ID']>;
  kind: RefundKind;
};

export type QueryObjectEntityArgs = {
  entityId: Scalars['ID'];
  entityKind: Scalars['String'];
};

export type QueryObjectEventArgs = {
  event: Scalars['ID'];
};

export type QueryObjectEventsArgs = {
  advancedFilter?: InputMaybe<Scalars['JSON']>;
  filters?: InputMaybe<Array<Filter>>;
  groups?: InputMaybe<Array<Scalars['String']>>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  queries?: InputMaybe<Array<Scalars['JSON']>>;
  sorts?: InputMaybe<Array<Sort>>;
};

export type QueryObjectEventsQueryArgs = {
  advancedFilter?: InputMaybe<Scalars['JSON']>;
  filterOptions?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<Filter>>;
  groups?: InputMaybe<Array<Scalars['String']>>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  queries?: InputMaybe<Array<Scalars['JSON']>>;
  sorts?: InputMaybe<Array<Sort>>;
  totals?: InputMaybe<Array<Total>>;
};

export type QueryObjectFamiliesArgs = {
  advancedFilter?: InputMaybe<Scalars['JSON']>;
  filters?: InputMaybe<Array<Filter>>;
  groups?: InputMaybe<Array<Scalars['String']>>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  queries?: InputMaybe<Array<Scalars['JSON']>>;
  sorts?: InputMaybe<Array<Sort>>;
};

export type QueryObjectFamiliesQueryArgs = {
  advancedFilter?: InputMaybe<Scalars['JSON']>;
  filterOptions?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<Filter>>;
  groups?: InputMaybe<Array<Scalars['String']>>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  queries?: InputMaybe<Array<Scalars['JSON']>>;
  sorts?: InputMaybe<Array<Sort>>;
  totals?: InputMaybe<Array<Total>>;
};

export type QueryObjectFamilyArgs = {
  family: Scalars['ID'];
};

export type QueryObjectHistoryArgs = {
  id: Scalars['ID'];
  type: Scalars['String'];
};

export type QueryObjectImageSearchArgs = {
  folder: Scalars['String'];
};

export type QueryObjectMsgArgs = {
  message: Scalars['ID'];
};

export type QueryObjectMsgThreadArgs = {
  entityMessages?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
};

export type QueryObjectMsgThreadsArgs = {
  entityMessages?: InputMaybe<Scalars['Boolean']>;
  labelOrParticipant?: InputMaybe<MessageEntityInput>;
  nonSystemMessages?: InputMaybe<Scalars['Boolean']>;
  systemMessages?: InputMaybe<Scalars['Boolean']>;
  threadId?: InputMaybe<Scalars['String']>;
};

export type QueryObjectMutationArgs = {
  mutation: Scalars['ID'];
};

export type QueryObjectMutationChangesArgs = {
  advancedFilter?: InputMaybe<Scalars['JSON']>;
  filters?: InputMaybe<Array<Filter>>;
  groups?: InputMaybe<Array<Scalars['String']>>;
  id: Scalars['ID'];
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  queries?: InputMaybe<Array<Scalars['JSON']>>;
  sorts?: InputMaybe<Array<Sort>>;
  type: Scalars['String'];
};

export type QueryObjectMutationChangesQueryArgs = {
  advancedFilter?: InputMaybe<Scalars['JSON']>;
  filterOptions?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<Filter>>;
  groups?: InputMaybe<Array<Scalars['String']>>;
  id: Scalars['ID'];
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  queries?: InputMaybe<Array<Scalars['JSON']>>;
  sorts?: InputMaybe<Array<Sort>>;
  totals?: InputMaybe<Array<Total>>;
  type: Scalars['String'];
};

export type QueryObjectOpeningSeatsWithWaitlistArgs = {
  inputs: Array<OpeningSeatsWithWaitlistInput>;
};

export type QueryObjectParentAttendanceStudentsArgs = {
  code: Scalars['String'];
};

export type QueryObjectPayoutArgs = {
  payout: Scalars['ID'];
};

export type QueryObjectPayoutsArgs = {
  advancedFilter?: InputMaybe<Scalars['JSON']>;
  filters?: InputMaybe<Array<Filter>>;
  groups?: InputMaybe<Array<Scalars['String']>>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  queries?: InputMaybe<Array<Scalars['JSON']>>;
  sorts?: InputMaybe<Array<Sort>>;
};

export type QueryObjectPayoutsQueryArgs = {
  advancedFilter?: InputMaybe<Scalars['JSON']>;
  filterOptions?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<Filter>>;
  groups?: InputMaybe<Array<Scalars['String']>>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  queries?: InputMaybe<Array<Scalars['JSON']>>;
  sorts?: InputMaybe<Array<Sort>>;
  totals?: InputMaybe<Array<Total>>;
};

export type QueryObjectPolicyArgs = {
  policy: Scalars['ID'];
};

export type QueryObjectReportTransactionsArgs = {
  advancedFilter?: InputMaybe<Scalars['JSON']>;
  current: Scalars['ID'];
  filters?: InputMaybe<Array<Filter>>;
  sorts?: InputMaybe<Array<Sort>>;
  totals?: InputMaybe<Array<Total>>;
  type: EntityKind;
};

export type QueryObjectReportTransactionsFilterOptionsArgs = {
  current: Scalars['ID'];
  name: Scalars['String'];
  selected?: InputMaybe<Array<Scalars['ID']>>;
  type: EntityKind;
};

export type QueryObjectReviewArgs = {
  review: Scalars['String'];
};

export type QueryObjectReviewsArgs = {
  advancedFilter?: InputMaybe<Scalars['JSON']>;
  filters?: InputMaybe<Array<Filter>>;
  groups?: InputMaybe<Array<Scalars['String']>>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  queries?: InputMaybe<Array<Scalars['JSON']>>;
  sorts?: InputMaybe<Array<Sort>>;
};

export type QueryObjectReviewsQueryArgs = {
  advancedFilter?: InputMaybe<Scalars['JSON']>;
  filterOptions?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<Filter>>;
  groups?: InputMaybe<Array<Scalars['String']>>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  queries?: InputMaybe<Array<Scalars['JSON']>>;
  sorts?: InputMaybe<Array<Sort>>;
  totals?: InputMaybe<Array<Total>>;
};

export type QueryObjectRosterByGroupingArgs = {
  advancedFilter?: InputMaybe<Scalars['JSON']>;
  date?: InputMaybe<Scalars['ISO8601Date']>;
  enrollmentKind?: InputMaybe<EnrollmentStatusFilter>;
  enrollmentStatus?: InputMaybe<EnrollmentStatusFilter>;
  filters?: InputMaybe<RosterFilters>;
  groupingId: Scalars['ID'];
  groupingKind: RosterGroupingKind;
  limit?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<RosterSort>;
  sorts?: InputMaybe<Array<RosterSort>>;
};

export type QueryObjectRosterFilterOptionsArgs = {
  date?: InputMaybe<Scalars['ISO8601Date']>;
  enrollmentKind?: InputMaybe<EnrollmentStatusFilter>;
  enrollmentStatus?: InputMaybe<EnrollmentStatusFilter>;
  filterKind: RosterFilterKind;
  groupingId: Scalars['ID'];
  groupingKind: RosterGroupingKind;
};

export type QueryObjectSeasonArgs = {
  season: Scalars['ID'];
};

export type QueryObjectSeasonCourseFilterOptionsArgs = {
  companyId?: InputMaybe<Scalars['ID']>;
  courseId?: InputMaybe<Scalars['ID']>;
  courseKind?: InputMaybe<CourseDraftStatus>;
  filterOptionKind: CourseFilterOptionKind;
  kinds?: InputMaybe<Array<CourseKind>>;
  seasonId?: InputMaybe<Scalars['ID']>;
  status?: InputMaybe<CourseDraftStatus>;
  statuses?: InputMaybe<Array<CourseStatus>>;
  totals?: InputMaybe<Array<Array<CourseStatus>>>;
};

export type QueryObjectSeasonCoursesArgs = {
  companyId?: InputMaybe<Scalars['ID']>;
  courseId?: InputMaybe<Scalars['ID']>;
  courseKind?: InputMaybe<CourseDraftStatus>;
  filters?: InputMaybe<CourseFilters>;
  kinds?: InputMaybe<Array<CourseKind>>;
  seasonId?: InputMaybe<Scalars['ID']>;
  sort?: InputMaybe<CourseSort>;
  status?: InputMaybe<CourseDraftStatus>;
  statuses?: InputMaybe<Array<CourseStatus>>;
  totals?: InputMaybe<Array<Array<CourseStatus>>>;
};

export type QueryObjectSiteArgs = {
  site: Scalars['ID'];
};

export type QueryObjectSiteCourseSummaryReportArgs = {
  season: Scalars['ID'];
};

export type QueryObjectSiteFeesArgs = {
  cost?: InputMaybe<Scalars['Float']>;
  feeRate?: InputMaybe<Scalars['Float']>;
  feeType?: InputMaybe<SiteFeeTypeEnum>;
  id?: InputMaybe<Scalars['ID']>;
  site?: InputMaybe<Scalars['ID']>;
};

export type QueryObjectSitesArgs = {
  advancedFilter?: InputMaybe<Scalars['JSON']>;
  filters?: InputMaybe<Array<Filter>>;
  groups?: InputMaybe<Array<Scalars['String']>>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  queries?: InputMaybe<Array<Scalars['JSON']>>;
  sorts?: InputMaybe<Array<Sort>>;
};

export type QueryObjectSitesQueryArgs = {
  advancedFilter?: InputMaybe<Scalars['JSON']>;
  filterOptions?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<Filter>>;
  groups?: InputMaybe<Array<Scalars['String']>>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  queries?: InputMaybe<Array<Scalars['JSON']>>;
  sorts?: InputMaybe<Array<Sort>>;
  totals?: InputMaybe<Array<Total>>;
};

export type QueryObjectSitesSearchArgs = {
  activeOnly?: InputMaybe<Scalars['Boolean']>;
  connectedSitesOnly?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  searchableOnly?: InputMaybe<Scalars['Boolean']>;
  term: Scalars['String'];
};

export type QueryObjectStudentArgs = {
  student: Scalars['ID'];
};

export type QueryObjectStudentByTokenArgs = {
  id: Scalars['ID'];
  token: Scalars['String'];
};

export type QueryObjectStudentsArgs = {
  advancedFilter?: InputMaybe<Scalars['JSON']>;
  filters?: InputMaybe<Array<Filter>>;
  groups?: InputMaybe<Array<Scalars['String']>>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  queries?: InputMaybe<Array<Scalars['JSON']>>;
  sorts?: InputMaybe<Array<Sort>>;
};

export type QueryObjectStudentsQueryArgs = {
  advancedFilter?: InputMaybe<Scalars['JSON']>;
  filterOptions?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<Filter>>;
  groups?: InputMaybe<Array<Scalars['String']>>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  queries?: InputMaybe<Array<Scalars['JSON']>>;
  sorts?: InputMaybe<Array<Sort>>;
  totals?: InputMaybe<Array<Total>>;
};

export type QueryObjectStudentsSearchArgs = {
  company?: InputMaybe<Scalars['ID']>;
  excludedCourses?: InputMaybe<Array<Scalars['ID']>>;
  site?: InputMaybe<Scalars['ID']>;
  term: Scalars['String'];
};

export type QueryObjectTimeEntriesArgs = {
  advancedFilter?: InputMaybe<Scalars['JSON']>;
  filters?: InputMaybe<Array<Filter>>;
  groups?: InputMaybe<Array<Scalars['String']>>;
  ownerId: Scalars['ID'];
  ownerType: EntityKind;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  queries?: InputMaybe<Array<Scalars['JSON']>>;
  sorts?: InputMaybe<Array<Sort>>;
};

export type QueryObjectTimeEntriesQueryArgs = {
  advancedFilter?: InputMaybe<Scalars['JSON']>;
  filterOptions?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<Filter>>;
  groups?: InputMaybe<Array<Scalars['String']>>;
  ownerId: Scalars['ID'];
  ownerType: EntityKind;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  queries?: InputMaybe<Array<Scalars['JSON']>>;
  sorts?: InputMaybe<Array<Sort>>;
  totals?: InputMaybe<Array<Total>>;
};

export type QueryObjectUserCurrentTimeEntriesArgs = {
  advancedFilter?: InputMaybe<Scalars['JSON']>;
  filters?: InputMaybe<Array<Filter>>;
  groups?: InputMaybe<Array<Scalars['String']>>;
  ownerId: Scalars['ID'];
  ownerType: EntityKind;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  queries?: InputMaybe<Array<Scalars['JSON']>>;
  sorts?: InputMaybe<Array<Sort>>;
};

export type QueryObjectUserCurrentTimeEntriesQueryArgs = {
  advancedFilter?: InputMaybe<Scalars['JSON']>;
  filterOptions?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<Filter>>;
  groups?: InputMaybe<Array<Scalars['String']>>;
  ownerId: Scalars['ID'];
  ownerType: EntityKind;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  queries?: InputMaybe<Array<Scalars['JSON']>>;
  sorts?: InputMaybe<Array<Sort>>;
  totals?: InputMaybe<Array<Total>>;
};

export type QueryObjectVendorArgs = {
  vendor: Scalars['ID'];
};

export type QueryObjectVendorComplianceArgs = {
  vendor: Scalars['ID'];
};

export type QueryObjectVendorTeacherComplianceArgs = {
  teacherId: Scalars['ID'];
  vendor: Scalars['ID'];
};

export type QueryObjectZipCodeSearchArgs = {
  zipcode: Scalars['String'];
};

export type Rates = {
  dropIn: DropInRate;
  recurring: Array<RecurringRate>;
  season: SeasonRate;
  seasons: Array<SeasonRate>;
  usage: Array<UsageRate>;
};

export type RatesInput = {
  dropIn?: InputMaybe<DropInRateInput>;
  recurring?: InputMaybe<Array<RecurringRateInput>>;
  season?: InputMaybe<SeasonRateInput>;
  seasons?: InputMaybe<Array<SeasonRateInput>>;
  usage?: InputMaybe<Array<UsageRateInput>>;
};

export type Receipt = {
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  total: Scalars['Float'];
};

export type RecurringPrice = {
  days?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Float']>;
  unit?: Maybe<RecurringUnit>;
};

export type RecurringPriceBreakdown = {
  days?: Maybe<Scalars['Int']>;
  discountAmount?: Maybe<Scalars['Float']>;
  homeroomCharges?: Maybe<Scalars['Float']>;
  listPrice?: Maybe<Scalars['Float']>;
  listPriceBeforeDiscount?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  priceBeforeDiscount?: Maybe<Scalars['Float']>;
  siteCharges?: Maybe<Scalars['Float']>;
  siteCreditCardFee?: Maybe<Scalars['Float']>;
  siteReceives?: Maybe<Scalars['Float']>;
  unit?: Maybe<RecurringUnit>;
  vendorCharges?: Maybe<Scalars['Float']>;
  vendorCreditCardFee?: Maybe<Scalars['Float']>;
  vendorReceives?: Maybe<Scalars['Float']>;
};

export type RecurringPriceConfig = PriceConfig & {
  billingDate?: Maybe<Scalars['ISO8601Date']>;
  billingDescription: Scalars['String'];
  kind: PriceConfigKind;
  purchaseDescription?: Maybe<Scalars['String']>;
  startDate: Scalars['ISO8601Date'];
  startDateDisabled: Scalars['Boolean'];
  template: Scalars['Boolean'];
  unit: RecurringUnit;
  updateDescription?: Maybe<Scalars['String']>;
  weekdays: Array<Scalars['String']>;
};

export type RecurringPriceConfigInput = {
  startDate?: InputMaybe<Scalars['ISO8601Date']>;
  unit: RecurringUnit;
  weekdays: Array<Scalars['String']>;
};

export type RecurringRate = {
  days?: Maybe<Scalars['Int']>;
  homeroomFeeRoundingIncrementCents?: Maybe<Scalars['Int']>;
  rate?: Maybe<Scalars['Float']>;
  startDateDisabled?: Maybe<Scalars['Boolean']>;
  unit?: Maybe<RecurringUnit>;
};

export type RecurringRateInput = {
  days?: InputMaybe<Scalars['Int']>;
  homeroomFeeRoundingIncrementCents?: InputMaybe<Scalars['Int']>;
  rate?: InputMaybe<Scalars['Float']>;
  startDateDisabled?: InputMaybe<Scalars['Boolean']>;
  unit?: InputMaybe<RecurringUnit>;
};

export enum RecurringUnit {
  Month = 'MONTH',
  Week = 'WEEK'
}

export type Refund = Receipt & {
  amount: Scalars['Float'];
  course?: Maybe<Course>;
  createdAt: Scalars['ISO8601DateTime'];
  description?: Maybe<Scalars['String']>;
  enrollment: Enrollment;
  id: Scalars['ID'];
  initiatedBy?: Maybe<SharedUser>;
  item: OrderItem;
  order?: Maybe<Order>;
  parent?: Maybe<SharedUser>;
  pending: Scalars['Boolean'];
  remainder: Scalars['Float'];
  status: PaymentStatus;
  student?: Maybe<Student>;
  total: Scalars['Float'];
  void: Scalars['Boolean'];
};

export type RefundBreakdown = {
  enrollment: Enrollment;
  familyReceives: Scalars['Float'];
  nonrefundableSiteFee: Scalars['Float'];
  otherFeeReversals: Scalars['Float'];
  providerWithdrawAmount: Scalars['Float'];
  siteWithdrawAmount: Scalars['Float'];
};

export enum RefundKind {
  Custom = 'CUSTOM',
  PerSession = 'PER_SESSION',
  Remainder = 'REMAINDER',
  Withdraw = 'WITHDRAW'
}

export type ReportResult = {
  items: Array<Maybe<ReportTransaction>>;
  total?: Maybe<Scalars['Int']>;
  totals?: Maybe<Array<Maybe<Scalars['Float']>>>;
};

export type ReportTransaction = {
  billPeriod?: Maybe<Scalars['String']>;
  company?: Maybe<Company>;
  companyEarnings?: Maybe<Scalars['Float']>;
  companyExternalTransferAmount?: Maybe<Scalars['Float']>;
  companyExternalTransferDate?: Maybe<Scalars['ISO8601Date']>;
  companyExternalTransferEstimatedDate?: Maybe<Scalars['ISO8601Date']>;
  companyExternalTransferStatus?: Maybe<Scalars['String']>;
  companyFee?: Maybe<Scalars['Float']>;
  companyHomeroomFee?: Maybe<Scalars['Float']>;
  companyInternalTransferDate?: Maybe<Scalars['ISO8601DateTime']>;
  companyProcessingFee?: Maybe<Scalars['Float']>;
  course?: Maybe<Course>;
  coversProcessingFee?: Maybe<Scalars['Boolean']>;
  discountAmount?: Maybe<Scalars['Float']>;
  discountCodes?: Maybe<Array<Scalars['String']>>;
  enrollmentPriceTierUse?: Maybe<EnrollmentPriceTierUse>;
  id?: Maybe<Scalars['ID']>;
  listPrice?: Maybe<Scalars['Float']>;
  parent?: Maybe<SharedUser>;
  paymentService?: Maybe<PaymentService>;
  rosterPeriod?: Maybe<Scalars['String']>;
  season?: Maybe<Season>;
  site?: Maybe<Site>;
  siteEarnings?: Maybe<Scalars['Float']>;
  siteExternalTransferAmount?: Maybe<Scalars['Float']>;
  siteExternalTransferDate?: Maybe<Scalars['ISO8601Date']>;
  siteExternalTransferEstimatedDate?: Maybe<Scalars['ISO8601Date']>;
  siteExternalTransferStatus?: Maybe<Scalars['String']>;
  siteFee?: Maybe<Scalars['Float']>;
  siteInternalTransferDate?: Maybe<Scalars['ISO8601DateTime']>;
  siteProcessingFee?: Maybe<Scalars['Float']>;
  student?: Maybe<Student>;
  trackingParameters?: Maybe<Scalars['String']>;
  transactionAmount?: Maybe<Scalars['Float']>;
  transactionDate?: Maybe<Scalars['ISO8601DateTime']>;
  transactionDescription?: Maybe<Scalars['String']>;
  transactionNote?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<Scalars['String']>;
};

export type Review = ActiveRecordInterface & {
  comments?: Maybe<Scalars['String']>;
  company?: Maybe<Company>;
  course?: Maybe<Course>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  enrollment?: Maybe<Enrollment>;
  /** The unique identifier of the resource. */
  id: Scalars['ID'];
  instructorRating?: Maybe<Scalars['Int']>;
  overallRating?: Maybe<Scalars['Int']>;
  parent?: Maybe<SharedUser>;
  qualityRating?: Maybe<Scalars['Int']>;
  site?: Maybe<Site>;
  status?: Maybe<Scalars['String']>;
  student?: Maybe<Student>;
  title?: Maybe<Scalars['String']>;
};

export type ReviewInput = {
  comments?: InputMaybe<Scalars['String']>;
  instructorRating?: InputMaybe<Scalars['Int']>;
  overallRating?: InputMaybe<Scalars['Int']>;
  qualityRating?: InputMaybe<Scalars['Int']>;
};

/** Autogenerated return type of ReviewUpsert */
export type ReviewUpsertPayload = {
  review?: Maybe<Review>;
};

export type ReviewsQueryResults = CollectionInfo & {
  count?: Maybe<Scalars['Int']>;
  filterOptions?: Maybe<Array<Maybe<Option>>>;
  /** The unique identifier of the resource. */
  id: Scalars['ID'];
  items?: Maybe<Array<Review>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  pages?: Maybe<Scalars['Int']>;
  totals?: Maybe<Array<Scalars['Int']>>;
};

export enum RosterFilterKind {
  Added = 'ADDED',
  AmountPaid = 'AMOUNT_PAID',
  AttendanceDate = 'ATTENDANCE_DATE',
  Balance = 'BALANCE',
  BilledAt = 'BILLED_AT',
  BilledDate = 'BILLED_DATE',
  Classroom = 'CLASSROOM',
  Course = 'COURSE',
  Day = 'DAY',
  DiscountAmount = 'DISCOUNT_AMOUNT',
  DiscountCode = 'DISCOUNT_CODE',
  Dismissal = 'DISMISSAL',
  EndDate = 'END_DATE',
  EnrollmentEndDate = 'ENROLLMENT_END_DATE',
  EnrollmentStartDate = 'ENROLLMENT_START_DATE',
  GracePeriod = 'GRACE_PERIOD',
  Grade = 'GRADE',
  Group = 'GROUP',
  Kind = 'KIND',
  Overlaps = 'OVERLAPS',
  Parent = 'PARENT',
  PaymentStatus = 'PAYMENT_STATUS',
  PriceTier = 'PRICE_TIER',
  Room = 'ROOM',
  RoundingIncrement = 'ROUNDING_INCREMENT',
  Season = 'SEASON',
  StartDate = 'START_DATE',
  Student = 'STUDENT',
  StudentFirstName = 'STUDENT_FIRST_NAME',
  StudentLastName = 'STUDENT_LAST_NAME',
  StudentMedicalAllergies = 'STUDENT_MEDICAL_ALLERGIES',
  StudentMedicalConditions = 'STUDENT_MEDICAL_CONDITIONS',
  StudentMedicalEpiPen = 'STUDENT_MEDICAL_EPI_PEN',
  StudentMedicalMedications = 'STUDENT_MEDICAL_MEDICATIONS',
  UsageBillingDate = 'USAGE_BILLING_DATE',
  UsageRate = 'USAGE_RATE',
  UsageUnitsBilled = 'USAGE_UNITS_BILLED'
}

export type RosterFilters = {
  byAdded?: InputMaybe<Array<Scalars['ISO8601Date']>>;
  byAmountPaid?: InputMaybe<Array<Scalars['Float']>>;
  byAttendanceDate?: InputMaybe<Array<Scalars['ISO8601Date']>>;
  byBalance?: InputMaybe<Array<Scalars['Float']>>;
  byBilledAt?: InputMaybe<Array<Scalars['ISO8601DateTime']>>;
  byBilledDate?: InputMaybe<Array<Scalars['ISO8601Date']>>;
  byClassroom?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  byCourseId?: InputMaybe<Array<Scalars['ID']>>;
  byDay?: InputMaybe<Array<Scalars['String']>>;
  byDiscountAmount?: InputMaybe<Array<Scalars['Float']>>;
  byDiscountCode?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  byDismissal?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  byEndDate?: InputMaybe<Array<Scalars['ID']>>;
  byEnrollmentEndDate?: InputMaybe<Array<Scalars['ISO8601Date']>>;
  byEnrollmentStartDate?: InputMaybe<Array<Scalars['ISO8601Date']>>;
  byGracePeriod?: InputMaybe<Array<Scalars['Int']>>;
  byGrade?: InputMaybe<Array<Scalars['String']>>;
  byGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  byOverlaps?: InputMaybe<Array<Scalars['Boolean']>>;
  byParent?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  byPaymentStatus?: InputMaybe<Array<PaymentStatus>>;
  byPriceTier?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  byRoom?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  byRoundingIncrement?: InputMaybe<Array<Scalars['Float']>>;
  bySeason?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  byStartDate?: InputMaybe<Array<Scalars['ID']>>;
  byStudent?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  byStudentFirstName?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  byStudentLastName?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  byStudentMedicalAllergies?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  byStudentMedicalConditions?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  byStudentMedicalEpiPen?: InputMaybe<Array<InputMaybe<Scalars['Boolean']>>>;
  byStudentMedicalMedications?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  byUsageBillingDate?: InputMaybe<Array<Scalars['ISO8601Date']>>;
  byUsageRate?: InputMaybe<Array<Scalars['Float']>>;
  byUsageUnit?: InputMaybe<Array<UsageUnit>>;
  byUsageUnitsBilled?: InputMaybe<Array<Scalars['Float']>>;
};

export enum RosterGroupingKind {
  Course = 'COURSE',
  Season = 'SEASON',
  Site = 'SITE'
}

export type RosterItem = ActiveRecordInterface &
  CartItem & {
    added?: Maybe<Scalars['ISO8601Date']>;
    additionalDiscount?: Maybe<Discount>;
    affirmEnabled: Scalars['Boolean'];
    ageAtStart?: Maybe<Scalars['Int']>;
    amount?: Maybe<Scalars['Float']>;
    athleticsConfig?: Maybe<AthleticsConfig>;
    balance: Scalars['Float'];
    billPeriod?: Maybe<Scalars['String']>;
    billedAt?: Maybe<Scalars['ISO8601DateTime']>;
    billedDate?: Maybe<Scalars['ISO8601Date']>;
    billingDescription?: Maybe<Scalars['String']>;
    billingStartDate?: Maybe<Scalars['ISO8601Date']>;
    cfAnswers?: Maybe<Scalars['JSON']>;
    cfFormattedAnswers?: Maybe<Scalars['JSON']>;
    cfFormattedSeasonAnswers?: Maybe<Scalars['JSON']>;
    cfQuestions?: Maybe<Array<CfQuestion>>;
    cfSeasonAnswers?: Maybe<Scalars['JSON']>;
    checkedIn?: Maybe<CheckedIn>;
    checkedInAt?: Maybe<Scalars['ISO8601DateTime']>;
    checkedInBy?: Maybe<Scalars['String']>;
    checkedOut?: Maybe<Scalars['Boolean']>;
    checkedOutAt?: Maybe<Scalars['ISO8601DateTime']>;
    checkedOutBy?: Maybe<Scalars['String']>;
    checkoutDest?: Maybe<Scalars['String']>;
    childEnrollments: Array<Enrollment>;
    childOptions: Array<Enrollment>;
    company: Company;
    contacts?: Maybe<Array<Maybe<Contact>>>;
    course: Course;
    courseOption?: Maybe<CourseOption>;
    createdAt: Scalars['ISO8601DateTime'];
    discount?: Maybe<Discount>;
    discountAmount: Scalars['Float'];
    discountCodes: Array<Scalars['String']>;
    discountId?: Maybe<Scalars['ID']>;
    discountUses: Array<DiscountUse>;
    discountedPrice: Scalars['Float'];
    dismissal?: Maybe<Scalars['String']>;
    endDate?: Maybe<Scalars['ISO8601Date']>;
    endsAt: Scalars['ISO8601DateTime'];
    enrollmentOptions: Array<EnrollmentOption>;
    enrollmentPriceTierUse?: Maybe<EnrollmentPriceTierUse>;
    family?: Maybe<Family>;
    firstPayment?: Maybe<FirstPayment>;
    formattedDays: Scalars['String'];
    formattedDaysAndTimes: Scalars['String'];
    formattedEndsAt: Scalars['String'];
    formattedOtherEnrollments: Scalars['String'];
    formattedStartsAt: Scalars['String'];
    formattedTimes: Scalars['String'];
    gradeAtStart?: Maybe<Scalars['String']>;
    groups?: Maybe<Array<Scalars['String']>>;
    id: Scalars['ID'];
    inAgeRange?: Maybe<Scalars['Boolean']>;
    inGradeRange?: Maybe<Scalars['Boolean']>;
    invitationExpired?: Maybe<Scalars['Boolean']>;
    isChildEnrollment: Scalars['Boolean'];
    isInvitation: Scalars['Boolean'];
    isOption: Scalars['Boolean'];
    isOptionParent: Scalars['Boolean'];
    isParentEnrollment: Scalars['Boolean'];
    isScholarshipHold: Scalars['Boolean'];
    lateStart: Scalars['Boolean'];
    listPrice: Scalars['Float'];
    optionParent?: Maybe<Enrollment>;
    otherEnrollments: Array<Enrollment>;
    overlaps?: Maybe<Scalars['Boolean']>;
    parent?: Maybe<GuardedUser>;
    parentEnrollment?: Maybe<Enrollment>;
    parents?: Maybe<Array<GuardedUser>>;
    paymentFailureReason?: Maybe<Scalars['String']>;
    paymentService?: Maybe<PaymentService>;
    paymentStatus: PaymentStatus;
    pendingChange?: Maybe<EnrollmentChange>;
    pendingInstances: Array<Enrollment>;
    perSessionRefunds: Scalars['Boolean'];
    pickup?: Maybe<Scalars['String']>;
    pickupDropoffRequired: Scalars['Boolean'];
    priceConfig?: Maybe<PriceConfig>;
    priceConfigs?: Maybe<Array<PriceConfig>>;
    priceTierUse?: Maybe<PriceTierUse>;
    purchaseDescription?: Maybe<Scalars['String']>;
    purchased: Scalars['Boolean'];
    refunds: Array<Maybe<Refund>>;
    refundsTotal: Scalars['Float'];
    rosterAmount?: Maybe<Scalars['String']>;
    rosterPeriod?: Maybe<Scalars['String']>;
    rosterPeriods?: Maybe<Scalars['String']>;
    season?: Maybe<Season>;
    startDate?: Maybe<Scalars['ISO8601Date']>;
    startsAt: Scalars['ISO8601DateTime'];
    status: EnrollmentStatusEnum;
    student?: Maybe<Student>;
    studentSelectionRequired: Scalars['Boolean'];
    templateId?: Maybe<Scalars['String']>;
    trackingParameters?: Maybe<Scalars['String']>;
    updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
    waitlistKind: WaitlistKind;
    weekday: Scalars['String'];
    withdrawable?: Maybe<Scalars['Boolean']>;
  };

/** Autogenerated return type of RosterItemsSetAdditionalDiscount */
export type RosterItemsSetAdditionalDiscountPayload = {
  enrollments?: Maybe<Array<RosterItem>>;
};

export type RosterResult = {
  items: Array<Maybe<RosterItem>>;
  total: Scalars['Int'];
};

export type RosterSort = {
  by: RosterSortBy;
  direction?: InputMaybe<SortDirectionEnum>;
};

export enum RosterSortBy {
  Added = 'ADDED',
  AmountPaid = 'AMOUNT_PAID',
  AttendanceDate = 'ATTENDANCE_DATE',
  Balance = 'BALANCE',
  BilledAt = 'BILLED_AT',
  BilledDate = 'BILLED_DATE',
  CheckedIn = 'CHECKED_IN',
  CheckedInTime = 'CHECKED_IN_TIME',
  CheckedOut = 'CHECKED_OUT',
  CheckedOutTime = 'CHECKED_OUT_TIME',
  Classroom = 'CLASSROOM',
  Course = 'COURSE',
  Day = 'DAY',
  DiscountAmount = 'DISCOUNT_AMOUNT',
  Dismissal = 'DISMISSAL',
  EndDate = 'END_DATE',
  EnrollmentEndDate = 'ENROLLMENT_END_DATE',
  EnrollmentStartDate = 'ENROLLMENT_START_DATE',
  GracePeriod = 'GRACE_PERIOD',
  Group = 'GROUP',
  Kind = 'KIND',
  Overlaps = 'OVERLAPS',
  Parent = 'PARENT',
  PriceTier = 'PRICE_TIER',
  Room = 'ROOM',
  RoundingIncrement = 'ROUNDING_INCREMENT',
  Season = 'SEASON',
  StartDate = 'START_DATE',
  Student = 'STUDENT',
  StudentFirstName = 'STUDENT_FIRST_NAME',
  StudentLastName = 'STUDENT_LAST_NAME',
  UsageBillingDate = 'USAGE_BILLING_DATE',
  UsageRate = 'USAGE_RATE',
  UsageUnitsBilled = 'USAGE_UNITS_BILLED',
  Weekday = 'WEEKDAY'
}

export type Season = ActiveRecordInterface &
  WithName & {
    approvedCourseKindGroups: Array<CourseKindGroup>;
    cfQuestions?: Maybe<Array<CfQuestion>>;
    completed: Scalars['Boolean'];
    courseKindGroups: Array<CourseKindGroup>;
    courses: Array<Course>;
    coursesBegin: Scalars['ISO8601DateTime'];
    coursesFinish: Scalars['ISO8601DateTime'];
    enrollmentCloses: Scalars['ISO8601DateTime'];
    enrollmentOpens: Scalars['ISO8601DateTime'];
    formattedKind?: Maybe<Scalars['String']>;
    games: Array<Course>;
    hasConfigurablePricing: Scalars['Boolean'];
    hasCourses: Scalars['Boolean'];
    hasFutureCourses: Scalars['Boolean'];
    hasNonSeasonPricing: Scalars['Boolean'];
    holidays: Array<Maybe<Scalars['ISO8601Date']>>;
    homeroomRate?: Maybe<Scalars['Float']>;
    /** The unique identifier of the resource. */
    id: Scalars['ID'];
    kind?: Maybe<SeasonKind>;
    name: Scalars['String'];
    note?: Maybe<Scalars['String']>;
    open: Scalars['Boolean'];
    payoutConfig: SitePayoutConfig;
    private?: Maybe<Scalars['Boolean']>;
    registrationGroup?: Maybe<Scalars['String']>;
    site: Site;
    siteId: Scalars['ID'];
    status: SeasonStatus;
    txnFeeRate?: Maybe<Scalars['Float']>;
    upcoming: Scalars['Boolean'];
  };

/** Autogenerated return type of SeasonImport */
export type SeasonImportPayload = {
  courses?: Maybe<Array<Course>>;
  season?: Maybe<Season>;
};

export type SeasonInput = {
  courseKindGroups?: InputMaybe<Array<CourseKindGroup>>;
  coursesBegin?: InputMaybe<Scalars['ISO8601DateTime']>;
  coursesFinish?: InputMaybe<Scalars['ISO8601DateTime']>;
  enrollmentCloses?: InputMaybe<Scalars['ISO8601DateTime']>;
  enrollmentOpens?: InputMaybe<Scalars['ISO8601DateTime']>;
  holidays?: InputMaybe<Array<InputMaybe<Scalars['ISO8601Date']>>>;
  homeroomRate?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['ID']>;
  kind?: InputMaybe<SeasonKind>;
  name?: InputMaybe<Scalars['String']>;
  note?: InputMaybe<Scalars['String']>;
  payoutConfig?: InputMaybe<SitePayoutConfigInput>;
  private?: InputMaybe<Scalars['Boolean']>;
  registrationGroup?: InputMaybe<Scalars['String']>;
  siteId?: InputMaybe<Scalars['ID']>;
  txnFeeRate?: InputMaybe<Scalars['Float']>;
};

export enum SeasonKind {
  Camp = 'CAMP',
  Enrichment = 'ENRICHMENT',
  ExtendedCare = 'EXTENDED_CARE',
  Online = 'ONLINE'
}

export type SeasonLoadInfo = {
  enrollmentsInFifteen?: Maybe<Scalars['Int']>;
  enrollmentsInFive?: Maybe<Scalars['Int']>;
  enrollmentsInTen?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  purchasedPerOrder?: Maybe<Scalars['Float']>;
  totalCourses?: Maybe<Scalars['Int']>;
  totalEnrollments?: Maybe<Scalars['Int']>;
  totalSpots?: Maybe<Scalars['Int']>;
  waitlistedEnrollments?: Maybe<Scalars['Int']>;
};

export type SeasonPrice = {
  days?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Float']>;
};

export type SeasonPriceBreakdown = {
  days?: Maybe<Scalars['Int']>;
  discountAmount?: Maybe<Scalars['Float']>;
  homeroomCharges?: Maybe<Scalars['Float']>;
  listPrice?: Maybe<Scalars['Float']>;
  listPriceBeforeDiscount?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  priceBeforeDiscount?: Maybe<Scalars['Float']>;
  siteCharges?: Maybe<Scalars['Float']>;
  siteCreditCardFee?: Maybe<Scalars['Float']>;
  siteReceives?: Maybe<Scalars['Float']>;
  vendorCharges?: Maybe<Scalars['Float']>;
  vendorCreditCardFee?: Maybe<Scalars['Float']>;
  vendorReceives?: Maybe<Scalars['Float']>;
};

export type SeasonPriceConfig = PriceConfig & {
  billingDate?: Maybe<Scalars['ISO8601Date']>;
  billingDescription: Scalars['String'];
  deposit?: Maybe<Scalars['Boolean']>;
  depositAmount?: Maybe<Scalars['Float']>;
  installmentDates?: Maybe<Array<Scalars['ISO8601Date']>>;
  kind: PriceConfigKind;
  purchaseDescription?: Maybe<Scalars['String']>;
  template: Scalars['Boolean'];
  updateDescription?: Maybe<Scalars['String']>;
  usingInstallments: Scalars['Boolean'];
  weekdays: Array<Scalars['String']>;
};

export type SeasonPriceConfigInput = {
  weekdays: Array<Scalars['String']>;
};

export type SeasonRate = {
  days?: Maybe<Scalars['Int']>;
  depositAmount?: Maybe<Scalars['Float']>;
  homeroomFeeRoundingIncrementCents?: Maybe<Scalars['Int']>;
  installmentDates?: Maybe<Array<Scalars['ISO8601Date']>>;
  materialsRate?: Maybe<Scalars['Float']>;
  rate?: Maybe<Scalars['Float']>;
};

export type SeasonRateInput = {
  days?: InputMaybe<Scalars['Int']>;
  depositAmount?: InputMaybe<Scalars['Float']>;
  homeroomFeeRoundingIncrementCents?: InputMaybe<Scalars['Int']>;
  installmentDates?: InputMaybe<Array<Scalars['ISO8601Date']>>;
  materialsRate?: InputMaybe<Scalars['Float']>;
  rate?: InputMaybe<Scalars['Float']>;
};

export enum SeasonStatus {
  Active = 'ACTIVE',
  Completed = 'COMPLETED',
  Upcoming = 'UPCOMING'
}

/** Autogenerated return type of SeasonUpsert */
export type SeasonUpsertPayload = {
  season?: Maybe<Season>;
};

/** Autogenerated return type of SeasonsDelete */
export type SeasonsDeletePayload = {
  seasons?: Maybe<Array<Maybe<Season>>>;
};

export type SetAdditionalDiscountInput = {
  course: Scalars['ID'];
  date: Scalars['ISO8601Date'];
  discount?: InputMaybe<DiscountInput>;
  student: Scalars['ID'];
};

/** Autogenerated return type of SetContact */
export type SetContactPayload = {
  entity?: Maybe<WithTeam>;
};

export type SharedUser = ActiveRecordInterface & {
  email: Scalars['String'];
  firstName: Scalars['String'];
  /** The unique identifier of the resource. */
  id: Scalars['ID'];
  lastName: Scalars['String'];
  name?: Maybe<Scalars['String']>;
};

export type SingleTeacherInput = {
  email?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
};

export type Site = ActiveRecordInterface &
  SiteOrCompany &
  WithNameAndId &
  WithTableViews &
  WithTeam & {
    active?: Maybe<Scalars['Boolean']>;
    attendanceQrCode?: Maybe<Scalars['String']>;
    attendanceUrl?: Maybe<Scalars['String']>;
    branding?: Maybe<SiteBranding>;
    charityName?: Maybe<Scalars['String']>;
    checkoutDestinations?: Maybe<Array<Scalars['String']>>;
    city?: Maybe<Scalars['String']>;
    classrooms: Array<Classroom>;
    complianceDefinitions: ComplianceDefinitions;
    complianceReport?: Maybe<Array<ComplianceReportRow>>;
    contact?: Maybe<Contact>;
    contacts: Array<Contact>;
    directoryCourseTags?: Maybe<Array<CourseTag>>;
    discountDefinitions: Array<Maybe<DiscountDefinition>>;
    donationConfig: DonationConfig;
    feeRate: Scalars['Float'];
    feeType: SiteFeeTypeEnum;
    gameSeasons: Array<Season>;
    grades?: Maybe<Array<Grade>>;
    hasMutations: Scalars['Boolean'];
    hasSiteCompanies: Scalars['Boolean'];
    hasTableViews: Scalars['Boolean'];
    hasVendors: Scalars['Boolean'];
    history?: Maybe<Array<HistoryVersion>>;
    homeroomRate?: Maybe<Scalars['Float']>;
    hubspotId?: Maybe<Scalars['String']>;
    /** The unique identifier of the resource. */
    id: Scalars['ID'];
    latestSeason: Season;
    mutationChanges?: Maybe<Array<MutationChange>>;
    mutationChangesQuery?: Maybe<MutationChangesQueryResults>;
    mutations?: Maybe<Array<Mutation>>;
    mutationsQuery?: Maybe<MutationsQueryResults>;
    name?: Maybe<Scalars['String']>;
    organizationType?: Maybe<Organization>;
    parentAttendanceConfig: ParentAttendanceConfig;
    participants?: Maybe<Array<Participant>>;
    participantsQuery?: Maybe<ParticipantsQueryResults>;
    partner: Scalars['Boolean'];
    payoutConfig: SitePayoutConfig;
    payouts?: Maybe<Array<Payout>>;
    pendingRefunds?: Maybe<Array<Refund>>;
    phone?: Maybe<Scalars['String']>;
    pickupDestinations?: Maybe<Array<Scalars['String']>>;
    policy?: Maybe<Policy>;
    priceTiers: Array<Maybe<PriceTierUse>>;
    proration: ProrationKind;
    recentSeasons: Array<Season>;
    registrationGroups?: Maybe<Array<Scalars['String']>>;
    retired?: Maybe<Scalars['Boolean']>;
    searchable?: Maybe<Scalars['Boolean']>;
    seasons: Array<Season>;
    seasonsByStatus: Array<Season>;
    siteCompanies: Array<Company>;
    slug: Scalars['String'];
    state?: Maybe<Scalars['String']>;
    streetAddress?: Maybe<Scalars['String']>;
    stripeAccount?: Maybe<Scalars['String']>;
    stripeAccountType?: Maybe<Scalars['String']>;
    stripeAccountVerified?: Maybe<Scalars['Boolean']>;
    stripeBalance?: Maybe<Scalars['Float']>;
    stripeConnectUrl?: Maybe<Scalars['String']>;
    stripeDashboardUrl?: Maybe<Scalars['String']>;
    studentAssignableClassrooms: Array<Classroom>;
    tableViews?: Maybe<Array<TableView>>;
    tableViewsQuery?: Maybe<TableViewsQueryResults>;
    tableViewsUpsert?: Maybe<Scalars['Boolean']>;
    tableViewsUpsertPersonal?: Maybe<Scalars['Boolean']>;
    teamAdmins?: Maybe<Array<Member>>;
    teamAdminsQuery?: Maybe<TeamAdminsQueryResults>;
    teamChangeRole?: Maybe<Scalars['Boolean']>;
    teamInvites?: Maybe<Array<MemberInvitation>>;
    teamMembers?: Maybe<Array<Member>>;
    teamMembersQuery?: Maybe<TeamMembersQueryResults>;
    teamParticipantsUpsert?: Maybe<Scalars['Boolean']>;
    teamSetAsContact?: Maybe<Scalars['Boolean']>;
    timeEntryQrCode?: Maybe<Scalars['String']>;
    timeEntryUrl?: Maybe<Scalars['String']>;
    timezone?: Maybe<Scalars['String']>;
    timezoneDisplay?: Maybe<Scalars['String']>;
    txnFeeRate?: Maybe<Scalars['Float']>;
    userCompanyRole?: Maybe<MemberRole>;
    userCourseRole?: Maybe<MemberRole>;
    userFamilyRole?: Maybe<MemberRole>;
    userMemberRole?: Maybe<MemberRole>;
    userSiteRole?: Maybe<MemberRole>;
    usingAch: Scalars['Boolean'];
    usingCompliance: Scalars['Boolean'];
    usingNewRegistration: Scalars['Boolean'];
    usingParentAttendance: Scalars['Boolean'];
    usingPickups: Scalars['Boolean'];
    usingPriceTiers: Scalars['Boolean'];
    usingTrackingParameters?: Maybe<Scalars['Boolean']>;
    vendors: Array<Vendor>;
    vendorsQuery: VendorsQueryResults;
    waivers?: Maybe<Array<Maybe<FileUpload>>>;
    zipcode?: Maybe<Scalars['String']>;
  };

export type SiteMutationChangesArgs = {
  advancedFilter?: InputMaybe<Scalars['JSON']>;
  filters?: InputMaybe<Array<Filter>>;
  groups?: InputMaybe<Array<Scalars['String']>>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  queries?: InputMaybe<Array<Scalars['JSON']>>;
  sorts?: InputMaybe<Array<Sort>>;
};

export type SiteMutationChangesQueryArgs = {
  advancedFilter?: InputMaybe<Scalars['JSON']>;
  filterOptions?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<Filter>>;
  groups?: InputMaybe<Array<Scalars['String']>>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  queries?: InputMaybe<Array<Scalars['JSON']>>;
  sorts?: InputMaybe<Array<Sort>>;
  totals?: InputMaybe<Array<Total>>;
};

export type SiteMutationsArgs = {
  advancedFilter?: InputMaybe<Scalars['JSON']>;
  filters?: InputMaybe<Array<Filter>>;
  groups?: InputMaybe<Array<Scalars['String']>>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  queries?: InputMaybe<Array<Scalars['JSON']>>;
  sorts?: InputMaybe<Array<Sort>>;
};

export type SiteMutationsQueryArgs = {
  advancedFilter?: InputMaybe<Scalars['JSON']>;
  filterOptions?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<Filter>>;
  groups?: InputMaybe<Array<Scalars['String']>>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  queries?: InputMaybe<Array<Scalars['JSON']>>;
  sorts?: InputMaybe<Array<Sort>>;
  totals?: InputMaybe<Array<Total>>;
};

export type SiteParticipantsArgs = {
  advancedFilter?: InputMaybe<Scalars['JSON']>;
  filters?: InputMaybe<Array<Filter>>;
  groups?: InputMaybe<Array<Scalars['String']>>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  queries?: InputMaybe<Array<Scalars['JSON']>>;
  sorts?: InputMaybe<Array<Sort>>;
};

export type SiteParticipantsQueryArgs = {
  advancedFilter?: InputMaybe<Scalars['JSON']>;
  filterOptions?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<Filter>>;
  groups?: InputMaybe<Array<Scalars['String']>>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  queries?: InputMaybe<Array<Scalars['JSON']>>;
  sorts?: InputMaybe<Array<Sort>>;
  totals?: InputMaybe<Array<Total>>;
};

export type SiteRecentSeasonsArgs = {
  private?: InputMaybe<Scalars['Boolean']>;
};

export type SiteRegistrationGroupsArgs = {
  kind?: InputMaybe<SeasonKind>;
  kindGroups?: InputMaybe<Array<CourseKindGroup>>;
};

export type SiteTableViewsArgs = {
  advancedFilter?: InputMaybe<Scalars['JSON']>;
  filters?: InputMaybe<Array<Filter>>;
  groups?: InputMaybe<Array<Scalars['String']>>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  queries?: InputMaybe<Array<Scalars['JSON']>>;
  sorts?: InputMaybe<Array<Sort>>;
};

export type SiteTableViewsQueryArgs = {
  advancedFilter?: InputMaybe<Scalars['JSON']>;
  filterOptions?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<Filter>>;
  groups?: InputMaybe<Array<Scalars['String']>>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  queries?: InputMaybe<Array<Scalars['JSON']>>;
  sorts?: InputMaybe<Array<Sort>>;
  totals?: InputMaybe<Array<Total>>;
};

export type SiteTeamAdminsArgs = {
  advancedFilter?: InputMaybe<Scalars['JSON']>;
  filters?: InputMaybe<Array<Filter>>;
  groups?: InputMaybe<Array<Scalars['String']>>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  queries?: InputMaybe<Array<Scalars['JSON']>>;
  sorts?: InputMaybe<Array<Sort>>;
};

export type SiteTeamAdminsQueryArgs = {
  advancedFilter?: InputMaybe<Scalars['JSON']>;
  filterOptions?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<Filter>>;
  groups?: InputMaybe<Array<Scalars['String']>>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  queries?: InputMaybe<Array<Scalars['JSON']>>;
  sorts?: InputMaybe<Array<Sort>>;
  totals?: InputMaybe<Array<Total>>;
};

export type SiteTeamMembersArgs = {
  advancedFilter?: InputMaybe<Scalars['JSON']>;
  filters?: InputMaybe<Array<Filter>>;
  groups?: InputMaybe<Array<Scalars['String']>>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  queries?: InputMaybe<Array<Scalars['JSON']>>;
  sorts?: InputMaybe<Array<Sort>>;
};

export type SiteTeamMembersQueryArgs = {
  advancedFilter?: InputMaybe<Scalars['JSON']>;
  filterOptions?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<Filter>>;
  groups?: InputMaybe<Array<Scalars['String']>>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  queries?: InputMaybe<Array<Scalars['JSON']>>;
  sorts?: InputMaybe<Array<Sort>>;
  totals?: InputMaybe<Array<Total>>;
};

export type SiteVendorsArgs = {
  advancedFilter?: InputMaybe<Scalars['JSON']>;
  filters?: InputMaybe<Array<Filter>>;
  groups?: InputMaybe<Array<Scalars['String']>>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  queries?: InputMaybe<Array<Scalars['JSON']>>;
  sorts?: InputMaybe<Array<Sort>>;
};

export type SiteVendorsQueryArgs = {
  advancedFilter?: InputMaybe<Scalars['JSON']>;
  filterOptions?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<Filter>>;
  groups?: InputMaybe<Array<Scalars['String']>>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  queries?: InputMaybe<Array<Scalars['JSON']>>;
  sorts?: InputMaybe<Array<Sort>>;
  totals?: InputMaybe<Array<Total>>;
};

export type SiteAddressInput = {
  phone?: InputMaybe<Scalars['String']>;
  streetAddress?: InputMaybe<Scalars['String']>;
  zipcode?: InputMaybe<Scalars['String']>;
};

export type SiteBranding = ActiveRecordInterface & {
  /** The unique identifier of the resource. */
  id: Scalars['ID'];
  logo?: Maybe<Scalars['String']>;
};

export type SiteBrandingInput = {
  logo?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of SiteCreateDemo */
export type SiteCreateDemoPayload = {
  site?: Maybe<Site>;
  user?: Maybe<User>;
};

/** Autogenerated return type of SiteCreate */
export type SiteCreatePayload = {
  site?: Maybe<Site>;
};

/** Autogenerated return type of SiteDelete */
export type SiteDeletePayload = {
  success?: Maybe<Scalars['Boolean']>;
};

export type SiteDirectoryResult = {
  distance?: Maybe<Scalars['Int']>;
  site: Site;
};

export type SiteDirectoryResults = {
  sites: Array<SiteDirectoryResult>;
  total: Scalars['Int'];
};

export type SiteFeeConfigInput = {
  feeRate?: InputMaybe<Scalars['Float']>;
  feeType: SiteFeeTypeEnum;
};

export enum SiteFeeTypeEnum {
  AmountPerEnrollment = 'AMOUNT_PER_ENROLLMENT',
  NoFee = 'NO_FEE',
  PercentagePerEnrollment = 'PERCENTAGE_PER_ENROLLMENT'
}

export type SiteInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  checkoutDestinations?: InputMaybe<Array<Scalars['String']>>;
  city?: InputMaybe<Scalars['String']>;
  classroomsAttributes?: InputMaybe<Array<ClassroomInput>>;
  homeroomRate?: InputMaybe<Scalars['Float']>;
  hubspotId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organizationType?: InputMaybe<Organization>;
  partner?: InputMaybe<Scalars['Boolean']>;
  pickupDestinations?: InputMaybe<Array<Scalars['String']>>;
  proration?: InputMaybe<ProrationKind>;
  retired?: InputMaybe<Scalars['Boolean']>;
  searchable?: InputMaybe<Scalars['Boolean']>;
  state?: InputMaybe<Scalars['String']>;
  streetAddress?: InputMaybe<Scalars['String']>;
  txnFeeRate?: InputMaybe<Scalars['Float']>;
  usingAch?: InputMaybe<Scalars['Boolean']>;
  zipcode?: InputMaybe<Scalars['String']>;
};

export type SiteOrCompany = {
  hasMutations: Scalars['Boolean'];
  history?: Maybe<Array<HistoryVersion>>;
  id: Scalars['ID'];
  mutationChanges?: Maybe<Array<MutationChange>>;
  mutationChangesQuery?: Maybe<MutationChangesQueryResults>;
  mutations?: Maybe<Array<Mutation>>;
  mutationsQuery?: Maybe<MutationsQueryResults>;
  name?: Maybe<Scalars['String']>;
  stripeAccount?: Maybe<Scalars['String']>;
  stripeBalance?: Maybe<Scalars['Float']>;
};

export type SiteOrCompanyMutationChangesArgs = {
  advancedFilter?: InputMaybe<Scalars['JSON']>;
  filters?: InputMaybe<Array<Filter>>;
  groups?: InputMaybe<Array<Scalars['String']>>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  queries?: InputMaybe<Array<Scalars['JSON']>>;
  sorts?: InputMaybe<Array<Sort>>;
};

export type SiteOrCompanyMutationChangesQueryArgs = {
  advancedFilter?: InputMaybe<Scalars['JSON']>;
  filterOptions?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<Filter>>;
  groups?: InputMaybe<Array<Scalars['String']>>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  queries?: InputMaybe<Array<Scalars['JSON']>>;
  sorts?: InputMaybe<Array<Sort>>;
  totals?: InputMaybe<Array<Total>>;
};

export type SiteOrCompanyMutationsArgs = {
  advancedFilter?: InputMaybe<Scalars['JSON']>;
  filters?: InputMaybe<Array<Filter>>;
  groups?: InputMaybe<Array<Scalars['String']>>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  queries?: InputMaybe<Array<Scalars['JSON']>>;
  sorts?: InputMaybe<Array<Sort>>;
};

export type SiteOrCompanyMutationsQueryArgs = {
  advancedFilter?: InputMaybe<Scalars['JSON']>;
  filterOptions?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<Filter>>;
  groups?: InputMaybe<Array<Scalars['String']>>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  queries?: InputMaybe<Array<Scalars['JSON']>>;
  sorts?: InputMaybe<Array<Sort>>;
  totals?: InputMaybe<Array<Total>>;
};

export type SiteParentAttendanceConfigInput = {
  enabled: Scalars['Boolean'];
  notify: Scalars['Boolean'];
};

export type SitePayoutConfig = ActiveRecordInterface & {
  autoSiteCompanyPayouts?: Maybe<Scalars['Boolean']>;
  /** The unique identifier of the resource. */
  id: Scalars['ID'];
  pause?: Maybe<Scalars['Boolean']>;
};

export type SitePayoutConfigInput = {
  autoSiteCompanyPayouts?: InputMaybe<Scalars['Boolean']>;
  pause?: InputMaybe<Scalars['Boolean']>;
};

/** Autogenerated return type of SiteUpdateAdminFee */
export type SiteUpdateAdminFeePayload = {
  site?: Maybe<Site>;
};

/** Autogenerated return type of SiteUpdateDismissals */
export type SiteUpdateDismissalsPayload = {
  site?: Maybe<Site>;
};

/** Autogenerated return type of SiteUpdateDonationConfig */
export type SiteUpdateDonationConfigPayload = {
  site?: Maybe<Site>;
};

/** Autogenerated return type of SiteUpdateGeneralInfo */
export type SiteUpdateGeneralInfoPayload = {
  site?: Maybe<Site>;
};

/** Autogenerated return type of SiteUpdateGrades */
export type SiteUpdateGradesPayload = {
  site?: Maybe<Site>;
};

/** Autogenerated return type of SiteUpdateParentAttendance */
export type SiteUpdateParentAttendancePayload = {
  site?: Maybe<Site>;
};

/** Autogenerated return type of SiteUpdate */
export type SiteUpdatePayload = {
  site?: Maybe<Site>;
};

/** Autogenerated return type of SiteUpdatePickups */
export type SiteUpdatePickupsPayload = {
  site?: Maybe<Site>;
};

/** Autogenerated return type of SiteUpdatePolicies */
export type SiteUpdatePoliciesPayload = {
  policy?: Maybe<Policy>;
};

/** Autogenerated return type of SiteUpdateWaivers */
export type SiteUpdateWaiversPayload = {
  waivers?: Maybe<Array<Maybe<FileUpload>>>;
};

/** Autogenerated return type of SiteUpsertClassrooms */
export type SiteUpsertClassroomsPayload = {
  site?: Maybe<Site>;
};

/** Autogenerated return type of SiteUpsertComplianceDefinitions */
export type SiteUpsertComplianceDefinitionsPayload = {
  site?: Maybe<Site>;
};

/** Autogenerated return type of SiteUpsertContacts */
export type SiteUpsertContactsPayload = {
  site?: Maybe<Site>;
};

/** Autogenerated return type of SiteUpsertPriceTiers */
export type SiteUpsertPriceTiersPayload = {
  priceTiers?: Maybe<Array<PriceTier>>;
};

export type SitesQueryResults = CollectionInfo & {
  count?: Maybe<Scalars['Int']>;
  filterOptions?: Maybe<Array<Maybe<Option>>>;
  /** The unique identifier of the resource. */
  id: Scalars['ID'];
  items?: Maybe<Array<Site>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  pages?: Maybe<Scalars['Int']>;
  totals?: Maybe<Array<Scalars['Int']>>;
};

export type Sort = {
  direction?: InputMaybe<SortDirection>;
  name: Scalars['String'];
};

export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum SortDirectionEnum {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type Student = ActiveRecordInterface & {
  age?: Maybe<Scalars['Int']>;
  authorizedPickupContacts: Array<Maybe<Contact>>;
  automaticGradeUpdate: Scalars['Boolean'];
  cfAnswers?: Maybe<Array<Maybe<CfAnswer>>>;
  cfAnswersCarted?: Maybe<Array<Maybe<CfAnswer>>>;
  cfAnswersConfirmed?: Maybe<Array<Maybe<CfAnswer>>>;
  cfQuestions?: Maybe<Array<Maybe<CfQuestion>>>;
  cfQuestionsCarted?: Maybe<Array<Maybe<CfQuestion>>>;
  cfQuestionsConfirmed?: Maybe<Array<Maybe<CfQuestion>>>;
  classroom?: Maybe<Classroom>;
  contacts: Array<Contact>;
  dob?: Maybe<Scalars['ISO8601Date']>;
  emergencyContacts: Array<Contact>;
  enrollments?: Maybe<Array<Maybe<Enrollment>>>;
  enrollmentsQuery?: Maybe<EnrollmentsQueryResults>;
  family?: Maybe<Family>;
  firstName: Scalars['String'];
  firstNameWithNickname: Scalars['String'];
  formattedAllergies: Scalars['String'];
  formattedAuthorizedPickupContacts: Scalars['String'];
  formattedContacts: Scalars['String'];
  formattedEmergencyContacts: Scalars['String'];
  formattedEpiPen: Scalars['String'];
  formattedMedicalAndNotes: Scalars['String'];
  formattedMedications: Scalars['String'];
  grade: Scalars['String'];
  /** The unique identifier of the resource. */
  id: Scalars['ID'];
  lastName: Scalars['String'];
  logEntries?: Maybe<Array<StudentLogEntry>>;
  medical?: Maybe<StudentMedical>;
  name: Scalars['String'];
  nameWithNickname: Scalars['String'];
  nickname?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  parents: Array<GuardedUser>;
  refunds: Array<Maybe<Refund>>;
  site?: Maybe<Site>;
  siteGradeLabel: Scalars['String'];
  upToDate?: Maybe<Scalars['Boolean']>;
};

export type StudentEnrollmentsArgs = {
  advancedFilter?: InputMaybe<Scalars['JSON']>;
  filters?: InputMaybe<Array<Filter>>;
  groups?: InputMaybe<Array<Scalars['String']>>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  queries?: InputMaybe<Array<Scalars['JSON']>>;
  sorts?: InputMaybe<Array<Sort>>;
};

export type StudentEnrollmentsQueryArgs = {
  advancedFilter?: InputMaybe<Scalars['JSON']>;
  filterOptions?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<Filter>>;
  groups?: InputMaybe<Array<Scalars['String']>>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  queries?: InputMaybe<Array<Scalars['JSON']>>;
  sorts?: InputMaybe<Array<Sort>>;
  totals?: InputMaybe<Array<Total>>;
};

/** Autogenerated return type of StudentConfirmYearlyUpdate */
export type StudentConfirmYearlyUpdatePayload = {
  students?: Maybe<Array<Student>>;
};

/** Autogenerated return type of StudentCopyContacts */
export type StudentCopyContactsPayload = {
  student?: Maybe<Student>;
};

export type StudentInput = {
  allergies?: InputMaybe<Scalars['String']>;
  automaticGradeUpdate?: InputMaybe<Scalars['Boolean']>;
  classroomId?: InputMaybe<Scalars['ID']>;
  dob?: InputMaybe<Scalars['ISO8601Date']>;
  family?: InputMaybe<Scalars['ID']>;
  firstName: Scalars['String'];
  grade?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  lastName: Scalars['String'];
  medical?: InputMaybe<StudentMedicalInput>;
  nickname?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
};

export type StudentLogEntry = ActiveRecordInterface & {
  createdBy?: Maybe<SharedUser>;
  date?: Maybe<Scalars['ISO8601DateTime']>;
  /** The unique identifier of the resource. */
  id: Scalars['ID'];
  kind?: Maybe<StudentLogEntryKind>;
  notes?: Maybe<Scalars['String']>;
};

export type StudentLogEntryInput = {
  date: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  kind: StudentLogEntryKind;
  notes: Scalars['String'];
};

export enum StudentLogEntryKind {
  Medical = 'medical',
  Other = 'other'
}

export type StudentMedical = ActiveRecordInterface & {
  allergies?: Maybe<Array<Allergy>>;
  conditions?: Maybe<Scalars['String']>;
  epiPen?: Maybe<Scalars['Boolean']>;
  /** The unique identifier of the resource. */
  id: Scalars['ID'];
  medications?: Maybe<Array<Medication>>;
};

export type StudentMedicalInput = {
  allergies?: InputMaybe<Array<AllergyInput>>;
  conditions?: InputMaybe<Scalars['String']>;
  epiPen?: InputMaybe<Scalars['Boolean']>;
  medications?: InputMaybe<Array<MedicationInput>>;
};

/** Autogenerated return type of StudentRemoveLogEntry */
export type StudentRemoveLogEntryPayload = {
  success?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of StudentUpdate */
export type StudentUpdatePayload = {
  student?: Maybe<Student>;
};

/** Autogenerated return type of StudentUpsertLogEntry */
export type StudentUpsertLogEntryPayload = {
  success?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of StudentUpsert */
export type StudentUpsertPayload = {
  student?: Maybe<Student>;
};

/** Autogenerated return type of StudentsMerge */
export type StudentsMergePayload = {
  student?: Maybe<Student>;
};

export type StudentsQueryResults = CollectionInfo & {
  count?: Maybe<Scalars['Int']>;
  filterOptions?: Maybe<Array<Maybe<Option>>>;
  /** The unique identifier of the resource. */
  id: Scalars['ID'];
  items?: Maybe<Array<Student>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  pages?: Maybe<Scalars['Int']>;
  totals?: Maybe<Array<Scalars['Int']>>;
};

export type TableView = ActiveRecordInterface & {
  advancedFilter?: Maybe<Scalars['JSON']>;
  cols: Array<TableViewColumn>;
  filters?: Maybe<Array<TableViewFilter>>;
  groups?: Maybe<Array<TableViewGroup>>;
  /** The unique identifier of the resource. */
  id: Scalars['ID'];
  lockedCol?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  sorts?: Maybe<Array<TableViewSort>>;
  table: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
  visibility?: Maybe<TableViewVisibility>;
};

export type TableViewColumn = {
  name: Scalars['String'];
  width?: Maybe<Scalars['Float']>;
};

export type TableViewColumnInput = {
  name: Scalars['String'];
  width?: InputMaybe<Scalars['Float']>;
};

export type TableViewFilter = {
  name: Scalars['String'];
  op?: Maybe<FilterOperator>;
  value: Array<Scalars['OptionValue']>;
};

export type TableViewGroup = {
  direction?: Maybe<SortDirection>;
  name: Scalars['String'];
};

export type TableViewGroupInput = {
  direction?: InputMaybe<SortDirection>;
  name: Scalars['String'];
};

export type TableViewInput = {
  cols: Array<TableViewColumnInput>;
  filters?: InputMaybe<Array<Filter>>;
  groups?: InputMaybe<Array<TableViewGroupInput>>;
  id: Scalars['ID'];
  lockedCol?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  sorts?: InputMaybe<Array<Sort>>;
  table: Scalars['String'];
  visibility?: InputMaybe<TableViewVisibility>;
};

/** Autogenerated return type of TableViewRemove */
export type TableViewRemovePayload = {
  entity?: Maybe<WithTableViews>;
};

export type TableViewSort = {
  direction?: Maybe<SortDirection>;
  name: Scalars['String'];
};

/** Autogenerated return type of TableViewUpsert */
export type TableViewUpsertPayload = {
  entity?: Maybe<WithTableViews>;
};

export enum TableViewVisibility {
  Personal = 'personal',
  Teacher = 'teacher',
  Team = 'team'
}

export type TableViewsQueryResults = CollectionInfo & {
  count?: Maybe<Scalars['Int']>;
  filterOptions?: Maybe<Array<Maybe<Option>>>;
  /** The unique identifier of the resource. */
  id: Scalars['ID'];
  items?: Maybe<Array<TableView>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  pages?: Maybe<Scalars['Int']>;
  totals?: Maybe<Array<Scalars['Int']>>;
};

export type Teacher = ActiveRecordInterface & {
  email?: Maybe<Scalars['String']>;
  /** The unique identifier of the resource. */
  id: Scalars['ID'];
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
};

export type TeacherInput = {
  archived?: InputMaybe<Scalars['Boolean']>;
  companyId?: InputMaybe<Scalars['ID']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
};

export type TeachersQueryResults = CollectionInfo & {
  count?: Maybe<Scalars['Int']>;
  filterOptions?: Maybe<Array<Maybe<Option>>>;
  /** The unique identifier of the resource. */
  id: Scalars['ID'];
  items?: Maybe<Array<Teacher>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  pages?: Maybe<Scalars['Int']>;
  totals?: Maybe<Array<Scalars['Int']>>;
};

export type TeamAdminsQueryResults = CollectionInfo & {
  count?: Maybe<Scalars['Int']>;
  filterOptions?: Maybe<Array<Maybe<Option>>>;
  /** The unique identifier of the resource. */
  id: Scalars['ID'];
  items?: Maybe<Array<Member>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  pages?: Maybe<Scalars['Int']>;
  totals?: Maybe<Array<Scalars['Int']>>;
};

/** Autogenerated return type of TeamInviteResend */
export type TeamInviteResendPayload = {
  entity?: Maybe<WithTeam>;
};

export type TeamMembersQueryResults = CollectionInfo & {
  count?: Maybe<Scalars['Int']>;
  filterOptions?: Maybe<Array<Maybe<Option>>>;
  /** The unique identifier of the resource. */
  id: Scalars['ID'];
  items?: Maybe<Array<Member>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  pages?: Maybe<Scalars['Int']>;
  totals?: Maybe<Array<Scalars['Int']>>;
};

/** Autogenerated return type of TeamSetAsContact */
export type TeamSetAsContactPayload = {
  entity?: Maybe<WithTeam>;
};

export type TimeEntriesQueryResults = CollectionInfo & {
  count?: Maybe<Scalars['Int']>;
  filterOptions?: Maybe<Array<Maybe<Option>>>;
  /** The unique identifier of the resource. */
  id: Scalars['ID'];
  items?: Maybe<Array<TimeEntry>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  pages?: Maybe<Scalars['Int']>;
  totals?: Maybe<Array<Scalars['Int']>>;
};

/** Autogenerated return type of TimeEntriesUpdate */
export type TimeEntriesUpdatePayload = {
  entries?: Maybe<Array<TimeEntry>>;
};

export type TimeEntry = ActiveRecordInterface & {
  checkedIn?: Maybe<Scalars['ISO8601DateTime']>;
  checkedOut?: Maybe<Scalars['ISO8601DateTime']>;
  formattedCheckedInTime?: Maybe<Scalars['String']>;
  formattedCheckedOutTime?: Maybe<Scalars['String']>;
  formattedDate?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['Float']>;
  /** The unique identifier of the resource. */
  id: Scalars['ID'];
  user?: Maybe<GuardedUser>;
};

/** Autogenerated return type of TimeEntryCheckIn */
export type TimeEntryCheckInPayload = {
  timeEntry?: Maybe<TimeEntry>;
};

/** Autogenerated return type of TimeEntryCheckOut */
export type TimeEntryCheckOutPayload = {
  timeEntry?: Maybe<TimeEntry>;
};

export type TimeEntryInput = {
  checkedIn?: InputMaybe<Scalars['ISO8601DateTime']>;
  checkedOut?: InputMaybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
};

export type Total = {
  name: Scalars['String'];
  op: TotalOperator;
};

export enum TotalOperator {
  Count = 'COUNT',
  Sum = 'SUM'
}

export type UsBankAccount = {
  bankName: Scalars['String'];
  fingerprint: Scalars['ID'];
  last4: Scalars['String'];
  routingNumber: Scalars['String'];
};

export type UsagePrice = {
  price?: Maybe<Scalars['Float']>;
  unit?: Maybe<UsageUnit>;
};

export type UsagePriceBreakdown = {
  discountAmount?: Maybe<Scalars['Float']>;
  homeroomCharges?: Maybe<Scalars['Float']>;
  listPrice?: Maybe<Scalars['Float']>;
  listPriceBeforeDiscount?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  priceBeforeDiscount?: Maybe<Scalars['Float']>;
  siteCharges?: Maybe<Scalars['Float']>;
  siteCreditCardFee?: Maybe<Scalars['Float']>;
  siteReceives?: Maybe<Scalars['Float']>;
  unit?: Maybe<UsageUnit>;
  vendorCharges?: Maybe<Scalars['Float']>;
  vendorCreditCardFee?: Maybe<Scalars['Float']>;
  vendorReceives?: Maybe<Scalars['Float']>;
};

export type UsagePriceConfig = PriceConfig & {
  attendance?: Maybe<Attendance>;
  billingDate?: Maybe<Scalars['ISO8601Date']>;
  billingDescription: Scalars['String'];
  gracePeriod?: Maybe<Scalars['Int']>;
  kind: PriceConfigKind;
  purchaseDescription?: Maybe<Scalars['String']>;
  rate?: Maybe<Scalars['Float']>;
  roundingIncrement?: Maybe<Scalars['Int']>;
  template: Scalars['Boolean'];
  unit: UsageUnit;
  unitsBilled?: Maybe<Scalars['Float']>;
  updateDescription?: Maybe<Scalars['String']>;
};

export type UsageRate = {
  gracePeriod?: Maybe<Scalars['Int']>;
  homeroomFeeRoundingIncrementCents?: Maybe<Scalars['Int']>;
  rate?: Maybe<Scalars['Float']>;
  roundingIncrement?: Maybe<Scalars['Int']>;
  unit?: Maybe<UsageUnit>;
};

export type UsageRateInput = {
  gracePeriod?: InputMaybe<Scalars['Int']>;
  homeroomFeeRoundingIncrementCents?: InputMaybe<Scalars['Int']>;
  rate?: InputMaybe<Scalars['Float']>;
  roundingIncrement?: InputMaybe<Scalars['Int']>;
  unit?: InputMaybe<UsageUnit>;
};

export enum UsageUnit {
  Day = 'DAY',
  Hour = 'HOUR'
}

export type User = ActiveRecordInterface & {
  accessibleCourses?: Maybe<Array<Course>>;
  accessibleCoursesQuery?: Maybe<AccessibleCoursesQueryResults>;
  attendanceCodeCode?: Maybe<Scalars['String']>;
  betaFlags?: Maybe<BetaFlags>;
  cart?: Maybe<Cart>;
  ccContacts?: Maybe<Array<Contact>>;
  companies?: Maybe<Array<Company>>;
  companiesQuery?: Maybe<CompaniesQueryResults>;
  courseKindGroups: Array<CourseKindGroup>;
  deactivated?: Maybe<Scalars['Boolean']>;
  deactivatedAt?: Maybe<Scalars['ISO8601DateTime']>;
  deletedAt?: Maybe<Scalars['ISO8601DateTime']>;
  email: Scalars['String'];
  enrollments?: Maybe<Array<Maybe<Enrollment>>>;
  enrollmentsQuery?: Maybe<EnrollmentsQueryResults>;
  events?: Maybe<Array<Event>>;
  eventsQuery?: Maybe<EventsQueryResults>;
  families?: Maybe<Array<Family>>;
  familiesQuery?: Maybe<FamiliesQueryResults>;
  firstName: Scalars['String'];
  formattedCcContacts?: Maybe<Scalars['String']>;
  hasAccessibleCourses: Scalars['Boolean'];
  /** The unique identifier of the resource. */
  id: Scalars['ID'];
  invites?: Maybe<Array<Invite>>;
  joined?: Maybe<Scalars['ISO8601DateTime']>;
  lastName: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  orders?: Maybe<Array<Order>>;
  personalCalendar?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  receipts?: Maybe<Array<Receipt>>;
  refunds?: Maybe<Array<Refund>>;
  roles?: Maybe<UserRoles>;
  sites?: Maybe<Array<Site>>;
  sitesQuery?: Maybe<SitesQueryResults>;
  students?: Maybe<Array<Student>>;
  tosAccepted?: Maybe<Scalars['Boolean']>;
  uuid?: Maybe<Scalars['String']>;
  vendorInvites?: Maybe<Array<Vendor>>;
};

export type UserAccessibleCoursesArgs = {
  advancedFilter?: InputMaybe<Scalars['JSON']>;
  filters?: InputMaybe<Array<Filter>>;
  groups?: InputMaybe<Array<Scalars['String']>>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  queries?: InputMaybe<Array<Scalars['JSON']>>;
  sorts?: InputMaybe<Array<Sort>>;
};

export type UserAccessibleCoursesQueryArgs = {
  advancedFilter?: InputMaybe<Scalars['JSON']>;
  filterOptions?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<Filter>>;
  groups?: InputMaybe<Array<Scalars['String']>>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  queries?: InputMaybe<Array<Scalars['JSON']>>;
  sorts?: InputMaybe<Array<Sort>>;
  totals?: InputMaybe<Array<Total>>;
};

export type UserCompaniesArgs = {
  advancedFilter?: InputMaybe<Scalars['JSON']>;
  filters?: InputMaybe<Array<Filter>>;
  groups?: InputMaybe<Array<Scalars['String']>>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  queries?: InputMaybe<Array<Scalars['JSON']>>;
  sorts?: InputMaybe<Array<Sort>>;
};

export type UserCompaniesQueryArgs = {
  advancedFilter?: InputMaybe<Scalars['JSON']>;
  filterOptions?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<Filter>>;
  groups?: InputMaybe<Array<Scalars['String']>>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  queries?: InputMaybe<Array<Scalars['JSON']>>;
  sorts?: InputMaybe<Array<Sort>>;
  totals?: InputMaybe<Array<Total>>;
};

export type UserEnrollmentsArgs = {
  advancedFilter?: InputMaybe<Scalars['JSON']>;
  filters?: InputMaybe<Array<Filter>>;
  groups?: InputMaybe<Array<Scalars['String']>>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  queries?: InputMaybe<Array<Scalars['JSON']>>;
  sorts?: InputMaybe<Array<Sort>>;
};

export type UserEnrollmentsQueryArgs = {
  advancedFilter?: InputMaybe<Scalars['JSON']>;
  filterOptions?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<Filter>>;
  groups?: InputMaybe<Array<Scalars['String']>>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  queries?: InputMaybe<Array<Scalars['JSON']>>;
  sorts?: InputMaybe<Array<Sort>>;
  totals?: InputMaybe<Array<Total>>;
};

export type UserEventsArgs = {
  advancedFilter?: InputMaybe<Scalars['JSON']>;
  filters?: InputMaybe<Array<Filter>>;
  groups?: InputMaybe<Array<Scalars['String']>>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  queries?: InputMaybe<Array<Scalars['JSON']>>;
  sorts?: InputMaybe<Array<Sort>>;
};

export type UserEventsQueryArgs = {
  advancedFilter?: InputMaybe<Scalars['JSON']>;
  filterOptions?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<Filter>>;
  groups?: InputMaybe<Array<Scalars['String']>>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  queries?: InputMaybe<Array<Scalars['JSON']>>;
  sorts?: InputMaybe<Array<Sort>>;
  totals?: InputMaybe<Array<Total>>;
};

export type UserFamiliesArgs = {
  advancedFilter?: InputMaybe<Scalars['JSON']>;
  filters?: InputMaybe<Array<Filter>>;
  groups?: InputMaybe<Array<Scalars['String']>>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  queries?: InputMaybe<Array<Scalars['JSON']>>;
  sorts?: InputMaybe<Array<Sort>>;
};

export type UserFamiliesQueryArgs = {
  advancedFilter?: InputMaybe<Scalars['JSON']>;
  filterOptions?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<Filter>>;
  groups?: InputMaybe<Array<Scalars['String']>>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  queries?: InputMaybe<Array<Scalars['JSON']>>;
  sorts?: InputMaybe<Array<Sort>>;
  totals?: InputMaybe<Array<Total>>;
};

export type UserRolesArgs = {
  company?: InputMaybe<Scalars['ID']>;
  course?: InputMaybe<Scalars['ID']>;
  family?: InputMaybe<Scalars['ID']>;
  site?: InputMaybe<Scalars['ID']>;
};

export type UserSitesArgs = {
  advancedFilter?: InputMaybe<Scalars['JSON']>;
  filters?: InputMaybe<Array<Filter>>;
  groups?: InputMaybe<Array<Scalars['String']>>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  queries?: InputMaybe<Array<Scalars['JSON']>>;
  sorts?: InputMaybe<Array<Sort>>;
};

export type UserSitesQueryArgs = {
  advancedFilter?: InputMaybe<Scalars['JSON']>;
  filterOptions?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<Filter>>;
  groups?: InputMaybe<Array<Scalars['String']>>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  queries?: InputMaybe<Array<Scalars['JSON']>>;
  sorts?: InputMaybe<Array<Sort>>;
  totals?: InputMaybe<Array<Total>>;
};

/** Autogenerated return type of UserAcceptTermsOfService */
export type UserAcceptTermsOfServicePayload = {
  success?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of UserAddRole */
export type UserAddRolePayload = {
  user?: Maybe<User>;
};

export type UserCurrentTimeEntriesQueryResults = CollectionInfo & {
  count?: Maybe<Scalars['Int']>;
  filterOptions?: Maybe<Array<Maybe<Option>>>;
  /** The unique identifier of the resource. */
  id: Scalars['ID'];
  items?: Maybe<Array<TimeEntry>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  pages?: Maybe<Scalars['Int']>;
  totals?: Maybe<Array<Scalars['Int']>>;
};

export type UserInput = {
  betaFlags?: InputMaybe<BetaFlagsInput>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
};

export enum UserRole {
  Organizer = 'organizer',
  Parent = 'parent',
  Vendor = 'vendor'
}

export type UserRoles = {
  admin: Scalars['Boolean'];
  company?: Maybe<Company>;
  course?: Maybe<CourseRole>;
  family?: Maybe<Family>;
  familyRole?: Maybe<FamilyRole>;
  organizer?: Maybe<OrganizerRole>;
  parent: Scalars['Boolean'];
  provider?: Maybe<CompanyRole>;
  site?: Maybe<Site>;
};

/** Autogenerated return type of UserUpdate */
export type UserUpdatePayload = {
  user?: Maybe<User>;
};

export type Vendor = ComplianceTarget & {
  accepted: Scalars['Boolean'];
  cancellationPolicy?: Maybe<VendorGuideline>;
  company?: Maybe<Company>;
  companyName: Scalars['String'];
  complianceRequirements: Array<Maybe<ComplianceRequirement>>;
  contacts: Array<Contact>;
  courseCancellationPolicy?: Maybe<Scalars['String']>;
  courses?: Maybe<Array<Maybe<Course>>>;
  displayName: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  inCompliance: Scalars['Boolean'];
  name: Scalars['String'];
  nickName: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  showProviderCompliance: Scalars['Boolean'];
  showTeacherCompliance: Scalars['Boolean'];
  site: Site;
  siteCompany: Scalars['Boolean'];
  teachers?: Maybe<Array<Maybe<Teacher>>>;
  token: Scalars['String'];
  userIsMember: Scalars['Boolean'];
  vendorInCompliance: Scalars['Boolean'];
  vendorTeachers?: Maybe<Array<Maybe<VendorTeacher>>>;
};

/** Autogenerated return type of VendorCreate */
export type VendorCreatePayload = {
  vendor?: Maybe<Vendor>;
};

export type VendorGuideline = ActiveRecordInterface & {
  /** The unique identifier of the resource. */
  id: Scalars['ID'];
  text: Scalars['String'];
};

export type VendorInput = {
  companyName: Scalars['String'];
  contacts: Array<ContactInput>;
};

/** Autogenerated return type of VendorMakeSiteCompany */
export type VendorMakeSiteCompanyPayload = {
  vendor?: Maybe<Vendor>;
};

export type VendorTeacher = ComplianceTarget & {
  id: Scalars['ID'];
  inCompliance?: Maybe<Scalars['Boolean']>;
  teacher?: Maybe<Teacher>;
};

/** Autogenerated return type of VendorUpdateCompliance */
export type VendorUpdateCompliancePayload = {
  requirement?: Maybe<ComplianceRequirement>;
};

/** Autogenerated return type of VendorUpdate */
export type VendorUpdatePayload = {
  vendor?: Maybe<Vendor>;
};

/** Autogenerated return type of VendorsDelete */
export type VendorsDeletePayload = {
  vendors?: Maybe<Array<Maybe<Vendor>>>;
};

export type VendorsQueryResults = CollectionInfo & {
  count?: Maybe<Scalars['Int']>;
  filterOptions?: Maybe<Array<Maybe<Option>>>;
  /** The unique identifier of the resource. */
  id: Scalars['ID'];
  items?: Maybe<Array<Vendor>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  pages?: Maybe<Scalars['Int']>;
  totals?: Maybe<Array<Scalars['Int']>>;
};

export enum WaitlistKind {
  Carted = 'CARTED',
  Finalized = 'FINALIZED',
  None = 'NONE',
  Promoted = 'PROMOTED'
}

export type WithName = {
  name?: Maybe<Scalars['String']>;
};

export type WithNameAndId = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type WithRoles = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  userCompanyRole?: Maybe<MemberRole>;
  userCourseRole?: Maybe<MemberRole>;
  userFamilyRole?: Maybe<MemberRole>;
  userSiteRole?: Maybe<MemberRole>;
};

export type WithTableViews = {
  hasTableViews: Scalars['Boolean'];
  tableViews?: Maybe<Array<TableView>>;
  tableViewsQuery?: Maybe<TableViewsQueryResults>;
  tableViewsUpsert?: Maybe<Scalars['Boolean']>;
  tableViewsUpsertPersonal?: Maybe<Scalars['Boolean']>;
};

export type WithTableViewsTableViewsArgs = {
  advancedFilter?: InputMaybe<Scalars['JSON']>;
  filters?: InputMaybe<Array<Filter>>;
  groups?: InputMaybe<Array<Scalars['String']>>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  queries?: InputMaybe<Array<Scalars['JSON']>>;
  sorts?: InputMaybe<Array<Sort>>;
};

export type WithTableViewsTableViewsQueryArgs = {
  advancedFilter?: InputMaybe<Scalars['JSON']>;
  filterOptions?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<Filter>>;
  groups?: InputMaybe<Array<Scalars['String']>>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  queries?: InputMaybe<Array<Scalars['JSON']>>;
  sorts?: InputMaybe<Array<Sort>>;
  totals?: InputMaybe<Array<Total>>;
};

export type WithTeam = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  participants?: Maybe<Array<Participant>>;
  teamAdmins?: Maybe<Array<Member>>;
  teamChangeRole?: Maybe<Scalars['Boolean']>;
  teamInvites?: Maybe<Array<MemberInvitation>>;
  teamMembers?: Maybe<Array<Member>>;
  teamParticipantsUpsert?: Maybe<Scalars['Boolean']>;
  teamSetAsContact?: Maybe<Scalars['Boolean']>;
  userCompanyRole?: Maybe<MemberRole>;
  userCourseRole?: Maybe<MemberRole>;
  userFamilyRole?: Maybe<MemberRole>;
  userMemberRole?: Maybe<MemberRole>;
  userSiteRole?: Maybe<MemberRole>;
};
