import * as React from 'react';
import { useParams } from 'react-router';
import { isEqual } from 'lodash-es';

import { CourseKind, authorizedPreferences, authorizedCoursePreferenceKeys, CourseUtils } from 'app2/api';
import { Form, HBox, MenuItem, OptionsMenu, Tabs, Tag, useForm, VBox } from 'app2/components';
import { BackLink, CourseStatusTag, CourseDates, courseKindBehavior } from 'app2/views/shared-public';
import { CourseAttendance, CourseDaysDisplay, courseVariantTag, PrivatePage, removePathSegment, StudentModal, Threads } from 'app2/views/shared';

import { approveCourses, CancelCoursesModal, finalizeCourses, sendCourseRequests, openCourses, closeCourses } from '../course';

import { ActivityRouteParams } from './courseRoutes';
import { ActivityInformation } from './information';
import { Enrollments, Enrolled } from './enrollments';
import { TimeSlots, Games } from './child-courses';

import { Reviews } from './reviews';
import { useCourseQuery, CourseSelections } from './generated';

const urlBase = '/activities/manage';

export function Activity() {
  const { course: id } = useParams<ActivityRouteParams>();
  const [courseResult] = useCourseQuery({ variables: { courseId: id } });
  const course = courseResult.data?.course;
  const prevCourse = React.useRef<CourseSelections>();

  const form = useForm<CourseSelections>(undefined, [], {alwaysSave: true});
  const formUi = React.useRef<Form>();

  React.useEffect(() => {
    // for some reason course is refiring while editing, even when saving fails
    // which if we reset during that, clears the form, so only reset when the course changes
    if (isEqual(prevCourse.current, course)) {
      return;
    }

    prevCourse.current = course;
    form.reset({ initialValues: course });
  }, [course]);

  const behavior = courseKindBehavior[course?.kind];
  const behaviorS = course?.behavior;
  const actions = React.useMemo(renderActions, [course?.id]);
  const scheduled = CourseUtils.isOrWasScheduled(course);
  const showAttendance = scheduled && behaviorS?.attendance;

  function render() {
    return <PrivatePage title={course?.name} subtitle={renderSubtitle()} status={renderStatus()} actions={actions} back={renderBack()}>
      {course && 
      <Tabs tabStrip={{borderBottom:'solid', borderWidth:'standard', borderColor:'border'}} pref={authorizedPreferences.coursePreferences(id)} prefName='activityTab' baseUrl={`${urlBase}/${id}/:activityTab`} urlParameter='activityTab' tabs={[
        behaviorS?.info && {label:'Activity information', name: 'info', content: <ActivityInformation containerVars={containerVars()} course={course} form={form} formUi={formUi} />}, 
        scheduled && behaviorS?.enrollments && {label:'Enrollments', name:'enrollments', content: <Enrollments course={course} />},
        renderActivities(),
        scheduled && behaviorS?.billing && {label:'Billing', name:'billing', content: <Enrolled billed course={course} />},
        showAttendance && {label:'Attendance', name:'attendance', content: <CourseAttendance token={course?.token} url={`${urlBase}/${id}/attendance`} prefsKey={authorizedCoursePreferenceKeys.attendanceTable} />},
        behaviorS?.messages && {label:'Messages', name: 'messages', container:{overflow:'hidden'}, content: <Threads role='organizer' labelOrParticipant={{type: 'Course', id: course?.id}} entityMessages={true} onClickStudent={id => <StudentModal id={id} />} />}, 
        behaviorS?.reviews && {label:'Reviews', name: 'reviews', content: <Reviews course={course?.id} />},
      ]}/>}
    </PrivatePage>
  }

  function renderActivities() {
    return course?.kind == CourseKind.LessonSet
      ? {label:'Time slots', name:'time-slots', content: <TimeSlots course={course} />}
      : course?.kind == CourseKind.Team
        ? {label:'Team activities', name:'activities', content: <Games course={course} />}
        : undefined;
  }

  function renderStatus() {
    return <HBox gap='$8'>
      <CourseStatusTag status={course?.status} />
      {course?.kind == CourseKind.LessonSet && <Tag {...courseVariantTag} bg='brand' color='white'>Lesson set</Tag>}
    </HBox>
  }

  function renderBack() {
    return <HBox gap='$8'>
      {course?.site?.userSiteRole 
        ? <BackLink to={`/organizer/${course?.site?.slug}/seasons/${course?.season?.id}`}>Back to season</BackLink>
        : <BackLink to={`/provider/activities`}>Back to activities</BackLink>}
      {Boolean(course?.parent) && <BackLink to={`${urlBase}/${course.parent.id}`}>Back to {courseKindBehavior[course.parent.kind]?.label} ({course.parent.name})</BackLink>}
    </HBox>;
  }

  function renderSubtitle() {
    return <VBox gap='$8'>
      <CourseDaysDisplay course={course} icon />
      <CourseDates course={course} gap={course?.kind == CourseKind.LessonSet ? '$16' : undefined} />
    </VBox>
  }

  function renderActions() {
    const abilities = CourseUtils.getCourseAbilities(course);
    
    return (
      <OptionsMenu ml={['$20', '$60', '$60']}>
        {abilities.sendable && <MenuItem label="Send to provider" onClick={handleSendToProvider} />}
        {abilities.finalizable && <MenuItem label="Send to organizer" onClick={handleFinalize} />}
        {abilities.approvable && <MenuItem label="Approve" onClick={handleApprove} />}
        {abilities.enrollable && behavior.fields?.enrollmentPeriod !== false && <MenuItem label="Open enrollment" onClick={handleOpenEnrollment} />}
        {abilities.enrollable && behavior.fields?.enrollmentPeriod !== false && <MenuItem label="Close enrollment" onClick={handleCloseEnrollment} />}
        {(abilities.cancelable || abilities.deletable) && <MenuItem label="Cancel" onClick={handleCancel} />}
      </OptionsMenu>
    );
  }

  function containerVars() {
    // this is hack...we need a new server argument that allows us to specify
    // that we are editing a course w/o a container id
    return course?.parent ? {courseId: course.parent.id} : course?.site?.userSiteRole ? {seasonId: course.season?.id} : {companyId: course.vendor?.company?.id};
  }

  function handleSendToProvider() {
    sendCourseRequests(actionOptions('Activity sent'));
  }

  function handleFinalize() {
    finalizeCourses(actionOptions('Activities sent to the organizer'));
  }
  
  function handleApprove() {
    approveCourses(actionOptions('Activity approved'));
  }

  function handleOpenEnrollment() {
    openCourses(actionOptions('Enrollment opened'));
  }

  function handleCloseEnrollment() {
    closeCourses(actionOptions('Enrollment closed'));
  }

  function actionOptions(successMsg: string) {
    return {variables:{ids:[course.id]}, successMsg, error:{handler: formUi.current, transform: removePathSegment('ids.0')}};
  }

  function handleCancel() {
    return <CancelCoursesModal courses={[course]} />
  }

  return render();
}
