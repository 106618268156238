import * as React from 'react';

import { NavigationSection, NavigationLink } from 'app2/views/shared-public';

import { getOrganizerPath, seasonsPath } from './organizerRoutes';

export const organizerLinks = <NavigationSection icon='BookOpen' name='School'>
  <NavigationLink to={getOrganizerPath('schedule')} label='Schedule' />
  <NavigationLink to={seasonsPath} label='Season' />
  <NavigationLink to={getOrganizerPath('enrollments')} label='Enrollments' />
  <NavigationLink to={getOrganizerPath('attendance')} label='Attendance' />
  <NavigationLink to={getOrganizerPath('providers')} label='Providers' />
  <NavigationLink to={getOrganizerPath('messages')} label='Messages' />
  <NavigationLink to={getOrganizerPath('reports')} label='Reports' />
  <NavigationLink to={getOrganizerPath('setup')} label='Setup' />
</NavigationSection>
